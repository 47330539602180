
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { NuxtError } from '@nuxt/types'
import type { MetaInfo } from 'vue-meta'

import AppLayout from '~/components/AppLayout.vue'
import AppOfflineBar from '~/components/AppOfflineBar.vue'

import { WithHead } from '~/core/vue.types'

@Component({
  components: {
    AppLayout,
    AppOfflineBar,
  },
})
export default class NuxtErrorLayout extends Vue implements WithHead {
  @Prop() readonly error!: NuxtError

  head (): MetaInfo {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title:
        this.error.statusCode === 404
          ? this.$t('head.meta.404.title').toString()
          : this.$t('head.meta.500.title').toString(),
    }
  }

  get errorObject () {
    return this.error.statusCode === 404
      ? {
          title: this.$t('404.title').toString(),
          subtitle: this.$t('404.subtitle').toString(),
          buttonName: this.$t('404.backHome').toString(),
        }
      : {
          title: this.$t('500.title').toString(),
          subtitle: this.$t('500.subtitle').toString(),
          buttonName: this.$t('500.backHome').toString(),
        }
  }
}
