var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'expandable-card-header',
    `expandable-card-header--${_vm.theme}`
  ]},[_c('a',{staticClass:"expandable-card-header__overlay",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.toggle()}}}),_vm._v(" "),_c('div',{staticClass:"expandable-card-header__icon"},[_vm._t("icon")],2),_vm._v(" "),_c('div',{staticClass:"expandable-card-header__content"},[_vm._t("default")],2),_vm._v(" "),_c('button',{staticClass:"expandable-card-header__toggle"},[_c('IconCaretDown',{class:[
        'expandable-card-header__icon-toggle',
        `expandable-card-header__icon-toggle--${_vm.theme}`,
        {
          'expandable-card-header__icon-toggle--expanded': _vm.isExpanded
        }
      ]})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }