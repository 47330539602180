import type { Context } from '@nuxt/types'
import Vue from 'vue'
import VueMq from 'vue-mq'
import { Inject } from '@nuxt/types/app'
import { Breakpoints } from '@alao-frontend/types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import scssBreakpoints from '~/assets/styles/breakpoints.scss'

export interface MQProperties {
  isXS: boolean,
  isSM: boolean,
  isMD: boolean,
  isLG: boolean,
  isXL: boolean,
  isXXL: boolean,
  isXXXL: boolean,
  isMobile: boolean,
  isDesktop: boolean,
}

export default function ({ $device }: Context, inject: Inject) {
  const breakpoints = Object.fromEntries(
    Object.entries(scssBreakpoints).map(([key, val]) => [
      key,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseInt(val) + 1,
    ]),
  )

  const options = {
    breakpoints,
    defaultBreakpoint: $device.isMobile
      ? Breakpoints.SM
      : $device.isTablet
        ? Breakpoints.MD
        : Breakpoints.LG,
  }

  if (process.client) {
    VueMq.install(Vue, options)
  } else {
    const originalMixin = Vue.mixin
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Vue.mixin = () => {
    /*
    * We don't let mq put its handlers and the computed property "$mq",
    * but let it register the filter, component, and $mqAvailableBreakpoints.
    *  */
    }
    VueMq.install(Vue, options)
    Vue.mixin = originalMixin

    // And we inject a static $mq field
    inject('mq', options.defaultBreakpoint)
  }
}

Vue.mixin({
  computed: {
    $mqUtils (): MQProperties {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isXS: Breakpoints.XS === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isLG: Breakpoints.LG === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isMD: Breakpoints.MD === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isSM: Breakpoints.SM === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isXL: Breakpoints.XL === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isXXL: Breakpoints.XXL === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isXXXL: Breakpoints.XXXL === this.$mq,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isMobile: [Breakpoints.XS, Breakpoints.SM].includes(this.$mq as Breakpoints),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isDesktop: [Breakpoints.MD, Breakpoints.LG, Breakpoints.XL, Breakpoints.XXL, Breakpoints.XXXL].includes(this.$mq as Breakpoints),
      }
    },
  },
})
