import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'

const siteLevelReferralLink: Middleware = ctx => createNuxtMiddleware(
  ctx,
  (
    utils,
  ) => {
    const { route } = ctx

    const { REFERRAL_LINK_QUERY_PARAM_NAME: paramName } = ctx.$config

    if (!route.query[paramName]) {
      return
    }

    const {
      REFERRAL_LINK_COOKIE_NAME: cookieName,
      REFERRAL_LINK_COOKIE_EXP: expiration,
      APP_DOMAIN: domain,
    } = ctx.$config

    const context = route.query[paramName].toString()

    utils.setCookie(cookieName, context, {
      expires: expiration,
      domain,
      path: '/',
    })
  })

export default siteLevelReferralLink
