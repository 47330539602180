
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconCheckMark from '~/components/icons/IconCheckMark.vue'

@Component({
  components: { IconCheckMark },
  inheritAttrs: false,
})
export default class UiDropdownItem extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  readonly active!: boolean

  @Prop({ required: false, default: 'a', type: String })
  readonly tag!: string
}
