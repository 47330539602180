import axios from 'axios'
import { ApplicationService } from '~/services/core/application.service'

// TODO:
//  - Put fileName into upload()
export class FilesService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  async upload (
    orderId: string,
    file: File | Blob,
    type: string,
    name: string,
    ext: string,
  ) {
    const {
      presigned_url: preSignedUrl,
      file_name: fileName,
    } = await this._prepareUpload(orderId, type, name, ext)

    await this.uploadFile(preSignedUrl, file, type)

    return fileName
  }

  _prepareUpload (
    orderId: string,
    type: string,
    name: string,
    ext: string,
  ) {
    return this.post('/api/generate_upload_url/', {
      order_id: orderId,
      file_extension: ext,
      file_name: name,
      file_type: type,
    }) as Promise<{
      // eslint-disable-next-line camelcase
      presigned_url: string,
      // eslint-disable-next-line camelcase
      file_name: string,
    }>
  }

  uploadFile (preSignedUrl: any, file: File | Blob, fileType: string) {
    return axios.put(preSignedUrl, file, {
      headers: {
        'Content-Type': fileType,
      },
    })
  }
}
