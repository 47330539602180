import { AxiosRequestConfig } from 'axios'
import {
  BundleOffer,
  BundleOfferDTO,
  DeviceOfferDTO,
  HomeOffer,
  HomeOfferDTO,
  MobileOffer,
  MobileOfferDTO,
} from '@alao-frontend/core'
import { GoogleOptimizeExtended } from '@alao-frontend/google-optimize-extended.module'
import { SingleProviderArticle } from '~/store/modules/Blog'
import { ApplicationService } from '~/services/core/application.service'
import {
  BundleOfferServiceRequestPayload,
  Device,
  DeviceOfferServiceRequestPayload,
  HomeOfferServiceRequestPayload,
  MobileOfferServiceRequestPayload,
  OfferTypeByShortName,
  Provider,
} from '~/core/offer.types'

/* eslint-disable camelcase */

interface ComparatorBaseOffersDTO {
  count: number,
  next?: null | string,
  previous?: null | string,
  result_match_filter: boolean,
}

export interface CompareOfferDTO<T> extends ComparatorBaseOffersDTO {
  results: T,
}

// TODO: Must be moved out of the service file
export interface DatesValidationDTO {
  dates: {
    min: number,
    max: number,
    range: {
      start: string,
      end: string,
    },
    exclude: string[],
  },
}

// TODO:
//  - Remove any
export class OfferService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  getMobileOffersSorts (): Promise<any> {
    return this.get('/api/mobile_offers/sorting/')
  }

  getHomeOffersSorts (): Promise<any> {
    return this.get('/api/home_offers/sorting/')
  }

  getBundlesOffersSorts (): Promise<any> {
    return this.get('/api/bundle_offers/sorting/')
  }

  async getMobileOffers (
    payload: MobileOfferServiceRequestPayload | any,
    experiment?: GoogleOptimizeExtended,
    requestConfig?: Partial<AxiosRequestConfig>,
  ) {
    const response = await this.get<CompareOfferDTO<MobileOfferDTO[]>>(
      '/api/mobile_offers/',
      Object.assign({}, payload, experiment && experiment.activeExperiment),
      requestConfig,
    )

    return {
      ...response,
      results: response.results.map(dto => new MobileOffer(dto)),
    }
  }

  async getHomeOffers (
    payload: HomeOfferServiceRequestPayload | any,
    experiment?: GoogleOptimizeExtended,
    requestConfig?: Partial<AxiosRequestConfig>,
  ) {
    const response = await this.get<CompareOfferDTO<HomeOfferDTO[]>>(
      '/api/home_offers/',
      Object.assign({}, payload, experiment && experiment.activeExperiment),
      requestConfig,
    )

    return {
      ...response,
      results: response.results.map(dto => new HomeOffer(dto)),
    }
  }

  async getBundleOffers (
    payload: BundleOfferServiceRequestPayload | any,
    experiment?: GoogleOptimizeExtended,
    requestConfig?: Partial<AxiosRequestConfig>,
  ) {
    const response = await this.get<CompareOfferDTO<BundleOfferDTO[]>>(
      '/api/bundle_offers/',
      Object.assign({}, payload, experiment && experiment.activeExperiment),
      requestConfig,
    )

    return {
      ...response,
      results: response.results.map(dto => new BundleOffer(dto)),
    }
  }

  async getDeviceOffers (
    payload: DeviceOfferServiceRequestPayload | any,
    experiment?: GoogleOptimizeExtended,
  ) {
    return await this.get<CompareOfferDTO<DeviceOfferDTO[]>>(
      '/api/device_offers/',
      Object.assign({}, payload, experiment && experiment.activeExperiment),
    )
  }

  getSimilarOffers (
    offerType: string,
    payload: any,
    experiment?: GoogleOptimizeExtended,
  ): Promise<any> {
    switch (offerType) {
      case OfferTypeByShortName.mobile:
        return this.getMobileOffers(payload, experiment)
      case OfferTypeByShortName.home:
        return this.getHomeOffers(payload, experiment)
      case OfferTypeByShortName.bundle:
        return this.getBundleOffers(payload, experiment)
      case OfferTypeByShortName.smartphone:
        return this.getDeviceOffers(payload, experiment)
      default:
        return Promise.reject(
          new TypeError(`Unhandled offerType: ${offerType}`),
        )
    }
  }

  getProvidersList (): Promise<Provider[]> {
    return this.get(
      '/api/providers/',
      {},
      {
        cache: {
          excludeFromCache: false,
        },
      },
    )
  }

  getProviderBySlug (slug: string): Promise<Provider> {
    return this.get(
      `/api/providers/${slug}/`,
    )
  }

  getProviderContentBySlug (slug: string): Promise<any> {
    return this.get(
      '/api/providers/articles/',
      {
        provider: slug,
      },
    )
  }

  getProviderArticlesListBySlug (
    slug: string,
  ): Promise<SingleProviderArticle[]> {
    return this.get(
      '/api/providers/sidebar/',
      {
        provider: slug,
      },
    )
  }

  getProviderSingleArticleBySlug (
    slug: string,
  ): Promise<SingleProviderArticle> {
    return this.get(
      `/api/providers/sidebar/${slug}`,
    )
  }

  getProviderHomeActivationRules (slug: string) {
    return this.get<DatesValidationDTO>(
      `/api/providers/${slug}/activation/`,
    )
  }

  getProviderPortabilityRules (slug: string) {
    return this.get<DatesValidationDTO>(
      `/api/providers/${slug}/porting/`,
    )
  }

  getDevicesList (queryString = '', brandName = ''): Promise<Device[]> {
    return this.get(
      '/api/devices/search/',
      { q: queryString, brand_name: brandName },
      {
        cache: {
          excludeFromCache: false,
        },
      },
    )
  }

  getOfferByOfferId (
    offerId: string,
    offerType: string,
    experiment?: GoogleOptimizeExtended,
    fromOrder: string | null = null,
    extraDiscountPromoCode: string | null = null,
  ): Promise<any> {
    return this.get('/api/offers', Object.assign({}, {
      pk: offerId,
      offer_type: offerType,
      from_order: fromOrder,
      edpc: extraDiscountPromoCode,
    }, experiment && experiment.activeExperiment))
  }

  getDeviceByGTIN (gtin: string) {
    return this.get('/api/device/', { gtin })
  }

  getOfferBySlug (
    slug: string,
    offerType: OfferTypeByShortName,
    experiment?: GoogleOptimizeExtended,
  ): Promise<any> {
    return this.get('/api/offers', Object.assign({}, {
      slug,
      offer_type: offerType,
    }, experiment && experiment.activeExperiment))
  }

  requestForOffer (payload: any): Promise<any> {
    return this.post(
      '/api/request_for_offer/',
      payload,
    )
  }

  recommendedOffers (): Promise<any> {
    return this.get(
      '/api/recommended_offers/',
      {},
      {
        cache: {
          excludeFromCache: false,
        },
      },
    )
  }

  async getDailyDealOffer () {
    const {
      image,
      offer,
    } = await this.get('/api/daily_deal/')

    console.log(image, offer)

    return {
      imageUrl: image || null,
      offer: offer || null,
    }
  }

  checkIfOfferAvailableForAddress (payload: Record<string, string | number>) {
    return this.get(
      '/api/offers/check_address/',
      payload,
    )
  }

  getValidationRiskOffers () {
    return this.get('/api/validation-risk/offers/')
  }
}
