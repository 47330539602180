
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { MobileOffer, HomeOffer, NamedOfferCategoryType } from '@alao-frontend/core'
import OfferDealPrices from '~/components/DealOfTheDay/OfferDeal/OfferDealPrices.vue'

@Component({
  components: { OfferDealPrices },
})
export default class OfferDeal extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
    required: true,
  })
  offer!: MobileOffer | HomeOffer

  @Prop({
    type: String,
    default: '',
  })
  imageUrl!: string

  get url () {
    if (!this.offer) {
      return ''
    }

    const namedType = NamedOfferCategoryType[this.offer.type]
    const pathName = `compare-${namedType}-slug`

    return this.localePath({
      name: pathName,
      params: {
        slug: this.offer.slug,
      },
    })
  }

  @Emit('cta:click')
  onCTAClick () {
    return true
  }
}
