import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import { createLogger } from '@alao-frontend/utils'
import { $i18n } from '@alao-frontend/i18n.plugin'
import { APIException } from '~/plugins/axios/lib/exception'

export const attachInterceptors = ($axios: NuxtAxiosInstance) => {
  const logger = createLogger('axios')

  $axios.onResponse((response) => {
    if (response.status && response.config) {
      logger.debug('[API] response', {
        url: response.config.url,
        status: response.status,
        fromCache: response.request.fromCache ? 'Yes' : 'No',
      })
    }
  })

  $axios.onRequest((config) => {
    logger.debug('[API] request', {
      url: config.url,
      allowCaching: config.cache?.excludeFromCache ? 'No' : 'Yes',
    })
    config.headers.common['X-language-code'] = $i18n?.locale?.toString()
    return config
  })

  $axios.onError((error) => {
    if (error.response) {
      logger.error('[API] error', {
        status: error.response.status,
        url: error.response.request.url,
        data: error.response.data,
      })
    }

    return Promise.reject(new APIException(error))
  })
}
