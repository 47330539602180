
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { $i18n } from '@alao-frontend/i18n.plugin'
import { CountdownLabels, CountdownPeriod } from '~/components/AppCountdown/types'

/**
 * Renderless component
 */
@Component
export default class AppCountdown extends Vue {
  @Prop({ required: true, default: () => new Date(), type: Date })
  readonly countToDate !: Date

  @Prop({
    required: false,
    default: () => ({
      [CountdownPeriod.DAYS]: $i18n.t('promo.counter.days'),
      [CountdownPeriod.HOURS]: $i18n.t('promo.counter.hours'),
      [CountdownPeriod.MINUTES]: $i18n.t('promo.counter.minutes'),
      [CountdownPeriod.SECONDS]: $i18n.t('promo.counter.seconds'),
    }),
    type: Object,
  })
  readonly labels!: CountdownLabels

  private isReady = false
  private isExpired = false
  private interval: number | NodeJS.Timeout | null = null

  days = 0
  hours = 0
  minutes = 0
  seconds = 0

  get slotProps () {
    return {
      counter: {
        days: {
          value: this.days,
          label: this.labels[CountdownPeriod.DAYS],
        },
        hours: {
          value: this.hours,
          label: this.labels[CountdownPeriod.HOURS],
        },
        minutes: {
          value: this.minutes,
          label: this.labels[CountdownPeriod.MINUTES],
        },
        seconds: {
          value: this.seconds,
          label: this.labels[CountdownPeriod.SECONDS],
        },
      },
      ready: this.isReady,
      expired: this.isExpired,
    }
  }

  calculateTheMoment () {
    const now = new Date().getTime()
    const diff = this.countToDate.getTime() - now

    if (diff <= 0 && this.interval) {
      window.clearInterval(this.interval)
      this.isExpired = true

      return
    }

    this.days = this.getDays(diff)
    this.hours = this.getHours(diff)
    this.minutes = this.getMinutes(diff)
    this.seconds = this.getSeconds(diff)
  }

  getDays (diff: number) {
    return Math.floor(diff / (1000 * 60 * 60 * 24))
  }

  getHours (diff: number) {
    return Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  }

  getMinutes (diff: number) {
    return Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  }

  getSeconds (diff: number) {
    return Math.floor((diff % (1000 * 60)) / 1000)
  }

  beforeMount () {
    this.calculateTheMoment()
    this.interval = setInterval(this.calculateTheMoment.bind(this), 1000)
    this.isReady = true
  }

  beforeDestroy () {
    clearInterval(this.interval as number)
  }
}
