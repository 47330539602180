import { Context } from '@nuxt/types'
import { GoogleAnalytics } from '~/plugins/analytics/google'
import { FacebookAnalytics } from '~/plugins/analytics/facebook'
import { AnalyticsUtils } from '~/plugins/analytics/utils'
import { EmarsysAnalytics } from '~/plugins/analytics/emarsys'
import { GATracker } from '~/plugins/analytics/trackers/ga.tracker'
import { FeatureFlags } from '~/core/config/feature-flags'

export interface AnalyticsPlugin {
  gtm: GoogleAnalytics,
  fbq: FacebookAnalytics,
  emarsys: EmarsysAnalytics,
  utils: AnalyticsUtils,

  newGTM: GATracker,
}

export default function (ctx: Context, inject: any) {
  const {
    app: {
      i18n,
      $gtm,
      $fb,
      $config,
      $emarsysWebExtend,
    },
  } = ctx

  const isNewGTMDebugMode = $config.GOOGLE_TAG_MANAGER_IS_DEBUG || ctx.$featureFlags.isEnabled(FeatureFlags.NEW_GTM_DEBUG_MODE)

  const analytics: AnalyticsPlugin = {
    gtm: new GoogleAnalytics($gtm, i18n, ctx),
    fbq: new FacebookAnalytics($fb, { testEventCode: $config.FACEBOOK_TEST_EVENT_CODE }),
    emarsys: new EmarsysAnalytics($emarsysWebExtend, ctx),
    utils: new AnalyticsUtils(),

    newGTM: new GATracker($gtm, isNewGTMDebugMode),
  }

  inject('analytics', analytics)
}
