import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { AxiosError } from 'axios'
import {
  ActivationDiscount,
  BonusType,
  Cashback,
  IActivationDiscount,
  ICashback,
  IVoucher,
  OfferCategoryType,
  Voucher,
} from '@alao-frontend/core'
import { $api } from '~/plugins/axios'
import { RootState } from '~/store'

export const namespace = 'modules/Order'

export type PromoCode = string

export type AppliedDiscountCode = {
  code: PromoCode,
  isApplied: true,
  isInvalid: false,
}

export type NonAppliedDiscountCode = {
  code: PromoCode,
  isApplied: false,
  isInvalid: false,
}

export type InvalidDiscountCode = {
  code: PromoCode,
  isApplied: false,
  isInvalid: true,
}

export type DiscountCode = (
  AppliedDiscountCode | NonAppliedDiscountCode | InvalidDiscountCode
) & {
  // Applied benefits
  cashbacks: Cashback[],
  vouchers: Voucher[],
  activationDiscount: ActivationDiscount | null,

  isBusy: boolean,
}

export interface OrderState {
  discountCode: DiscountCode,
}

export interface ApplyDiscountPayload {
  code: PromoCode,
  offerId: string,
  offerType: OfferCategoryType,
}

export enum GetterType {
  IS_DISCOUNT_CODE_APPLIED = 'isDiscountCodeApplied',
  IS_DISCOUNT_CODE_STATE_BUSY = 'isStateBusy',
  IS_DISCOUNT_CODE_INVALID = 'isDiscountCodeInvalid',
  CASHBACK_BENEFITS_BY_DISCOUNT_CODE = 'cashbackBenefitsByDiscountCode',
  VOUCHER_BENEFITS_BY_DISCOUNT_CODE = 'voucherBenefitsByDiscountCode',
  ACTIVATION_COST_DISCOUNT_BY_DISCOUNT_CODE = 'activationCostDiscountByDiscountCode',
  DISCOUNT_CODE = 'discountCode',
}

export interface PublicGetter {
  [GetterType.IS_DISCOUNT_CODE_APPLIED]: boolean,
  [GetterType.IS_DISCOUNT_CODE_STATE_BUSY]: boolean,
  [GetterType.IS_DISCOUNT_CODE_INVALID]: boolean,
  [GetterType.CASHBACK_BENEFITS_BY_DISCOUNT_CODE]: Cashback[],
  [GetterType.VOUCHER_BENEFITS_BY_DISCOUNT_CODE]: Voucher[],
  [GetterType.ACTIVATION_COST_DISCOUNT_BY_DISCOUNT_CODE]: ActivationDiscount,
  [GetterType.DISCOUNT_CODE]: string,
}

export enum ActionType {
  APPLY_DISCOUNT_CODE = 'applyDiscountCode',
  SET_DISCOUNT_CODE = 'setDiscountCode',
  CLEAR_DISCOUNT_CODE = 'clearDiscountCode',
  UPDATE_DISCOUNT_CODE_INVALID_STATE = 'updateDiscountCodeInvalidState',
  RESET_DISCOUNT_CODE = 'resetDiscountCode',
}

export interface PublicAction {
  [ActionType.APPLY_DISCOUNT_CODE](
    payload: ApplyDiscountPayload
  ): Promise<void | never>,

  [ActionType.SET_DISCOUNT_CODE](
    code: PromoCode
  ): void,

  [ActionType.UPDATE_DISCOUNT_CODE_INVALID_STATE](
    state: boolean
  ): void,

  [ActionType.CLEAR_DISCOUNT_CODE](): void,

  [ActionType.RESET_DISCOUNT_CODE](): void,
}

export enum MutationType {
  SET_DISCOUNT_CODE = 'setDiscountCode',
  SET_DISCOUNT_CODE_CASHBACK_BENEFITS = 'setDiscountCodeCashbackBenefits',
  SET_DISCOUNT_CODE_VOUCHER_BENEFITS = 'setDiscountCodeVoucherBenefits',
  SET_DISCOUNT_CODE_ACTIVATION_COST_DISCOUNT = 'setDiscountCodeActivationCostDiscount',
  SET_DISCOUNT_CODE_APPLIED_STATE = 'setDiscountCodeAppliedState',
  SET_DISCOUNT_CODE_BUSY_STATE = 'setDiscountCodeBusyState',
  SET_DISCOUNT_CODE_INVALID_STATE = 'setDiscountCodeInvalidState',
}

export const state = (): OrderState => ({
  discountCode: {
    code: '',
    isApplied: false,
    isInvalid: false,
    isBusy: false,

    cashbacks: [],
    vouchers: [],
    activationDiscount: null,
  },
})

export const getters: GetterTree<OrderState, RootState> = {
  [GetterType.IS_DISCOUNT_CODE_APPLIED] (state): boolean {
    return state.discountCode.isApplied
  },

  [GetterType.IS_DISCOUNT_CODE_STATE_BUSY] (state): boolean {
    return state.discountCode.isBusy
  },

  [GetterType.IS_DISCOUNT_CODE_INVALID] (state): boolean {
    return state.discountCode.isInvalid
  },

  [GetterType.CASHBACK_BENEFITS_BY_DISCOUNT_CODE] (state): Cashback[] {
    return state.discountCode.cashbacks
  },

  [GetterType.VOUCHER_BENEFITS_BY_DISCOUNT_CODE] (state): Voucher[] {
    return state.discountCode.vouchers
  },

  [GetterType.ACTIVATION_COST_DISCOUNT_BY_DISCOUNT_CODE] (state): ActivationDiscount | null {
    return state.discountCode.activationDiscount
  },

  [GetterType.DISCOUNT_CODE] (state): string {
    return state.discountCode.code
  },
}

export const actions: ActionTree<OrderState, RootState> = {
  [ActionType.SET_DISCOUNT_CODE] (
    { commit },
    code: PromoCode,
  ): void {
    commit(MutationType.SET_DISCOUNT_CODE, code)
  },

  async [ActionType.APPLY_DISCOUNT_CODE] (
    { commit, dispatch },
    payload: ApplyDiscountPayload,
  ): ReturnType<PublicAction[ActionType.APPLY_DISCOUNT_CODE]> {
    const { code, offerId, offerType } = payload

    commit(MutationType.SET_DISCOUNT_CODE_APPLIED_STATE, false)
    commit(MutationType.SET_DISCOUNT_CODE_BUSY_STATE, true)
    commit(MutationType.SET_DISCOUNT_CODE_INVALID_STATE, false)

    try {
      const response = await $api.OrderService.applyCouponToOrder(
        offerId,
        offerType,
        code,
      )
      const cashbacks: Cashback[] = []
      const vouchers: Voucher[] = []
      let activationDiscount: ActivationDiscount | null = null

      response.forEach((benefit) => {
        switch (benefit.profit_type) {
          case BonusType.CASHBACK:
            cashbacks.push(new Cashback(benefit as ICashback))
            break

          case BonusType.VOUCHER:
            vouchers.push(new Voucher(benefit as IVoucher))
            break

          case BonusType.ACTIVATION_DISCOUNT:
            activationDiscount = new ActivationDiscount(benefit as IActivationDiscount)
            break

          // At this moment we can only handle Cashback and Voucher bonuses
          // provided by promo code.
          // In order to extend this new models have to be added first
          default:
            throw new TypeError('Wrong bonus type was returned from the API')
        }
      })

      commit(MutationType.SET_DISCOUNT_CODE_APPLIED_STATE, true)
      commit(MutationType.SET_DISCOUNT_CODE_CASHBACK_BENEFITS, cashbacks)
      commit(MutationType.SET_DISCOUNT_CODE_VOUCHER_BENEFITS, vouchers)
      commit(MutationType.SET_DISCOUNT_CODE_ACTIVATION_COST_DISCOUNT, activationDiscount)
    } catch (e) {
      console.error(e)

      if ((e as AxiosError).response?.status === 400) {
        commit(MutationType.SET_DISCOUNT_CODE_INVALID_STATE, true)
      } else {
        dispatch(ActionType.CLEAR_DISCOUNT_CODE)
        throw e
      }
    } finally {
      commit(MutationType.SET_DISCOUNT_CODE_BUSY_STATE, false)
    }
  },

  [ActionType.CLEAR_DISCOUNT_CODE] ({ commit }): void {
    commit(MutationType.SET_DISCOUNT_CODE, '')
    commit(MutationType.SET_DISCOUNT_CODE_CASHBACK_BENEFITS, [])
    commit(MutationType.SET_DISCOUNT_CODE_VOUCHER_BENEFITS, [])
    commit(MutationType.SET_DISCOUNT_CODE_ACTIVATION_COST_DISCOUNT, null)
    commit(MutationType.SET_DISCOUNT_CODE_APPLIED_STATE, false)
  },

  [ActionType.RESET_DISCOUNT_CODE] ({ dispatch, commit }) {
    dispatch(ActionType.CLEAR_DISCOUNT_CODE)
    commit(MutationType.SET_DISCOUNT_CODE_INVALID_STATE, false)
  },

  [ActionType.UPDATE_DISCOUNT_CODE_INVALID_STATE] ({ commit }, state: boolean) {
    commit(MutationType.SET_DISCOUNT_CODE_INVALID_STATE, state)
  },
}

export const mutations: MutationTree<OrderState> = {
  [MutationType.SET_DISCOUNT_CODE] (state, code: string): void {
    state.discountCode.code = code
  },

  [MutationType.SET_DISCOUNT_CODE_CASHBACK_BENEFITS] (state, cashbacks: Cashback[]): void {
    state.discountCode.cashbacks = cashbacks
  },

  [MutationType.SET_DISCOUNT_CODE_VOUCHER_BENEFITS] (state, vouchers: Voucher[]): void {
    state.discountCode.vouchers = vouchers
  },

  [MutationType.SET_DISCOUNT_CODE_ACTIVATION_COST_DISCOUNT] (state, activationDiscount: ActivationDiscount | null): void {
    state.discountCode.activationDiscount = activationDiscount
  },

  [MutationType.SET_DISCOUNT_CODE_APPLIED_STATE] (state, applied: boolean): void {
    state.discountCode.isApplied = applied
  },

  [MutationType.SET_DISCOUNT_CODE_BUSY_STATE] (state, busy: boolean): void {
    state.discountCode.isBusy = busy
  },

  [MutationType.SET_DISCOUNT_CODE_INVALID_STATE] (state, invalid: boolean): void {
    state.discountCode.isInvalid = invalid
  },
}
