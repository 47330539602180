export enum NotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface Notification {
  id: string,
  title: string,
  message: string,
  duration?: number,
  type?: NotificationType,
}
