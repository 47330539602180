import { ApplicationService } from '~/services/core/application.service'

export class StripeService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  createPaymentIntent (orderId: string) {
    return this.post('/api/payments/create-payment-intent/', { order_id: orderId })
  }
}
