
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import Loader from '~/components/Loader.vue'
import CheckmarkAnimated from '~/components/icons/CheckmarkAnimated.vue'
import AppTransition from '~/components/AppTransition/AppTransition.vue'
import AppModalState from '~/components/AppModal/AppModalState.vue'
import IconFilledClose from '~/assets/images/icons/icon-close-filled.svg?inline'

@Component({
  components: {
    AppModalState,
    AppTransition,
    Loader,
    CheckmarkAnimated,
    IconFilledClose,
  },
})
export default class AppModal extends Vue {
  @Prop({ type: String, default: 'sm', required: false })
  readonly size!: string

  @Prop({ type: Boolean, default: false, required: false })
  readonly loading!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly loadingDone!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly overlayTransparent!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly overlayBlur!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly filledClose!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly closeFromBg!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly centered!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  readonly bodyLock!: boolean

  @Prop({ type: String, default: 'fade', required: false })
  readonly overlayTransitionName!: string

  lock () {
    document.body.classList.add('modal-open')
  }

  unlock () {
    document.body.classList.remove('modal-open')
  }

  @Emit('close')
  close () {
    this.unlock()
    return true
  }

  mounted () {
    if (this.bodyLock) {
      this.lock()
    }
  }

  beforeDestroy () {
    if (this.bodyLock) {
      this.unlock()
    }
  }
}
