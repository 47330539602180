

import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ModelSync } from 'vue-property-decorator'
import IconCaretDown from '~/assets/icons/icon-caret-down.svg?inline'

type Theme = 'light' | 'dark'

@Component({
  components: {
    IconCaretDown,
  },
})
export default class ExpandableCardHeader extends Vue {
  @ModelSync('expanded', 'change', { type: Boolean, default: true })
  private isExpanded!: boolean

  @Prop({
    type: String,
    default: 'light',
  })
  readonly theme!: Theme

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly expanded!: boolean

  toggle () {
    this.isExpanded = !this.isExpanded
  }
}
