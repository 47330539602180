import { Operator } from '~/types/promo/firebase'
import { QueryBuilder } from '~/services/promo/query-builder'
import { PageBannerTemplateFields } from '~/types/promo/page-banner-template'

export class PageBannerTemplatesQuery extends QueryBuilder {
  protected readonly collectionId = 'page-banner-templates'
  protected readonly fields: PageBannerTemplateFields = [
    'name',
    'backgroundColor',
    'textColor',
    'prependImage',
  ]

  constructor (
    protected readonly projectId: string,
    protected readonly dbName: string,
  ) {
    super()
  }

  findById (documentId: string) {
    this.conditions = {
      type: 'field',
      filter: {
        field: {
          fieldPath: '__name__',
        },
        op: Operator.EQUAL,
        value: {
          referenceValue: this.buildDocumentReference(
            this.projectId,
            this.dbName,
            this.collectionId,
            documentId,
          ),
        },
      },
    }

    return this
  }
}
