import { Operator } from '~/types/promo/firebase'
import { QueryBuilder } from '~/services/promo/query-builder'
import type { OfferBannerTemplateFields } from '~/types/promo/offer-banner-template'

export class OfferBannerTemplatesQuery extends QueryBuilder {
  protected readonly collectionId = 'offer-banner-templates'
  protected readonly fields: OfferBannerTemplateFields = [
    'name',
    'backgroundColor',
    'textColor',
    'appendIcon',
    'prependIcon',
    'appendImage',
    'prependImage',
    'alignImage',
  ]

  constructor (
    protected readonly projectId: string,
    protected readonly dbName: string,
  ) {
    super()
  }

  findById (documentId: string) {
    this.conditions = {
      type: 'field',
      filter: {
        field: {
          fieldPath: '__name__',
        },
        op: Operator.EQUAL,
        value: {
          referenceValue: this.buildDocumentReference(
            this.projectId,
            this.dbName,
            this.collectionId,
            documentId,
          ),
        },
      },
    }

    return this
  }
}
