import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { Provider } from '~/core/offer.types'
import { RootState } from '~/store'
import { $api } from '~/plugins/axios'
import { sortProvidersBySequence } from '~/core/providers/config/providers-sequence'

export const namespace = 'modules/Offer'

export interface OfferState {
  providersList: Provider[],
  recommendedOffers: [],
}

export const ActionType = {
  FETCH_PROVIDERS_LIST: 'fetchProvidersList',
  FETCH_RECOMMENDED_OFFERS: 'fetchRecommendedOffers',
}

export const MutationType = {
  SET_PROVIDERS_LIST: 'setProvidersList',
  SET_RECOMMENDED_OFFERS: 'setRecommendedOffers',
}

export const state = (): OfferState => ({
  providersList: [],
  recommendedOffers: [],
})

export const getters: GetterTree<OfferState, RootState> = {
  partnersList (state): Provider[] {
    return sortProvidersBySequence(state.providersList).filter(p => p.mobile || p.home)
  },
  mobileProvidersList (state): Provider[] {
    return sortProvidersBySequence(state.providersList).filter(p => p.mobile)
  },
  mobileProvidersListWithTalkTalkPriority (state, getters): Provider[] {
    const providers = getters.mobileProvidersList.map((e: any) => {
      return {
        name: e.name,
        extra: {
          logo: e.logo,
        },
        value: e.pk.toString(),
      }
    })
    // if talk-talk position in list more 5 than swap its position with elem which position in list is 5
    if (providers && providers[4]?.name !== 'TalkTalk') {
      const talkTalkProviderIndex = providers.findIndex((provider: any) => provider.name === 'TalkTalk')
      if (talkTalkProviderIndex) {
        providers[4] = providers.splice(talkTalkProviderIndex, 1, providers[4])[0]
      }
    }
    return providers
  },
  homeProvidersList (state): Provider[] {
    return sortProvidersBySequence(state.providersList).filter(p => p.home)
  },
  getProviderBySlug (state) {
    return (slug: string): Provider | null => {
      return state.providersList.find(p => p.slug === slug) || null
    }
  },
  getProviderById (state) {
    return (pk: number): Provider | null => {
      return state.providersList.find(p => p.pk === pk) || null
    }
  },
}

export const actions: ActionTree<OfferState, RootState> = {
  async [ActionType.FETCH_PROVIDERS_LIST] ({ commit }) {
    try {
      const results: Provider[] = await $api.OfferService.getProvidersList()

      commit(MutationType.SET_PROVIDERS_LIST, results)
    } catch (error: any) {
      // TODO: Handle error
      console.info('SET_PROVIDERS_LIST catch: ', error)
      console.info('SET_PROVIDERS_LIST catch json: ', JSON.stringify(error))
      throw new Error(error)
    }
  },

  async [ActionType.FETCH_RECOMMENDED_OFFERS] ({ commit }) {
    try {
      const results = await $api.OfferService.recommendedOffers()
      commit(MutationType.SET_RECOMMENDED_OFFERS, results)
    } catch (error: any) {
      if (error.isAxiosError) {
        if (error.response && error.response.status === 404) {
          console.error(error)
          return
        }
      }
      // TODO: Handle errors correctly
      throw error
    }
  },
}

export const mutations: MutationTree<OfferState> = {
  [MutationType.SET_PROVIDERS_LIST] (state, providersList: Provider[]): void {
    state.providersList = providersList
  },
  [MutationType.SET_RECOMMENDED_OFFERS] (state, offers: []): void {
    state.recommendedOffers = offers
  },
}
