export enum EmarsysCommands {
  GO = 'go',
  SET_EMAIL = 'setEmail',
  CART = 'cart',
  CATEGORY = 'category',
  PURCHASE = 'purchase',
  VIEW = 'view',
  TAG = 'tag',
  SEARCH_TERM = 'searchTerm',
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
  RECOMMEND = 'recommend',
  TEST_MODE = 'testMode',
  AVAILABILITY_ZONE = 'availabilityZone',
  DISPLAY_CURRENCY = 'displayCurrency',
  LANGUAGE = 'language',
  SET_CUSTOMER_ID = 'setCustomerId',
}

export enum EmarsysCategoryByOfferType {
  MOBILE_ABO_OFFER = 'Plans > Compare > Mobile Plans',
  HOME_OFFER = 'Plans > Compare > Home Plans',
  BUNDLE_OFFER = 'Plans > Compare > Bundle Plans',
  DEVICE_WITH_MOBILE_PLAN_OFFER = 'Devices > Smartphones + Plans',
}
