

import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ExpandableCardBody extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly visible!: boolean
}
