import { FirebaseResponseItem } from '~/types/promo/firebase'
import { OfferBannerTemplate, OfferBannerTemplateDocument } from '~/types/promo/offer-banner-template'
import { parseFirebaseDocumentIdFromName } from '~/services/promo/utils'

export const offerBannerTemplatesConverter = (
  response: FirebaseResponseItem<OfferBannerTemplateDocument>[],
): OfferBannerTemplate[] => {
  const templates = response.map((item) => {
    if (!item.document) {
      return null
    }

    const id = parseFirebaseDocumentIdFromName(item.document.name)

    const {
      name,
      backgroundColor,
      textColor,
      appendIcon,
      prependIcon,
      appendImage,
      prependImage,
      alignImage,
    } = item.document.fields

    return {
      id,
      name: name.stringValue,
      backgroundColor: backgroundColor.stringValue,
      textColor: textColor.stringValue,
      appendIcon: appendIcon.stringValue,
      prependIcon: prependIcon.stringValue,
      appendImage: appendImage.stringValue,
      prependImage: prependImage.stringValue,
      alignImage: alignImage.stringValue,
    } as OfferBannerTemplate
  })

  return templates.filter(item => item !== null) as OfferBannerTemplate[]
}
