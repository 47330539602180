import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { $api } from '~/plugins/axios'

export const namespace = 'modules/Common'

export interface CommonState {
  totalOrders: number,
  totalSavings: number,
}

export const ActionType = {
  FETCH_SALES_STATISTICS: 'fetchSalesStatistics',
}
export const MutationType = {
  SET_TOTAL_ORDERS: 'setTotalOrders',
  SET_TOTAL_SAVINGS: 'setTotalSavings',
}

export const state = (): CommonState => ({
  totalOrders: 0,
  totalSavings: 0,
})

export const getters: GetterTree<CommonState, RootState> = {}

export const actions: ActionTree<CommonState, RootState> = {
  async [ActionType.FETCH_SALES_STATISTICS] ({ commit }) {
    try {
      const {
        completed_orders: orders,
        total_savings: savings,
      } = await $api.OrderService.salesStatistics()

      commit(MutationType.SET_TOTAL_ORDERS, orders)
      commit(MutationType.SET_TOTAL_SAVINGS, savings)
    } catch (error:any) {
      throw new Error(error)
    }
  },
}

export const mutations: MutationTree<CommonState> = {
  [MutationType.SET_TOTAL_ORDERS] (state, orders: number): void {
    state.totalOrders = orders
  },
  [MutationType.SET_TOTAL_SAVINGS] (state, savings: number): void {
    state.totalSavings = savings
  },
}
