import { ApplicationService } from '~/services/core/application.service'
import { FirebaseQuery, FirebaseResponseItem } from '~/types/promo/firebase'
import { Campaign, CampaignDocument } from '~/types/promo/campaign'
import { CampaignsQuery } from '~/services/promo/queries/campaigns.query'
import { OfferBannersQuery } from '~/services/promo/queries/offer-banners.query'
import { OfferBanner, OfferBannerDocument } from '~/types/promo/offer-banner'
import { OfferBannerTemplatesQuery } from '~/services/promo/queries/offer-banner-templates.query'
import { OfferBannerTemplate, OfferBannerTemplateDocument } from '~/types/promo/offer-banner-template'
import { campaignsConverter } from '~/services/promo/converters/campaigns.converter'
import { offerBannerTemplatesConverter } from '~/services/promo/converters/offer-banner-templates.converter'
import { offerBannersConverter } from '~/services/promo/converters/offer-banners.converter'
import { PageBannersQuery } from '~/services/promo/queries/page-banners.query'
import { PageBanner, PageBannerDocument } from '~/types/promo/page-banner'
import { PageBannerTemplatesQuery } from '~/services/promo/queries/page-banner-templates.query'
import { PageBannerTemplate, PageBannerTemplateDocument } from '~/types/promo/page-banner-template'
import { pageBannersConverter } from '~/services/promo/converters/page-banners.converter'
import { pageBannerTemplatesConverter } from '~/services/promo/converters/page-banner-templates.converter'

export class PromoService extends ApplicationService {
  private projectId = this.runtimeConfig.FIREBASE_PROJECT_ID
  private dbName = '(default)'
  private isCacheResults = true

  private queryUrl = `/projects/${this.projectId}/databases/${this.dbName}/documents:runQuery`

  get baseUrl () {
    return this.runtimeConfig.FIREBASE_API_BASE_URL
  }

  async activeCampaignsList (): Promise<Campaign[]> {
    try {
      const query = new CampaignsQuery(this.projectId, this.dbName)
        .list()
        .build()

      const campaigns = await this.runQuery<
        FirebaseResponseItem<CampaignDocument>[],
        Campaign[]
      >(
        query,
        campaignsConverter,
      )

      const now = Date.now()

      // Return only campaigns in between the start and the end dates
      return campaigns.filter(c => c.dateStart.getTime() <= now && c.dateEnd.getTime() > now)
    } catch (e) {
      console.log(e)
      return []
    }
  }

  async offerBannersList (campaignId: string) {
    try {
      const query = new OfferBannersQuery(this.projectId, this.dbName)
        .listByCampaignId(campaignId)
        .build()

      return await this.runQuery<
        FirebaseResponseItem<OfferBannerDocument>[],
        OfferBanner[]
      >(
        query,
        offerBannersConverter,
      )
    } catch (e) {
      console.log(e)
    }

    return []
  }

  async offerBannerTemplate (templateId: string) {
    try {
      const query = new OfferBannerTemplatesQuery(this.projectId, this.dbName)
        .findById(templateId)
        .build()

      const templates = await this.runQuery<FirebaseResponseItem<OfferBannerTemplateDocument>[], OfferBannerTemplate[]>(
        query,
        offerBannerTemplatesConverter,
      )

      if (templates.length) {
        return templates[0]
      }
    } catch (e) {
      console.log(e)
    }

    return null
  }

  async pageBannersList (campaignId: string) {
    try {
      const query = new PageBannersQuery(this.projectId, this.dbName)
        .listByCampaignId(campaignId)
        .build()

      return await this.runQuery<
        FirebaseResponseItem<PageBannerDocument>[],
        PageBanner[]
      >(
        query,
        pageBannersConverter,
      )
    } catch (e) {
      console.log(e)
    }

    return []
  }

  async pageBannerTemplate (templateId: string) {
    try {
      const query = new PageBannerTemplatesQuery(this.projectId, this.dbName)
        .findById(templateId)
        .build()

      const templates = await this.runQuery<FirebaseResponseItem<PageBannerTemplateDocument>[], PageBannerTemplate[]>(
        query,
        pageBannerTemplatesConverter,
      )

      if (templates.length) {
        return templates[0]
      }
    } catch (e) {
      console.log(e)
    }

    return null
  }

  private async runQuery<T, R>(
    query: FirebaseQuery,
    converter: (docs: T) => R,
    isCacheResults = this.isCacheResults,
  ) {
    const results = await this.post<T>(this.queryUrl, query, {
      cache: {
        excludeFromCache: !isCacheResults,
      },
    })

    return converter(results)
  }
}
