
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class OnIdleTimeoutDisplay extends Vue {
  @Prop({ type: Number, default: 5000 })
  timeout!: number

  timer: number | null = null
  isVisible = false

  clearTimer () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  idleTimerHandler () {
    this.clearTimer()

    this.timer = window.setTimeout(() => {
      this.isVisible = true
      this.onStateChange()
    }, this.timeout)
  }

  attachTimerListeners () {
    window.addEventListener('load', this.idleTimerHandler, true)
    window.addEventListener('mousemove', this.idleTimerHandler, true)
    window.addEventListener('mousedown', this.idleTimerHandler, true)
    window.addEventListener('touchstart', this.idleTimerHandler, true)
    window.addEventListener('touchmove', this.idleTimerHandler, true)
    window.addEventListener('click', this.idleTimerHandler, true)
    window.addEventListener('keydown', this.idleTimerHandler, true)
    window.addEventListener('scroll', this.idleTimerHandler, true)
  }

  detachTimerListeners () {
    window.removeEventListener('load', this.idleTimerHandler, true)
    window.removeEventListener('mousemove', this.idleTimerHandler, true)
    window.removeEventListener('mousedown', this.idleTimerHandler, true)
    window.removeEventListener('touchstart', this.idleTimerHandler, true)
    window.removeEventListener('touchmove', this.idleTimerHandler, true)
    window.removeEventListener('click', this.idleTimerHandler, true)
    window.removeEventListener('keydown', this.idleTimerHandler, true)
    window.removeEventListener('scroll', this.idleTimerHandler, true)
  }

  @Emit('change')
  onStateChange () {
    return this.isVisible
  }

  mounted () {
    if (process.client) {
      this.attachTimerListeners()
    }
  }

  beforeDestroy () {
    if (process.client) {
      this.detachTimerListeners()
      this.clearTimer()
    }
  }
}
