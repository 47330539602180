import { getCookie, setCookie } from '@alao-frontend/utils'
import {
  OfferBusinessCommissionMetrics,
  OfferCategoryIdsType,
  OfferCategoryNamesType,
  OfferCategoryType,
} from '~/core/offer.types'
import { AnalyticsOffersList, OFFERS_LIST_COOKIE_NAME, OFFERS_POSITION_COOKIE_NAME } from '~/plugins/analytics/types'

export class AnalyticsUtils {
  getOfferCategoryName (offerType: OfferCategoryType): OfferCategoryNamesType {
    return OfferCategoryNamesType[offerType]
  }

  getOfferCategoryId (offerType: OfferCategoryType): number {
    return OfferCategoryIdsType[offerType]
  }

  getOfferCategoryCommissionGroupCode (offerType: OfferCategoryType) {
    const categoryId = this.getOfferCategoryId(offerType)

    return `SALE${categoryId}`
  }

  getEventLabel (offerType: OfferCategoryType, providerName: string) {
    const isBundle = offerType === 'BUNDLE_OFFER'
    const label = `Provider: ${providerName}`

    if (isBundle) {
      return `${label} bundle`
    }

    return label
  }

  getMetricsValue (metrics: OfferBusinessCommissionMetrics, { isMobile, isHome, isBundle, isDevice }: any) {
    const values = []

    if (isMobile || isBundle || isDevice) {
      values.push(metrics.fpromtrid)
    }

    if (isHome || isBundle) {
      values.push(metrics.gtrhref)
    }

    if (isDevice) {
      values.push(metrics.timetosale)
    }

    return parseFloat(values.reduce((acc, curr) => acc + curr, 0).toFixed(2))
  }

  getContentId (offerType: OfferCategoryType, offerId: string | number): string {
    const categoryId = this.getOfferCategoryId(offerType)

    return `${categoryId}${offerId}`
  }

  setOffersListPosition (list: AnalyticsOffersList) {
    setCookie(OFFERS_LIST_COOKIE_NAME, list, {
      expires: 1,
    })
  }

  setOffersIndexPosition (index: number) {
    setCookie(OFFERS_POSITION_COOKIE_NAME, index, {
      expires: 1,
    })
  }

  getOffersListPosition (): AnalyticsOffersList {
    const list = getCookie(OFFERS_LIST_COOKIE_NAME) as AnalyticsOffersList

    return list || AnalyticsOffersList.DIRECT_LINK
  }

  getOffersIndexPosition (): number {
    const index = getCookie(OFFERS_POSITION_COOKIE_NAME)

    return index ? parseInt(index) : 1
  }
}
