import { ApplicationService } from '~/services/core/application.service'
import type { IScheduler } from '~/core/schedule.types'

export class ModalWindowSettingsService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  getSchedule (url: string): Promise<Array<IScheduler>> {
    return this.get('/api/modal_window_settings/', { url })
  }
}
