import { GoogleOptimizeExtended } from '~google-optimize-extended/class'
import frontendExperiments from '~/experiments'

export default function (ctx, inject) {
  const storeModuleName = 'modules/BaseInfo';
  const storeGetterName = 'abTests';

  const storeExperiments = ctx.store.getters[storeModuleName + '/' + storeGetterName]

  const experiments = []

  if (frontendExperiments) {
    experiments.push(...frontendExperiments)
  }

  if (storeExperiments) {
    experiments.push(...storeExperiments)
  }

  const googleOptimizeExtended = new GoogleOptimizeExtended(experiments, ctx, {
    excludeBots: true,
    botExpression: /(bot|spider|crawler)/i,
    maxAge: 604800,
  })

  inject('exp', googleOptimizeExtended)
}
