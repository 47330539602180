const PLATFORM = 'alao'
const COUNTRY = 'ch'

export const enum CUSTOM_HEADER {
  PLATFORM = 'platform',
  COUNTRY = 'country',
  LANG = 'lang',
}

export const INITIAL_HEADERS = {
  [CUSTOM_HEADER.PLATFORM]: PLATFORM,
  [CUSTOM_HEADER.COUNTRY]: COUNTRY,
}
