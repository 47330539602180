
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import UiDropdown from '~/components/ui/UIDropdown/UiDropdown.vue'
import UiDropdownItem from '~/components/ui/UIDropdown/UiDropdownItem.vue'
import { DropdownMenuItem } from '~/components/AppHeader/AppHeaderDropdownMenu/types'
import UiDropdownHandle from '~/components/ui/UIDropdown/UiDropdownHandle.vue'

@Component({
  components: {
    UiDropdownHandle,
    UiDropdownItem,
    UiDropdown,
  },
})
export default class AppHeaderDropdownMenu extends Vue {
  @Prop({ type: Array, required: true })
  items!: DropdownMenuItem[]
}
