import type { ConditionFilter, FirebaseQuery } from '~/types/promo/firebase'

export abstract class QueryBuilder {
  protected abstract readonly collectionId: string
  protected abstract readonly fields: string[]
  protected conditions!: ConditionFilter

  public build (): FirebaseQuery {
    if (!this.conditions) {
      throw new Error('No conditions were chosen. Specify the conditions first')
    }

    return {
      structuredQuery: {
        select: {
          fields: this.fields.map(field => ({ fieldPath: field })),
        },

        from: [{ collectionId: this.collectionId }],

        where: Object.assign({}, this.buildConditions()),
      },
    }
  }

  public buildDocumentReference (
    projectId: string,
    dbName: string,
    collectionId: string,
    documentId: string,
  ) {
    return `projects/${projectId}/databases/${dbName}/documents/${collectionId}/${documentId}`
  }

  private buildConditions () {
    switch (this.conditions.type) {
      case 'composite':
        return {
          compositeFilter: {
            op: this.conditions.op,
            filters: this.conditions.filters,
          },
        }
      case 'field':
        return {
          fieldFilter: this.conditions.filter,
        }
      case 'unary':
        return {
          unaryFilter: this.conditions.filter,
        }
    }
  }
}
