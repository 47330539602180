
import confetti from 'canvas-confetti'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OnMounted } from '~/core/vue.types'

@Component
export default class FullPageConfetti extends Vue implements OnMounted {
  @Prop({ required: false, type: Object, default: () => ({ resize: true, useWorker: true }) })
  readonly instanceOptions!: Record<string, any>

  private instance: any

  fire (options: any) {
    this.instance(options)
  }

  mounted () {
    const el = this.$refs.confettiCanvas as HTMLCanvasElement
    this.instance = confetti.create(el, this.instanceOptions)
    this.$parent?.$on('fire:confetti', this.fire)
  }
}
