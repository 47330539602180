import { OfferBannerDocumentFields } from '~/types/promo/offer-banner'
import { QueryBuilder } from '~/services/promo/query-builder'
import { Operator } from '~/types/promo/firebase'

export class OfferBannersQuery extends QueryBuilder {
  protected readonly collectionId = 'offer-banners'
  protected readonly fields: OfferBannerDocumentFields = [
    'campaignId',
    'contentEN',
    'contentDE',
    'contentFR',
    'offers',
    'templateId',
  ]

  constructor (
    protected readonly projectId: string,
    protected readonly dbName: string,
  ) {
    super()
  }

  listByCampaignId (campaignId: string) {
    this.conditions = {
      type: 'composite',
      op: Operator.AND,
      filters: [
        {
          fieldFilter: {
            field: {
              fieldPath: 'campaignId',
            },
            op: Operator.EQUAL,
            value: {
              stringValue: campaignId,
            },
          },
        },
      ],
    }

    return this
  }
}
