
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import {
  GetterType as PromoCampaignGetterType,
  namespace as promoCampaignNamespace,
  PublicGetters as PromoCampaignPublicGetters,
} from '~/store/modules/PromoCampaign'

import PageDynamicBannerTemplate1
  from '~/components/banners/PageDynamicBanner/templates/PageDynamicBannerTemplate1/PageDynamicBannerTemplate1.vue'

const PromoCampaignNamespace = namespace(promoCampaignNamespace)

@Component({
  components: {
    PageDynamicBannerTemplate1,
  },
})
export default class PageDynamicBanner extends Vue {
  @PromoCampaignNamespace.Getter(PromoCampaignGetterType.GET_CAMPAIGN_BY_ID)
  readonly getCampaignById!: PromoCampaignPublicGetters[PromoCampaignGetterType.GET_CAMPAIGN_BY_ID]

  @PromoCampaignNamespace.Getter(PromoCampaignGetterType.GET_PAGE_BANNERS)
  readonly getPageBanners!: PromoCampaignPublicGetters[PromoCampaignGetterType.GET_PAGE_BANNERS]

  @PromoCampaignNamespace.Getter(PromoCampaignGetterType.GET_PAGE_BANNER_TEMPLATE)
  readonly getBannerTemplate!: PromoCampaignPublicGetters[PromoCampaignGetterType.GET_PAGE_BANNER_TEMPLATE]

  get pageBanners () {
    return this.getPageBanners()
  }
}
