import { createLogger } from '@alao-frontend/utils'
import { EventPayload, TrackingProvider } from '~/plugins/analytics/types'

export abstract class TrackEvent <
  P extends EventPayload,
> {
  protected abstract readonly logger: ReturnType<typeof createLogger>

  public abstract readonly payload: P

  protected constructor (
    protected readonly provider: TrackingProvider,
    protected readonly debug: boolean = false,
  ) {
  }

  get isDebug () {
    return this.debug
  }

  public onEventSent () {
    if (!this.isDebug) {
      return
    }

    this.logger.info('Event sent:', this.payload)
  }

  public onEventFailed (e: Error) {
    this.logger.error('Unable to send event:', this.payload)
    this.logger.trace(e)
  }

  public send () {
    try {
      // Do not send events in debug mode, just log them
      if (!this.isDebug) {
        this.provider.push(this.payload)
      }
      this.onEventSent()
    } catch (e) {
      this.onEventFailed(e as Error)
    }
  }
}
