import { FirebaseResponseItem } from '~/types/promo/firebase'
import { parseFirebaseDocumentIdFromName } from '~/services/promo/utils'
import type { PageBanner, PageBannerDocument } from '~/types/promo/page-banner'

export const pageBannersConverter = (
  response: FirebaseResponseItem<PageBannerDocument>[],
): PageBanner[] => {
  const banners = response.map((item) => {
    if (!item.document) {
      return null
    }

    const id = parseFirebaseDocumentIdFromName(item.document.name)

    const {
      content,
      isDismissible,
      templateId,
      campaignId,
      visibility,
    } = item.document.fields

    return {
      id,
      templateId: templateId.stringValue,
      campaignId: campaignId.stringValue,
      visibility: visibility.stringValue,
      content: {
        en: content.mapValue.fields.en.stringValue,
        de: content.mapValue.fields.de.stringValue,
        fr: content.mapValue.fields.fr.stringValue,
      },
      isDismissible: isDismissible.booleanValue,
    } as PageBanner
  })

  return banners.filter(item => item !== null) as PageBanner[]
}
