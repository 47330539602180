export enum CountdownPeriod {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export type CountdownLabels = {
  [key in CountdownPeriod]: string
}
