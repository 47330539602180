import { CommonFiltersState, Exclusivity, FiltersList } from '~/core/config/filters/types'

import IconAlaoSmile from '~/components/icons/IconAlaoSmile.vue'

export const initialState: CommonFiltersState = Object.freeze({
  offer_types: [Exclusivity.EXCLUSIVE, Exclusivity.REGULAR],
})

export const getInitialState = (): CommonFiltersState => JSON.parse(JSON.stringify(initialState))

export const filtersList = (_rootGetters: any): FiltersList => {
  const defaultValues = getInitialState()

  return [
    {
      name: 'offer_types',
      component: 'MultipleSelection',
      props: {
        title: 'offerFilters.plans',
        deselectAll: false,
        defaultValue: defaultValues.offer_types,
        items: [
          {
            name: 'offerFilters.exclusiveAlaoDeals',
            value: Exclusivity.EXCLUSIVE,
            extra: {
              slotAfter: IconAlaoSmile,
            },
          },
          {
            name: 'offerFilters.regularOffers',
            value: Exclusivity.REGULAR,
          },
        ],
      },
    },
  ]
}
