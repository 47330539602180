import { VuexPersistence } from 'vuex-persist'
import { Context } from '@nuxt/types'
import Cookies from 'js-cookie'
import { FeatureFlags } from '~/core/config/feature-flags'

const TTL_DAYS = 0.5 // 12 hours

export default function (ctx: Context) {
  const { store, $featureFlags } = ctx

  new VuexPersistence({
    reducer: (state: any) => {
      const ampFilters = $featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)
        ? ({
            Amp: {
              Filters: {
                Common: state.modules.Amp.Filters.Common,
                Mobile: state.modules.Amp.Filters.Mobile,
                Home: state.modules.Amp.Filters.Home,
              },
            },
          })
        : {}
      return {
        modules: {
          Cart: state.modules.Cart,
          redirect: state.modules.redirect,
          Filters: {
            Common: state.modules.Filters.Common,
            Mobile: state.modules.Filters.Mobile,
            Home: state.modules.Filters.Home,
          },
          ...ampFilters,
        },
      }
    },
    restoreState: (key, _storage) => {
      const value = Cookies.get(key)

      if (value) {
        try {
          return JSON.parse(value)
        } catch (e) {
          return {}
        }
      }
    },
    saveState: (key, state, _storage) => {
      Cookies.set(key, JSON.stringify(state), {
        expires: TTL_DAYS,
      })
    },
  }).plugin(store)
}
