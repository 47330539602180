import type { FirebaseResponseItem } from '~/types/promo/firebase'
import type { PageBannerTemplate, PageBannerTemplateDocument } from '~/types/promo/page-banner-template'
import { parseFirebaseDocumentIdFromName } from '~/services/promo/utils'

export const pageBannerTemplatesConverter = (
  response: FirebaseResponseItem<PageBannerTemplateDocument>[],
): PageBannerTemplate[] => {
  const templates = response.map((item) => {
    if (!item.document) {
      return null
    }

    const id = parseFirebaseDocumentIdFromName(item.document.name)

    const {
      name,
      backgroundColor,
      textColor,
      prependImage,
    } = item.document.fields

    return {
      id,
      name: name.stringValue,
      backgroundColor: backgroundColor.stringValue,
      textColor: textColor.stringValue,
      prependImage: {
        en: prependImage?.mapValue.fields.en.stringValue,
        de: prependImage?.mapValue.fields.de.stringValue,
        fr: prependImage?.mapValue.fields.fr.stringValue,
      },
    } as PageBannerTemplate
  })

  return templates.filter(item => item !== null) as PageBannerTemplate[]
}
