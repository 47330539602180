import { IPriceExplanationBenefit } from '@alao-frontend/core'
import { NetworkType } from '~/components/sections/coverage/types'

export type ValueOf<T> = T[keyof T]

/* eslint-disable camelcase */
export enum ProviderScoreType {
  INTERNET = 'internet',
  MOBILE = 'mobile',
  TV = 'tv',
  TIME_TO_INSTALL = 'time_to_install',
  CUSTOMER_SERVICE = 'customer_service',
  USER_EXPERIENCE = 'user_experience',
  CORRECT_INVOICES = 'correct_invoices',
}

export interface ProviderScore {
  name: ProviderScoreType,
  score: number,
}

export interface ProviderSeo {
  title: string,
  description: string,
  meta_description: string,
  meta_keywords: string,
  title_h1: string,
  json_ld_markup: Record<string, any> | null,
}

export type PortingProvider = {
  name: string,
  slug: string,
}

export interface Provider {
  available_networks: NetworkType[] | null,
  avg_score: number,
  feedback_set_count: number,
  home: boolean,
  logo: string,
  mobile: boolean,
  name: string,
  network: string,
  pk: number,
  score_by_service: ProviderScore[],
  site_url: string,
  slug: string,
  seo: null | ProviderSeo,
  porting_providers: PortingProvider[],
}

export enum AgeGroup {
  '18_29' = '18-29',
  '30_39' = '30-39',
  '40+' = '40+',
}

export interface Review {
  [key: string]: any,
}

export enum OfferCategoryNamesType {
  MOBILE_ABO_OFFER = 'Mobile',
  HOME_OFFER = 'Home',
  BUNDLE_OFFER = 'Bundle',
  DEVICE_WITH_MOBILE_PLAN_OFFER = 'Device offer with mobile plan',
}

export type OfferCategoryType = keyof typeof OfferCategoryNamesType

export enum OfferCategoryIdsType {
  MOBILE_ABO_OFFER = 1,
  HOME_OFFER = 2,
  BUNDLE_OFFER = 3,
  DEVICE_WITH_MOBILE_PLAN_OFFER = 4,
}

export interface OfferBusinessCommissionMetrics {
  fpromtrid: number,
  gtrhref: number,
  timetosale: number,
}

export interface DeviceBrand {
  name: string,
  pk: number,
}

export interface DeviceImage {
  file_thumbnail_40_url: string,
  file_thumbnail_80_url: string,
  file_thumbnail_150_url: string,
  file_type: string,
  file_url: string,
}

export interface DeviceColor {
  color_hex_code: string,
  color_id: string,
  color_name: string,
  images: DeviceImage[],
}

export interface DeviceModelAttributes<T, U> {
  key: T,
  value: U,
}

export interface DeviceModel {
  attributes_en: DeviceModelAttributes<string, string>[],
  attributes_de: DeviceModelAttributes<string, string>[],
  attributes_fr: DeviceModelAttributes<string, string>[],
  battery: string,
  brand: DeviceBrand,
  camera: string,
  cpu: string,
  display: string,
  name: string,
  pk: number,
}

export interface Device {
  available_colors: DeviceColor[],
  available_gb_memory: number[],
  available_providers: number[],
  model: DeviceModel,
}

export enum OfferType {
  MOBILE_ABO_OFFER = 'mobile',
  HOME_OFFER = 'home',
  BUNDLE_OFFER = 'bundle',
  DEVICE_WITH_MOBILE_PLAN_OFFER = 'smartphone',
}

export type OfferTypeKey = keyof typeof OfferType

// TODO: Try to get rid of it
export enum OfferTypeByShortName {
  mobile = 'MOBILE_ABO_OFFER',
  home = 'HOME_OFFER',
  bundle = 'BUNDLE_OFFER',
  smartphone = 'DEVICE_WITH_MOBILE_PLAN_OFFER',
}

export type OfferTypeByShortNameKeys = keyof typeof OfferTypeByShortName

export interface OfferServiceRequestPayload {
  lang_code: string,
  sort: string,
  limit: number,
  offset: number,
  expert_tip?: string,
  [key: string]: any,
}

export interface MobileOfferServiceRequestPayload extends OfferServiceRequestPayload {
  mobile_providers: number[],
  data: string,
  call_options: string,
  age: number,
  europe: string,
  international_calls_ch_eu: boolean,
  international_calls_eu_ch: boolean,
}

export interface HomeOfferServiceRequestPayload
  extends OfferServiceRequestPayload {
  home_providers: number[],
  package: number[],
  age: number,
  advanced_filter_active: boolean,
  household_size: string,
  gaming: string,
  netflix: string,
  social_media: string,
  work_from_home: string,
  frequent_tv: string,
  extra_tv: string,
  roaming_europe: string,
  call_europe: string,
  zip?: string,
  city?: string,
  street?: string,
  street_number?: string,
}

export interface BundleOfferServiceRequestPayload
  extends OfferServiceRequestPayload {
  mobile_providers: number[],
  data: string,
  call_options: string,
  europe: string,
  international_calls_ch_eu: boolean,
  international_calls_eu_ch: boolean,
  advanced_mobile_filter_active: boolean,
  home_providers: number[],
  package: number[],
  age: number,
  advanced_home_filter_active: boolean,
  household_size: string,
  gaming: string,
  netflix: string,
  social_media: string,
  work_from_home: string,
  frequent_tv: string,
  extra_tv: string,
  roaming_europe: string,
  call_europe: string,
  zip?: string,
  city?: string,
  street?: string,
  street_number?: string,
}

export interface DeviceOfferServiceRequestPayload
  extends MobileOfferServiceRequestPayload {
  model_id: number,
  min_gb_memory: number | string,
  color_id: number | string,
}

export interface Under18EmailRequestPayload {
  offer_obj_pk: string,
  offer_obj_type: string,
  requester_first_name: string,
  requester_last_name: string,
  email: string,
}

export interface SignContractRequestPayload {
  file: FormData,
}

export enum ContractType {
  FIXED_CONTRACT = 'fixed_contract',
  PREPAID_CONTRACT = 'prepaid_contract',
}

// Used for analytics
export enum ContractTypes {
  fixed_contract = 'Postpaid',
  prepaid_contract = 'Prepaid',
}

export type ContractTypeKey = keyof typeof ContractTypes

export enum ProductVariants {
  BUNDLE = 'Bundle',
  INDIVIDUAL = 'Individual Plan',
}

export interface IGoogleRating {
  rating: number,
  reviews: number,
}

export interface CitiesResponse {
  cities: string[],
}

export interface StreetResponse {
  streets: string[],
}

export interface HouseResponse {
  houses: string[],
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export interface CreditCheckPayload {
  first_name: string,
  last_name: string,

  city: string,
  street: string,
  house: string,
  zip: string,

  email: string,
  mobile_number?: string,
  gender: Gender,
  date_of_birth: string,
}

export enum BonusType {
  VOUCHER = 'voucher',
  WELCOME = 'welcome',
  CASHBACK = 'cashback',
}

export interface Bonus {
  type: BonusType,
  translateKey: string,
  value: number,
}

export interface OfferPriceExplanation {
  price: number,
  contractDuration: number,
  effectivePrice: number,
  savings: number,
  benefits: IPriceExplanationBenefit[],
}

export type OfferPriceExplanationFormula = Omit<OfferPriceExplanation, 'benefits'>

export interface AlaoBenefit {
  profit_title: string,
  profit_value: string,
  currency: string,
  explanation?: string,
  tag?: string | null,
}

export enum OfferTag {
  ALAO = 'alao',
  ALAO_PLUS = 'alao_plus',
  OFFER_OF_THE_WEEK = 'offer_of_the_week',
  ALAO_PLUS_EXCLUSIVE = 'alao_plus_exclusive',
}

/* eslint-enable camelcase */

export interface OfferMobileSort {
  value: string,
  label: string,
}

export type WhatsAppShare = {
  offerUrl: string,
  offerTitle?: string,
  offerPrice: number,
  discount: number,
  deviceMemory?: string,
  deviceName?: string,
  providerName: string,
}

export interface OfferBadgeRaw {
  title: string,
  title_de: string | null,
  title_fr: string | null,
  subtitle: string,
  subtitle_de: string | null,
  subtitle_fr: string | null,
  title_font_size: 18,
  subtitle_font_size: number,
  color_hex_code: string,
  background: string,
}
