import md5 from 'md5'
import VueI18n from 'vue-i18n'
import type { Route } from 'vue-router'
import { Context } from '@nuxt/types'
import { BaseAnalytics } from '~/plugins/analytics/base-analytics'
import { OfferBenefitsUtils } from '~/utils/offer-benefits.utils'
import { AnalyticsVPVOfferTypes } from '~/plugins/analytics/types'
import { OfferCommonUtils } from '~/utils/offer/offer-common.utils'
import { ContractTypeKey, ContractTypes, OfferTypeKey, ProductVariants } from '~/core/offer.types'
import { QuizActionType } from '~/plugins/analytics/google/types'

export class GoogleAnalytics extends BaseAnalytics {
  constructor (protected provider: any, protected $i18n: VueI18n, protected nuxtContext: Context) {
    super(provider)
  }

  /**
   * Send a custom event into GTM
   *
   * @param {String} eventName
   * @param {Object} payload
   * @returns {void}
   */
  push (eventName: string, payload: Record<string, any> = {}) {
    try {
      this.provider.push({ event: eventName, ...payload })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  trackEvent (payload: Record<string, any> = {}) {
    this.push('GTMevent', payload)
  }

  trackCustomEvent (eventName: string, payload: any = {}) {
    this.push(eventName, payload)
  }

  sendPageView (pageTitle: string, $route: Route) {
    const { name, fullPath } = $route

    this.push('sendVirtualPageview', {
      routeName: name,
      pageType: 'PageView',
      pageUrl: fullPath,
      fullUrl: location.href,
      pageTitle,
      vpv: location.href.replace(location.origin, ''),
      previousUrl: this.nuxtContext.from ? location.origin + this.nuxtContext.from.path : '',
    })
  }

  getVirtualPageViewPageTitle (offerType: OfferTypeKey, offerName: string, stepName: string) {
    return `${offerType} | ${offerName} | ${stepName}`
  }

  getVirtualPageViewUrl (offerType: OfferTypeKey, stepPath: string): string {
    const {
      pathname: pathNameRaw,
      search,
    } = window.location || {}
    const vpvOfferType = this.getVpvOfferType(offerType)
    const pathName = pathNameRaw.replace(/\/$/g, '') // just in case the pathname ended with '/'

    return `${pathName}/${vpvOfferType}/${stepPath}/${search}`
  }

  sendVirtualPageView (offerType: OfferTypeKey, stepPath: string, stepName: string, offerName: string, routeName: string) {
    const pageUrl = this.getVirtualPageViewUrl(offerType, stepPath)
    const pageTitle = this.getVirtualPageViewPageTitle(offerType, offerName, stepName)

    this.push('sendVirtualPageview', {
      routeName,
      pageType: 'PageView',
      pageUrl,
      fullUrl: location.href,
      pageTitle,
      vpv: location.href.replace(location.origin, ''),
      previousUrl: this.nuxtContext.from ? location.origin + this.nuxtContext.from.path : '',
    })
  }

  sendOrderStepsEvent (stepName: string, meta: any) {
    const { offerType, offerName, finalPrice, offerProviderName } = meta
    const categoryName = this.utils.getOfferCategoryName(offerType)
    const eventLabel = this.utils.getEventLabel(offerType, offerProviderName)

    const payload = {
      eventCategory: `${stepName} - ${categoryName}`,
      eventAction: `${offerName} - ${finalPrice}`,
      eventLabel,
    }

    this.trackEvent(payload)
  }

  sendECommerceEvent (payload: any) {
    this.push('GTMecommerce', payload)
  }

  sendECommerceImpressionsEvent (offer: any, productMeta: any) {
    const { listPosition, offerPosition } = productMeta

    const products: any = this.getECommerceProductsPayload(offer, productMeta)
    const eventLabel = this.getECommerceEventLabel(offer)

    products.forEach((product: any) => {
      product.list = listPosition
      product.position = offerPosition
    })

    const eCommercePayload = {
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'product-impressions',
      eventLabel,
      ecommerce: {
        currencyCode: 'CHF',
        impressions: products,
      },
    }

    this.push('GTMecommerce', eCommercePayload)
  }

  sendECommerceProductClickEvent (offer: any, productMeta: any) {
    const { listPosition, offerPosition } = productMeta

    const products: any = this.getECommerceProductsPayload(offer, productMeta)
    const eventLabel = this.getECommerceEventLabel(offer)

    products.forEach((product: any) => {
      delete product.quantity
      product.position = offerPosition
    })

    const eCommercePayload = {
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'product-click',
      eventLabel,
      ecommerce: {
        currencyCode: 'CHF',
        click: {
          actionField: {
            list: listPosition,
          },
          products,
        },
      },
    }

    this.push('GTMecommerce', eCommercePayload)
  }

  sendECommerceProductDetailsEvent (offer: any, productMeta: any) {
    const { listPosition } = productMeta

    const products = this.getECommerceProductsPayload(offer, productMeta)
    const eventLabel = this.getECommerceEventLabel(offer)

    products.forEach((product: any) => {
      delete product.quantity
    })

    const eCommercePayload = {
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'product-details',
      eventLabel,
      ecommerce: {
        currencyCode: 'CHF',
        detail: {
          actionField: {
            list: listPosition,
          },
          products,
        },
      },
    }

    this.push('GTMecommerce', eCommercePayload)
  }

  sendECommerceAddToCartEvent (offer: any, productMeta: any) {
    const { listPosition } = productMeta

    const products: any = this.getECommerceProductsPayload(offer, productMeta)
    const eventLabel = this.getECommerceEventLabel(offer)

    products.forEach((product: any) => {
      delete product.quantity
    })

    const eCommercePayload = {
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'product-add-to-cart',
      eventLabel,
      ecommerce: {
        currencyCode: 'CHF',
        add: {
          actionField: {
            list: listPosition,
          },
          products,
        },
      },
    }

    this.push('GTMecommerce', eCommercePayload)
  }

  sendECommerceProductCheckoutEvent (offer: any, productMeta: any, orderMeta: { email: string, phoneNumber: string }, step: number) {
    const { listPosition } = productMeta

    const products: any = this.getECommerceProductsPayload(offer, productMeta)
    const eventLabel = this.getECommerceEventLabel(offer)
    const { email, phoneNumber } = orderMeta

    products.forEach((product: any) => {
      delete product.quantity
    })

    const eCommercePayload: Record<string, any> = {
      eventCategory: 'enhanced-ecommerce',
      eventAction: `product-checkout-${step}`,
      eventLabel,
      ecommerce: {
        currencyCode: 'CHF',
        checkout: {
          actionField: {
            list: listPosition,
            step,
          },
          products,
        },
      },
    }

    if (email) {
      eCommercePayload.email = email
      eCommercePayload.userId = md5(email)
    }

    if (phoneNumber) {
      eCommercePayload.phone_number = phoneNumber
    }

    this.push('GTMecommerce', eCommercePayload)
  }

  sendECommercePurchaseEvent (offer: any, productMeta: any, orderMeta: any) {
    const { listPosition, couponBonuses, isEPUsed } = productMeta
    const { orderId, email, phoneNumber, city, zip } = orderMeta

    const { isBundle, isDevice, offerProviderName, finalPrice, revenue } = new OfferCommonUtils(offer)

    const products: any = this.getECommerceProductsPayload(offer, productMeta)

    const eventLabel = this.getECommerceEventLabel(offer)
    const hashEmail = md5(email)

    // Attach transactionId for each product to dimension17 property
    products.forEach((p: any) => (p.dimension17 = orderId))

    let eCommercePayload: any = {
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'product-purchase',
      eventLabel,
      ecommerce: {
        currencyCode: 'CHF',
        purchase: {
          actionField: {
            id: orderId,
            affiliation: offerProviderName,
            revenue,
            tax: 0,
            shipping: 0,
            list: listPosition,
          },
          products,
        },
      },
      userId: hashEmail,
      purchaseCity: city,
      purchaseZip: zip,
      email,
      phone_number: phoneNumber,
      final_price_per_month: finalPrice,
    }

    if (isBundle || isDevice) {
      const cashback = this._getOfferBonusesByType('cashback', offer, isEPUsed, couponBonuses)

      eCommercePayload = {
        ...eCommercePayload,
        cashback,
        fullTransactionAmount: parseFloat(products.reduce((acc: number, curr: any) => acc + curr.metric1, 0).toFixed(2)),
        faceValuePrice: parseFloat(products.reduce((acc: number, curr: any) => acc + curr.dimension16, 0).toFixed(2)),
      }
    }

    this.push('GTMecommerce', eCommercePayload)
  }

  sendPhotoViaMobileEvent (action: string, productName: string) {
    this.trackEvent({
      eventCategory: 'photo-via-mobile',
      eventAction: action,
      eventLabel: productName,
    })
  }

  sendFraudPreventionEvent (reason = 'Unable to determine rejection reason') {
    this.trackEvent({
      eventCategory: 'Fraud Prevention Modal Popup',
      eventAction: 'viewComponent',
      eventLabel: reason,
    })
  }

  sendB2BFormEvent (action: string, company: string, employees: string) {
    this.trackEvent({
      eventCategory: 'b2b-form',
      eventAction: action,
      eventLabel: `${company}|${employees}`,
    })
  }

  sendFAQEvent (title: string) {
    this.trackEvent({
      eventCategory: 'FAQs',
      eventAction: 'click',
      eventLabel: title,
    })
  }

  sendBestDealGuaranteeViewEvent (action: 'view-badge' | 'view-description', path: string) {
    this.trackEvent({
      eventCategory: 'best-price-guarantee-badge',
      eventAction: action,
      eventLabel: path,
    })
  }

  sendQuizCTAClickEvent (lang: string, btnText: string) {
    this.trackEvent({
      eventCategory: 'quiz',
      eventAction: 'button_click',
      eventLabel: btnText,
      language_code: lang.toUpperCase(),
    })
  }

  sendQuizQuestionViewEvent (
    step: number,
    lang: string,
    category?: string,
  ) {
    const eventAction = this.formatQuizQuestionEventAction(step, category)

    this.trackEvent({
      eventCategory: 'quiz',
      language_code: lang.toUpperCase(),
      eventAction,
      eventLabel: QuizActionType.VIEW,
    })
  }

  sendQuizQuestionChooseEvent (
    step: number,
    lang: string,
    answer: string,
    category?: string,
  ) {
    const eventAction = this.formatQuizQuestionEventAction(step, category)

    this.trackEvent({
      eventCategory: 'quiz',
      eventAction,
      language_code: lang.toUpperCase(),
      eventLabel: [QuizActionType.CHOOSE, answer].join('|'),
    })
  }

  sendQuizQuestionCompleteEvent (
    step: number,
    lang: string,
    category?: string,
  ) {
    const eventAction = this.formatQuizQuestionEventAction(step, category, true)

    this.trackEvent({
      eventCategory: 'quiz',
      language_code: lang.toUpperCase(),
      eventAction,
      eventLabel: '',
    })
  }

  sendSocialLinkEvent (socialName: string, socialUrl: string, lang: VueI18n.Locale) {
    this.trackEvent({
      eventCategory: 'ty_page_share_icon_click',
      eventAction: socialName,
      eventLabel: socialUrl,
      language_code: lang.toUpperCase(),
    })
  }

  sendShareButtonClickEvent (buttonName: string, lang: VueI18n.Locale) {
    this.trackEvent({
      eventCategory: 'ty_page_share_button_click',
      eventAction: 'copy-link',
      eventLabel: buttonName,
      language_code: lang.toUpperCase(),
    })
  }

  sendShareCopyUrlEvent (url: string, lang: VueI18n.Locale) {
    this.trackEvent({
      eventCategory: 'ty_page_share_copy_url_click',
      eventAction: 'link-click',
      eventLabel: url,
      language_code: lang.toUpperCase(),
    })
  }

  private formatQuizQuestionEventAction (step: number, category?: string, complete = false): string {
    const base = 'step'

    if (complete && category) {
      return `complete|${category}`
    }

    if (category) {
      return `${base}_${step}|${category}`
    }

    return `${base}_${step}`
  }

  getClientId () {
    try {
      // TODO: Type it
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.ga.getAll()[0].get('clientId')
    } catch (e) {
      return null
    }
  }

  /**
   * Assign previous client ID in case we were able to obtain it from previous session,
   * basically from Sale object that comes from API.
   *
   * It really helps us keep track of the customer's device change
   * @param {string} clientId - UID
   * @param {string} referrer - A place user comes from (e.g. email, sms, etc)
   * @param {string} productName - Offer name
   */
  setClientId (clientId: string, referrer = 'undefined', productName: string) {
    const action = `${referrer}-link-open`

    this.trackEvent({
      eventCategory: 'proceed-checkout',
      eventAction: action,
      eventLabel: productName,
      CID: clientId || '',
    })
  }

  protected getECommerceEventLabel (offer: any) {
    const offerUtils = new OfferCommonUtils(offer)
    const { isMobile, isHome, isBundle, isDevice } = offerUtils

    const offerName = offerUtils.getOfferName('-')

    if (isMobile) { return `Mobile_${offerName}` }
    if (isHome) { return `Home_${offerName}` }
    if (isBundle) { return `Mobile|Home_${offerName}` }
    if (isDevice) { return `Mobile|Smartphone_${offerName}` }

    return 'undefined'
  }

  protected getVpvOfferType (offerType: OfferTypeKey) {
    return AnalyticsVPVOfferTypes[offerType] || 'undefined-offer'
  }

  protected getECommerceProductsPayload (offer: any, productMeta: any) {
    const {
      couponBonuses = {},
      discountCodesList = [],
      isEPUsed = false,
      mobileContractType = null,
      homeContractType = null,
      keepMobileNumber = null,
      keepHomeNumber = null,
      previousMobileProvider = null,
      previousHomeProvider = null,
      deviceColor = null,
    } = productMeta

    const offerUtils = new OfferCommonUtils(offer)
    const { isMobile, isHome, isBundle, isDevice, finalPrice, regularPrice } = offerUtils
    const sumUserPays = offerUtils.sumUserPaysDuringContractOffer(isEPUsed)

    const offerBenefitsI18n = new OfferBenefitsUtils(offer, this.$i18n, 'en')

    const products = []

    if (isMobile || isBundle || isDevice) {
      const priceDiscountLabel = this._getPriceDiscountLabel(
        offer.mobile_offer.discount,
        offer.mobile_offer.price,
        offer.mobile_offer.final_price,
      )

      products.push({
        name: offer.mobile_offer.name,
        id: offer.mobile_offer.pk,
        price: offer.mobile_offer._uetvid,
        brand: offer.mobile_offer.provider.name,
        category: 'Mobile',
        variant: isBundle || isDevice ? ProductVariants.BUNDLE : ProductVariants.INDIVIDUAL,
        coupon: discountCodesList.join('|'),
        quantity: 1,
        metric1: sumUserPays.mobile_offer,
        dimension3: priceDiscountLabel,
        dimension4: mobileContractType !== null ? ContractTypes[mobileContractType as ContractTypeKey] : '',
        dimension5: keepMobileNumber ? 'Yes' : 'No',
        dimension6: previousMobileProvider !== null ? previousMobileProvider : '',
        dimension7: offerUtils.getDiscountedActivationCostMobile(isEPUsed) === 0 ? 'Yes' : 'No',
        dimension8: offerUtils.tag ? 'alao_plus_offer' : 'default_offer',
        dimension9: `${offerBenefitsI18n.mobileCallsInEU}|${offerBenefitsI18n.mobileCallsFromCHToEU}|${offerBenefitsI18n.mobileCallsFromCHToEU}`,
        dimension10: offerBenefitsI18n.mobileInternetInCH,
        dimension11: offerBenefitsI18n.mobileContractDuration,
        dimension12: offer.mobile_offer.provider.network,
        dimension13: offerBenefitsI18n.mobileInternetInEU,
        dimension14: 'Not included',
        dimension15: '',
        dimension16: parseFloat(offer.mobile_offer.price),
        dimension17: '',
        dimension18: '',
      })
    }

    if (isHome || isBundle) {
      const priceDiscountLabel = this._getPriceDiscountLabel(
        offer.home_offer.discount,
        offer.home_offer.price,
        offer.home_offer.final_price,
      )

      products.push({
        name: offer.home_offer.name,
        id: offer.home_offer.pk,
        price: offer.home_offer._uetvid,
        brand: offer.home_offer.provider.name,
        category: 'Home',
        variant: isBundle ? ProductVariants.BUNDLE : ProductVariants.INDIVIDUAL,
        coupon: discountCodesList.join('|'),
        quantity: 1,
        metric1: sumUserPays.home_offer,
        dimension3: priceDiscountLabel,
        dimension4: homeContractType !== null ? ContractTypes[homeContractType as ContractTypeKey] : '',
        dimension5: keepHomeNumber ? 'Yes' : 'No',
        dimension6: previousHomeProvider !== null ? previousHomeProvider : '',
        dimension7: offerUtils.getDiscountedActivationCostHome(isEPUsed) === 0 ? 'Yes' : 'No',
        dimension8: offerUtils.tag ? 'alao_plus_offer' : 'default_offer',
        dimension9: offerBenefitsI18n.homeCallsFromCHToEU,
        dimension10: offerBenefitsI18n.homeInternet,
        dimension11: offerBenefitsI18n.homeContractDuration,
        dimension12: offer.home_offer.provider.network,
        dimension13: offerBenefitsI18n.homeInternetInEU,
        dimension14: offerBenefitsI18n.homeTV,
        dimension15: '',
        dimension16: parseFloat(offer.home_offer.price),
        dimension17: '',
        dimension18: '',
      })
    }

    if (isDevice) {
      const priceDiscountLabel = this._getPriceDiscountLabel(
        offer.mobile_offer.discount || {},
        parseFloat(offer.device_offer.regular_full_device_price || offer.device_offer.full_device_price),
        parseFloat(offer.device_offer.full_device_price),
      )

      const { color, offers_with_other_colors: offersWithOtherColors } = offer.device_offer
      const colorObj = [color, ...offersWithOtherColors].find((item) => {
        return item.color_id === deviceColor
      })

      const deviceColorName = (colorObj && colorObj.color_name) || null

      products.push({
        name: `${offer.device_offer.device.model.brand.name} ${offer.device_offer.device.model.name}`,
        id: offer.device_offer.pk,
        price: offer.device_offer._uetvid,
        brand: offer.device_offer.device.model.brand.name,
        category: 'Smartphone',
        variant: `${deviceColorName}|${offer.device_offer.device.gb_memory}`,
        coupon: discountCodesList.join('|'),
        quantity: 1,
        metric1: parseFloat(offer.device_offer.full_device_price),
        dimension3: priceDiscountLabel,
        dimension4: 'Postpaid',
        dimension11: '24 months',
        dimension15: '',
        dimension16:
          parseFloat(offer.device_offer.regular_full_device_price) || parseFloat(offer.device_offer.full_device_price),
        dimension17: '',
        dimension18: '',
      })
    }

    if (isMobile || isBundle || isDevice) {
      const mobileCashback = this._getOfferBonusesByType('cashback', offer.mobile_offer, isEPUsed, couponBonuses)
      const mobileVoucher = this._getOfferBonusesByType('voucher', offer.mobile_offer, isEPUsed, couponBonuses)
      const productIndex = 0

      if (mobileCashback) { products[productIndex].dimension15 = mobileCashback }
      if (mobileVoucher) { products[productIndex].dimension18 = mobileVoucher }
    }

    if (isHome || isBundle) {
      const homeCashback = this._getOfferBonusesByType('cashback', offer.home_offer, isEPUsed, couponBonuses)
      const homeVoucher = this._getOfferBonusesByType('voucher', offer.home_offer, isEPUsed, couponBonuses)
      const productIndex = isBundle ? 1 : 0

      if (homeCashback) { products[productIndex].dimension15 = homeCashback }
      if (homeVoucher) { products[productIndex].dimension18 = homeVoucher }

      // TODO: Looks like a dirty crutch, it is necessary to be refactored in nearest future
      // If homeCashback is exist we shouldn't apply mobileCashback
      if (products[0].dimension15 && homeCashback) {
        products[0].dimension15 = ''
      }

      // If homeVoucher is exist we shouldn't apply mobileVoucher
      if (products[0].dimension18 && homeVoucher) {
        products[0].dimension18 = ''
      }
    }

    if (isBundle || isDevice) {
      const bundleCashback = this._getOfferBonusesByType('cashback', offer, isEPUsed, couponBonuses)
      const bundleVoucher = this._getOfferBonusesByType('voucher', offer, isEPUsed, couponBonuses)

      if (bundleCashback) { products.forEach(p => (p.dimension15 = bundleCashback)) }
      if (bundleVoucher) { products.forEach(p => (p.dimension18 = bundleVoucher)) }
    }

    // Remove all keys whose value is null
    products.forEach((product: any) => {
      Object.keys(product).forEach((key) => {
        if (product[key] === null) {
          delete product[key]
        }

        if (isBundle) {
          if (offer.bundle_discount_days_left) {
            product.dimension3 = this._getPriceDiscountLabel({}, regularPrice, finalPrice)
          }
        }
      })
    })

    return products
  }

  /**
   *
   * @param {('cashback'|'voucher')} bonusType - Bonus type
   * @param {Object} offer - Offer object which contains the bonuses (it could be mobile offer, home offer or bundle)
   * @param {Boolean} isEPUsed - A flag shows is exit prevention bonus was applied
   * @param {Object} couponBonuses - Contains bonuses earned by using a discount code.
   * @return {string} - A string contains the same type bonuses and looks like 'CHF 20.- Welcome bonus|CHF 15.-
   * Welcome bonus'
   */
  _getOfferBonusesByType (bonusType: string, offer: any, isEPUsed = false, couponBonuses: any[]) {
    const bonusesList: any[] = []

    if (bonusType === 'cashback') {
      (offer.cashbacks || []).forEach((bonus: any) => {
        if ((bonus.only_for_exit_prevention && isEPUsed) || !bonus.only_for_exit_prevention) {
          bonusesList.push(bonus.applied_text)
        }
      })
    }

    if (bonusType === 'voucher') {
      (offer.vouchers || []).forEach((bonus: any) => {
        if ((bonus.only_for_exit_prevention && isEPUsed) || !bonus.only_for_exit_prevention) {
          bonusesList.push(bonus.applied_text)
        }
      })
    }

    if (couponBonuses.length) {
      couponBonuses.forEach((bonus: any) => {
        if (bonus.profit_type.toLowerCase() === bonusType) {
          bonusesList.push(bonus.applied_text_en)
        }
      })
    }

    return bonusesList.join('|')
  }

  _getPriceDiscountLabel (discount: any, regularPrice: number, finalPrice: number) {
    if (!discount) { return '' }

    const priceDiscount = 100 - Math.round((100 / regularPrice) * finalPrice)

    if (discount.discount_lifetime) {
      return `${priceDiscount}% discount forever`
    }

    return `${priceDiscount}% discount`
  }
}
