
import { Component, Vue } from 'vue-property-decorator'
import NotificationItem from './AlaoNotificationItem.vue'
import { eventBus } from './event-bus'
import { Notification } from './types'

@Component({
  components: {
    NotificationItem,
  },
})
export default class AlaoNotification extends Vue {
  private notifications: Notification[] = []

  onClose (id: string) {
    this.notifications = this.notifications.filter((item: Record<string, any>) => {
      return item.id !== id
    })
  }

  addItem (payload: Notification) {
    this.notifications.unshift({
      ...payload,
      id: Math.random().toString().substring(2),
    })
  }

  mounted () {
    eventBus.$on('notify', this.addItem)
  }
}
