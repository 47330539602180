import { ApplicationService } from '~/services/core/application.service'

export class EmarsysService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.EMARSYS_API_BASE_URL
  }

  registerForm (payload: Record<string, string>) {
    return this.get('/u/register.php', payload)
  }
}
