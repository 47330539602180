
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class OfferDealPrices extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  finalPrice!: number

  @Prop({
    type: Number,
    required: true,
  })
  regularPrice!: number
}
