
import { Component } from 'nuxt-property-decorator'
import DefaultLayout from '~/layouts/default.vue'

@Component({
  components: {
  },
})
export default class EmptyLayout extends DefaultLayout {
}
