import type { ProviderModel } from '@alao-frontend/erp-services'
import { FiltersList, AmpMobileFiltersState as MobileFiltersState } from '~/core/config/filters/types'

export const getInitialState = (
  rootGetters?: any,
): MobileFiltersState => {
  let providers: ProviderModel[] = []

  if (rootGetters) {
    providers = rootGetters['modules/BaseInfo/mobileProvidersWithTalkTalk']
  }

  return {
    providers: (providers?.map(item => item.slug) || []),
    data_options: [],
    call_options: ['unlimited_calls_in_ch'],
    five_g_only: false,
  }
}

export const filtersList = (
  rootGetters: any,
): FiltersList => {
  const defaultValues = getInitialState(rootGetters)

  return [
    {
      name: 'providers', // key in initialState
      component: 'TileSelection',
      props: {
        title: 'offerFilters.providers',
        expandableLimit: 6,
        isExpandable: true,
        defaultValue: defaultValues.providers,
        items: rootGetters['modules/BaseInfo/mobileProvidersWithTalkTalk'],
      },
    },
    {
      name: 'data_options', // key in initialState
      component: 'MultipleSelection',
      props: {
        title: 'offerFilters.dataOptions',
        defaultValue: defaultValues.data_options,
        items: [
          {
            name: 'offerFilters.unlimitedDataInCH',
            value: 'unlimited_data_in_ch',
          },
          {
            name: 'offerFilters.someDataInEU',
            value: 'some_data_in_eu',
          },
          {
            name: 'offerFilters.unlimitedDataInEU',
            value: 'unlimited_data_in_eu',
          },
        ],
      },
    },
    {
      name: 'five_g_only', // key in initialState
      component: 'ComparatorSwitch',
      props: {
        title: 'offerFilters.showOnly5GPlans',
        defaultValue: defaultValues.five_g_only,
      },
    },
    {
      name: 'call_options', // key in initialState
      component: 'MultipleSelection',
      props: {
        title: 'offerFilters.callOptions',
        defaultValue: defaultValues.call_options,
        items: [
          {
            name: 'offerFilters.unlimitedCallsInCH',
            value: 'unlimited_calls_in_ch',
            group: ['1'],
          },
          {
            name: 'offerFilters.callsWithinEU',
            value: 'calls_in_eu',
            group: ['1'],
          },
          {
            name: 'offerFilters.callsFromCHToEU',
            value: 'unlimited_calls_from_ch_to_eu',
            group: ['1'],
          },
          {
            name: 'offerFilters.noCalls',
            value: 'no_calls',
            group: ['2'],
          },
        ],
      },
    },
  ]
}
