import { Provider } from '~/core/offer.types'

export const providersSequence = [
  'swisscom',
  'sunrise',
  'upc',
  'salt',
  'wingo',
  'yallo',
  'm-budget',
  'init7',
  'quickline',
]

export const sortProvidersBySequence = (providersList: Provider[]) => {
  const rawProvidersList = [...providersList]
  let sortedProvidersList: Provider[] = []

  for (const key of providersSequence) {
    const idx = rawProvidersList.findIndex(p => p.name.toLowerCase() === key)

    if (idx >= 0) {
      const foundProviders = rawProvidersList.splice(idx, 1)

      sortedProvidersList = sortedProvidersList.concat(foundProviders)
    }
  }

  return sortedProvidersList.concat(rawProvidersList)
}
