import { QuizQuestion } from '~/core/config/comparator/quiz/interfaces/types'
import { QuizAction } from '~/core/config/comparator/quiz/interfaces/enums'

export const questions: QuizQuestion[] = [
  {
    id: 1,
    title: 'quiz.questions.chooseYourPlan',
    answers: [
      {
        id: 1,
        adviceCodePart: '70',
        title: 'quiz.questions.mobile',
        icon: require('~/assets/icons/icon-mobile.svg'),
        action: {
          type: QuizAction.ROUTE,
          name: 'compare-mobile',
        },
        nextQuestionId: 2,
      },
      {
        id: 2,
        adviceCodePart: '80',
        title: 'quiz.questions.home',
        icon: require('~/assets/icons/icon-home.svg'),
        action: {
          type: QuizAction.ROUTE,
          name: 'compare-home',
        },
        nextQuestionId: 3,
      },
      {
        id: 3,
        adviceCodePart: '90',
        title: 'quiz.questions.bundle',
        icon: require('~/assets/icons/icon-bundle.svg'),
        action: {
          type: QuizAction.ROUTE,
          name: 'compare-bundle',
        },
        nextQuestionId: 4,
      },
    ],
    root: true,
  },
  {
    id: 2,
    title: 'quiz.questions.planUsage',
    answers: [
      {
        id: 4,
        adviceCodePart: '0',
        title: 'quiz.questions.chOnly',
        icon: require('~/assets/icons/icon-flag-ch.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'unlimited_calls_in_ch',
        },
        nextQuestionId: 6,
      },
      {
        id: 5,
        adviceCodePart: '1',
        title: 'quiz.questions.sometimesEU',
        icon: require('~/assets/icons/icon-flag-eu.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'calls_in_eu',
        },
        nextQuestionId: 6,
      },
      {
        id: 6,
        adviceCodePart: '2',
        title: 'quiz.questions.callsCHtoEU',
        icon: require('~/assets/icons/icon-ch-to-eu.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'unlimited_calls_from_ch_to_eu',
        },
        nextQuestionId: 6,
      },
    ],
  },
  {
    id: 3, // For mobile/home offers
    title: 'quiz.questions.whatYouNeed',
    answers: [
      {
        id: 7,
        adviceCodePart: '0',
        title: 'quiz.questions.internetOnly',
        icon: require('~/assets/icons/icon-internet-only.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'internetOnly',
        },
        nextQuestionId: 6,
      },
      {
        id: 8,
        adviceCodePart: '1',
        title: 'quiz.questions.internetAndTV',
        icon: require('~/assets/icons/icon-internet-tv.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'internetAndTV',
        },
        nextQuestionId: 6,
      },
      {
        id: 9,
        adviceCodePart: '2',
        title: 'quiz.questions.internetTVAndLandline',
        icon: require('~/assets/icons/icon-internet-tv-landline.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'callsCHToEULandline',
        },
        nextQuestionId: 6,
      },
    ],
  },
  {
    id: 4,
    title: 'quiz.questions.planUsage',
    answers: [
      {
        id: 10,
        adviceCodePart: '0',
        title: 'quiz.questions.chOnly',
        icon: require('~/assets/icons/icon-flag-ch.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'call_options',
          payload: 'unlimited_calls_in_ch',
        },
        nextQuestionId: 5,
      },
      {
        id: 11,
        adviceCodePart: '1',
        title: 'quiz.questions.sometimesEU',
        icon: require('~/assets/icons/icon-flag-eu.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'call_options',
          payload: 'calls_in_eu',
        },
        nextQuestionId: 5,
      },
      {
        id: 12,
        adviceCodePart: '2',
        title: 'quiz.questions.callsCHtoEU',
        icon: require('~/assets/icons/icon-ch-to-eu.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'call_options',
          payload: 'unlimited_calls_from_ch_to_eu',
        },
        nextQuestionId: 5,
      },
    ],
  },
  {
    id: 5, // For bundle offers only
    title: 'quiz.questions.whatYouNeed',
    answers: [
      {
        id: 13,
        adviceCodePart: '0',
        title: 'quiz.questions.internetOnly',
        icon: require('~/assets/icons/icon-internet-only.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'internetAndMobileOnly',
        },
        nextQuestionId: 6,
      },
      {
        id: 14,
        adviceCodePart: '1',
        title: 'quiz.questions.internetAndTV',
        icon: require('~/assets/icons/icon-internet-tv.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'internetTVMobile',
        },
        nextQuestionId: 6,
      },
      {
        id: 15,
        adviceCodePart: '2',
        title: 'quiz.questions.internetTVAndLandline',
        icon: require('~/assets/icons/icon-internet-tv-landline.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here',
          payload: 'callsCHToEULandline',
        },
        nextQuestionId: 6,
      },
    ],
  },
  {
    id: 6,
    title: 'quiz.questions.getStarted',
    answers: [
      {
        id: 16,
        title: 'quiz.questions.compare',
        icon: require('~/assets/icons/icon-compare.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'action_name_here11',
          payload: 'payload_here',
        },
        nextQuestionId: null,
      },
      {
        id: 17,
        title: 'quiz.questions.advice',
        icon: require('~/assets/icons/icon-advice.svg'),
        action: {
          type: QuizAction.DISPATCH,
          name: 'get-advice',
        },
        nextQuestionId: null,
      },
    ],
  },
]
