import { Context } from '@nuxt/types'
import { createServices } from '~/plugins/axios/lib/utils'
import { API } from '~/plugins/axios/types'
import { attachInterceptors } from '~/plugins/axios/lib/interceptors'
import { FeatureFlags } from '~/core/config/feature-flags'
import { CUSTOM_HEADER, INITIAL_HEADERS } from '~/core/config/headers'

// eslint-disable-next-line import/no-mutable-exports
let $api: API

export default function (ctx: Context) {
  attachInterceptors(ctx.$axios)

  ctx.$axios.defaults.withCredentials = true

  if (ctx.$featureFlags.isEnabled(FeatureFlags.AMP_BASE_INFO)) {
    Object.entries(INITIAL_HEADERS).forEach(([header, value]) => ctx.$axios.setHeader(header, value))
    ctx.$axios.setHeader(CUSTOM_HEADER.LANG, ctx.i18n.locale)
  }

  $api = createServices(ctx.app.$axios, ctx.$config)
}

export {
  $api,
}
