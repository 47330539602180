
import { Component } from 'nuxt-property-decorator'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import UiDropdown from '~/components/ui/UIDropdown/UiDropdown.vue'
import AppLangSwitcher from '~/components/snippets/AppLangSwitcher.vue'
import { FeatureFlags } from '~/core/config/feature-flags'
import { SiteNavigationMixin } from '~/mixins/site-navigation.mixin'

@Component({
  components: {
    AppLangSwitcher,
    UiDropdown,
  },
})
export default class MobileMenu extends SiteNavigationMixin {
  get isFrenchLocale () {
    return this.$i18n.locale === 'fr'
  }

  get showB2B () {
    return this.$featureFlags.isEnabled(FeatureFlags.B2B_PAGE) && this.$i18n.locale === 'de'
  }

  toggleMobileNav (): void {
    this.$emit('toggle-mobile-menu')
  }

  mounted () {
    disableBodyScroll(this.$el)
  }

  beforeDestroy () {
    enableBodyScroll(this.$el)
  }
}
