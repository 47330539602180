import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'

const siteLevelPromoCode: Middleware = ctx => createNuxtMiddleware(
  ctx,
  (
    utils,
  ) => {
    const { route } = ctx

    const { SLPC_QUERY_PARAM_NAME: paramName } = ctx.$config

    if (!route.query[paramName]) {
      return
    }

    const {
      SLPC_COOKIE_NAME: cookieName,
      SLPC_COOKIE_EXP: expiration,
      APP_DOMAIN: domain,
    } = ctx.$config

    const promoCode = route.query[paramName].toString()

    utils.setCookie(cookieName, promoCode, {
      expires: expiration,
      domain,
      path: '/',
    })
  })
export default siteLevelPromoCode
