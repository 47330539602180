import { ApplicationService } from '~/services/core/application.service'
import { WheelOfFortuneBonus } from '~/types/wheel-of-fortune.types'

export interface TrustedByCounterResponse {
  // eslint-disable-next-line camelcase
  trusted_by: number,
}

/* eslint-disable camelcase */
export interface B2bFormRequestPayload {
  company_name: string,
  contact_person: string,
  phone_number: string,
  email: string,
  number_of_employees: string,
  purpose: string,
  comment: string,
  token: string,
}

export interface WheelOfFortuneBonusDTO {
  'profit_title': string | null,
  'promocode': string,
  'profit_value': number,
  'bonus_type': 'voucher' | 'cashback',
}

export interface WheelOfFortuneResponse {
  bonuses: WheelOfFortuneBonusDTO[],
}

export class CommonService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  getTrustedByCounter (): Promise<TrustedByCounterResponse> {
    return this.get('/api/trusted_by_counter/', {}, {
      cache: {
        excludeFromCache: false,
      },
    })
  }

  async getWheelOfFortuneBonuses (locale: string): Promise<WheelOfFortuneBonus[]> {
    try {
      const response = await this.get<WheelOfFortuneResponse>('/api/wheel_of_fortune/', {
        lang_code: locale,
      })
      return response.bonuses.map(bonus => ({
        title: bonus.profit_title,
        code: bonus.promocode,
        value: bonus.profit_value,
        type: bonus.bonus_type,
      }))
    } catch (e) {
      return []
    }
  }

  submitAffiliateForm (payload: any) {
    return this.post('/api/affiliates/', payload)
  }

  submitContactUsForm (payload: any, token: string) {
    const formData = new FormData()
    Object.keys(payload).forEach((key: string) => {
      formData.append(key, payload[key])
    })
    formData.append('token', token)
    return this.post('/api/contact_us_message/', formData)
  }

  getDynamicPageContent (slug: string) {
    return this.get(`/api/page/${slug}/`)
  }

  getB2bFormFields () {
    return this.get('/api/landings/b2b/')
  }

  submitB2bForm (payload: B2bFormRequestPayload) {
    return this.post('/api/landings/b2b/', payload)
  }
}
