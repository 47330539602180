import { CampaignDocumentFields } from '~/types/promo/campaign'
import { Operator } from '~/types/promo/firebase'
import { QueryBuilder } from '~/services/promo/query-builder'

export class CampaignsQuery extends QueryBuilder {
  protected readonly collectionId = 'campaigns'
  protected readonly fields: CampaignDocumentFields = [
    'name',
    'dateStart',
    'dateEnd',
  ]

  constructor (
    protected readonly projectId: string,
    protected readonly dbName: string,
  ) {
    super()
  }

  list () {
    this.conditions = {
      type: 'composite',
      op: Operator.AND,
      filters: [
        {
          fieldFilter: {
            field: {
              fieldPath: 'isPublished',
            },
            op: Operator.EQUAL,
            value: {
              booleanValue: true,
            },
          },
        },
      ],
    }

    return this
  }
}
