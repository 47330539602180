import { ApplicationService } from '~/services/core/application.service'
import { CitiesResponse, HouseResponse, StreetResponse } from '~/core/offer.types'

const ServiceRouteTypes = {
  API_AUTOCOMPLETE_CITIES: '/api/address_autocomplete/cities',
  API_AUTOCOMPLETE_STREETS: '/api/address_autocomplete/streets',
  API_AUTOCOMPLETE_HOUSES: '/api/address_autocomplete/houses',
}

export class AddressService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  getCitiesByZip (zip: string): Promise<CitiesResponse> {
    return this.get(ServiceRouteTypes.API_AUTOCOMPLETE_CITIES, { zip })
  }

  getStreetsByZip (zip: string, street: string): Promise<StreetResponse> {
    return this.get(ServiceRouteTypes.API_AUTOCOMPLETE_STREETS, { zip, street_name: street })
  }

  getHouseNumberByZip (
    zip: string,
    street: string,
    number: string,
  ): Promise<HouseResponse> {
    return this.get(ServiceRouteTypes.API_AUTOCOMPLETE_HOUSES, { zip, street_name: street, house_number: number })
  }
}
