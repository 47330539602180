import { TrackingProvider } from '~/plugins/analytics/types'

export abstract class Tracker<P extends TrackingProvider> {
  protected constructor (
    protected provider: P,
    protected readonly debug: boolean = false,
  ) {}

  get options () {
    return {
      provider: this.provider,
      debug: this.debug,
    }
  }
}
