
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator'
import vClickOutside from 'v-click-outside'

const { bind, unbind } = vClickOutside.directive

@Component
export default class UiDropdown extends Vue {
  @Prop({ required: false, default: 'div', type: String })
  readonly tag!: string

  @Prop({ required: false, default: false, type: Boolean })
  readonly top!: boolean

  @Prop({ required: false, default: false, type: Boolean })
  readonly bottom!: boolean

  @Prop({ required: false, default: false, type: Boolean })
  readonly center!: boolean

  @Prop({ required: false, default: false, type: Boolean })
  readonly left!: boolean

  @Prop({ required: false, default: false, type: Boolean })
  readonly right!: boolean

  @Prop({ required: false, default: false, type: Boolean })
  readonly disabled!: boolean

  @Ref('dropdown')
  dropdownElement!: HTMLElement

  isOpen = false

  // Center is default side
  get horizontal () {
    return this.left
      ? 'left'
      : this.right
        ? 'right'
        : 'center'
  }

  get vertical () {
    return this.top
      ? 'top'
      : 'bottom'
  }

  onDropdownClick () {
    if (this.disabled) {
      return
    }

    this.isOpen = !this.isOpen
  }

  onClickOutside () {
    this.isOpen = false
  }

  onClickListElement () {
    this.isOpen = false
  }

  mounted () {
    bind(this.dropdownElement, { value: this.onClickOutside })
  }

  beforeDestroy () {
    unbind(this.dropdownElement)
  }
}
