import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class IconsMixin extends Vue {
  @Prop({ required: false, default: '#4574ED' })
  readonly color!: string

  @Prop({ required: false })
  readonly width!: string

  @Prop({ required: false })
  readonly height!: string

  get id (): number {
    return 1 + Math.floor((99999 - 1) * Math.random())
  }

  get clipPath (): string {
    return `url(#${this.id})`
  }
}
