var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShow)?_c('AppModal',{staticClass:"fortune-wheel-modal",attrs:{"overlay-blur":"","centered":"","body-lock":"","size":_vm.$device.isMobile ? 'xs' : 'lg',"overlay-transition-name":null},on:{"close":_vm.onModalClose}},[_c('div',{staticClass:"fortune-wheel-modal__body",class:[
      'fortune-wheel-modal__body--screen-' + _vm.screen,
    ]},[_c('AppTransition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"fortune-wheel-modal__content"},[(_vm.screen === 'wheel')?[_c('div',{key:"wheel",staticClass:"fortune-wheel-modal__screen"},[_c('h6',{staticClass:"fortune-wheel-modal__subtitle"},[_vm._v("\n              "+_vm._s(_vm.$t('promo.wheelOfFortune.title1'))+"\n            ")]),_vm._v(" "),_c('h5',{staticClass:"fortune-wheel-modal__title"},[_vm._v("\n              "+_vm._s(_vm.$t('promo.wheelOfFortune.title2'))+"\n            ")]),_vm._v(" "),(_vm.benefits.length)?_c('AppFortuneWheel',{staticClass:"fortune-wheel-modal__wheel",attrs:{"items":_vm.benefitLabels,"size":_vm.$mqUtils.isDesktop ? 410 : 220,"once":""},on:{"spin:start":_vm.onSpinStart,"spin:end":_vm.onSpinEnd}}):_vm._e()],1)]:[(_vm.winner)?_c('div',{key:"wheel",staticClass:"fortune-wheel-modal__screen"},[_c('h5',{staticClass:"fortune-wheel-modal__title",domProps:{"innerHTML":_vm._s(_vm.$t('promo.wheelOfFortune.title3'))}}),_vm._v(" "),_c('div',{staticClass:"fortune-wheel-result"},[_c('div',{staticClass:"fortune-wheel-result__text",domProps:{"innerHTML":_vm._s(_vm.$t('promo.wheelOfFortune.additionalBenefit', {
                  benefitType: _vm.$t(`promo.wheelOfFortune.benefitType.${_vm.winner.type}`)
                }))}}),_vm._v(" "),_c('div',{staticClass:"fortune-wheel-result__separator"}),_vm._v(" "),_c('div',{staticClass:"fortune-wheel-result__value"},[_vm._v("\n                CHF\n                "),_c('strong',[_vm._v(_vm._s(_vm._f("formatPrice")(_vm.winner.value)))])])]),_vm._v(" "),_c('h6',{staticClass:"fortune-wheel-modal__subtitle"},[_vm._v("\n              "+_vm._s(_vm.$t('promo.wheelOfFortune.weAlreadyApplied'))+"\n            ")]),_vm._v(" "),(_vm.winner)?_c('div',{staticClass:"fortune-wheel-modal__promo-code"},[_vm._v("\n              "+_vm._s(_vm.winner.code)+"\n            ")]):_vm._e(),_vm._v(" "),_c('AlaoButton',{attrs:{"full-width":"","nuxt":"","to":_vm.localePath('compare-mobile')},nativeOn:{"click":function($event){return _vm.onCTAClick.apply(null, arguments)}}},[_vm._v("\n              "+_vm._s(_vm.$t('promo.wheelOfFortune.cta'))+"\n            ")])],1):_vm._e()],_vm._v(" "),_c('p',{staticClass:"fortune-wheel-modal__agreement-text text--center",domProps:{"innerHTML":_vm._s(_vm.$t('promo.wheelOfFortune.termsAndConditions', { href: _vm.localePath({
            name: 'page-slug',
            params: { slug: 'terms-and-conditions' }
          }) }))}})],2)])],1),_vm._v(" "),_c('FullPageConfetti',{ref:"confetti"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }