import { ApplicationService } from '~/services/core/application.service'

// TODO:
//  - Create offer interfaces to use as return type
export class RatingService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  create (payload = {}) {
    return this.post('/api/ratings/', payload)
  }

  getReviewsList (params = {}) {
    return this.get('/api/ratings/', params)
  }
}
