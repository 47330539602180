import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { Partner } from '~/services/partner.service'
import { $api } from '~/plugins/axios'

export const namespace = 'modules/Partner'

export interface PartnerState {
  currentPartner: undefined | Partner,
}

export const ActionType = {
  FETCH_PARTNER: 'fetchPartner',
  FETCH_AMP_PARTNER: 'fetchAmpPartner',
}

export const MutationType = {
  SET_CURRENT_PARTNER: 'setCurrentPartner',
  CLEAR_CURRENT_PARTNER: 'clearCurrentPartner',
}

export const GettersType = {
  IS_PARTNER_SET: 'isPartnerSet',
  GET_PARTNER_BY_SLUG: 'getPartnerBySlug',
  GET_CURRENT_PARTNER: 'getCurrentPartner',
}

export const state = (): PartnerState => ({
  currentPartner: undefined,
})

export const getters: GetterTree<PartnerState, RootState> = {
  [GettersType.IS_PARTNER_SET] (state: PartnerState): boolean {
    return state.currentPartner !== undefined
  },
  [GettersType.GET_CURRENT_PARTNER] (state: PartnerState): undefined | Partner {
    return state.currentPartner
  },
}

export const actions: ActionTree<PartnerState, RootState> = {
  async [ActionType.FETCH_PARTNER] ({ commit }, { lang, slug }) {
    try {
      const partner: Partner = await $api.PartnerService.fetchPartner(slug)
      if (partner?.is_active) {
        commit(MutationType.SET_CURRENT_PARTNER, partner)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async [ActionType.FETCH_AMP_PARTNER] ({ commit }, { slug }) {
    try {
      const partner = await $api.PublicWhiteLabelService.getPartner(slug)
      commit(MutationType.SET_CURRENT_PARTNER, { ...partner, logo: partner.logo.original_url })
    } catch (e) {
      console.error(e)
    }
  },
}

export const mutations: MutationTree<PartnerState> = {
  [MutationType.SET_CURRENT_PARTNER] (state: PartnerState, partner: undefined | Partner): void {
    state.currentPartner = partner
  },
  [MutationType.CLEAR_CURRENT_PARTNER] (state: PartnerState): void {
    state.currentPartner = undefined
  },
}
