import Vue from 'vue'
import { addDays } from 'date-fns'
import { daysLeft } from '@alao-frontend/filters'
import { IPriceExplanationBenefit } from '@alao-frontend/core'
import {
  Bonus,
  BonusType,
  OfferBadgeRaw,
  OfferPriceExplanation,
  OfferPriceExplanationFormula,
  OfferTag,
  OfferTypeByShortName,
  OfferTypeKey,
} from '~/core/offer.types'

/* eslint-disable camelcase */

interface State {
  offer: Record<string, any>,
}

const ONE_YEAR_IN_MONTHS = 12
const TWO_YEARS_IN_MONTHS = ONE_YEAR_IN_MONTHS * 2

export class OfferCommonUtils {
  private state!: State

  constructor (offer: any) {
    this.state = Vue.observable<State>({
      offer,
    })
  }

  get isMobile () {
    return Boolean(
      this.state.offer.mobile_offer &&
        !this.state.offer.device_offer &&
        !this.state.offer.home_offer,
    )
  }

  get isHome () {
    return Boolean(
      !this.state.offer.mobile_offer && this.state.offer.home_offer,
    )
  }

  get isBundle () {
    return Boolean(this.state.offer.mobile_offer && this.state.offer.home_offer)
  }

  get isDevice () {
    return Boolean(
      this.state.offer.mobile_offer && this.state.offer.device_offer,
    )
  }

  get offerId (): string {
    if (this.isMobile) {
      return this.state.offer.mobile_offer.pk
    }
    if (this.isHome) {
      return this.state.offer.home_offer.pk
    }

    return this.state.offer.pk
  }

  get offerType (): OfferTypeByShortName {
    if (this.isMobile) {
      return OfferTypeByShortName.mobile
    }
    if (this.isHome) {
      return OfferTypeByShortName.home
    }
    if (this.isBundle) {
      return OfferTypeByShortName.bundle
    }

    return OfferTypeByShortName.smartphone
  }

  static getOfferId (offer: any, offerType: OfferTypeKey) {
    switch (offerType) {
      case OfferTypeByShortName.mobile:
        return offer.mobile_offer.pk
      case OfferTypeByShortName.home:
        return offer.home_offer.pk
      case OfferTypeByShortName.bundle:
      case OfferTypeByShortName.smartphone:
        return offer.pk
      default:
        return new TypeError(`Unhandled offerType: ${offerType}`)
    }
  }

  get mobileOfferProviderName (): string {
    if (this.isMobile || this.isBundle || this.isDevice) {
      return this.state.offer.mobile_offer.provider.name
    }

    return ''
  }

  get homeOfferProviderName (): string {
    if (this.isHome || this.isBundle) {
      return this.state.offer.home_offer.provider.name
    }

    return ''
  }

  get offerProviderName () {
    if (this.isBundle) {
      return `${this.mobileOfferProviderName} & ${this.homeOfferProviderName}`
    }

    return this.mobileOfferProviderName || this.homeOfferProviderName
  }

  get revenue () {
    if (this.isBundle) {
      return this.state.offer._uetvid
    }

    if (this.isMobile || this.isDevice) {
      return this.state.offer.mobile_offer._uetvid
    }

    if (this.isHome) {
      return this.state.offer.home_offer._uetvid
    }

    return ''
  }

  getOfferName (separator?: string) {
    separator = separator || ' & '

    if (this.isMobile || this.isDevice) {
      return this.state.offer.mobile_offer.name
    }

    if (this.isHome) {
      return this.state.offer.home_offer.name
    }

    if (this.isBundle) {
      return `${this.state.offer.mobile_offer.name}${separator}${this.state.offer.home_offer.name}`
    }

    return ''
  }

  get offerName () {
    return this.getOfferName()
  }

  get isSaltWithMobile () {
    return (
      (this.isMobile || this.isBundle || this.isDevice) &&
      this.mobileOfferProviderName.toLowerCase() === 'salt'
    )
  }

  get isSunriseMobile () {
    return (
      (this.isMobile || this.isBundle || this.isDevice) &&
      this.mobileOfferProviderName.toLowerCase() === 'sunrise'
    )
  }

  calculateActivationDiscountedPrice (
    price: number,
    discountType: string,
    discountValue: number,
  ) {
    if (discountType === 'PERCENT') {
      return parseFloat((price - (discountValue / 100) * price).toFixed(2))
    }

    return price - discountValue >= 0
      ? parseFloat((price - discountValue).toFixed(2))
      : price
  }

  /**
   * Regular activation price for mobile offer
   * @returns {number}
   */
  get activationCostMobile () {
    if (this.isHome) {
      return 0
    }

    // TODO: Figure out why I apply Math.round() here
    return (
      Math.round(
        parseFloat(this.state.offer.mobile_offer.activation_costs) * 100,
      ) / 100
    )
  }

  /**
   * Regular activation price for home offer
   * @returns {number}
   */
  get activationCostHome () {
    if (this.isMobile || this.isDevice) {
      return 0
    }

    // TODO: Figure out why I apply Math.round() here
    return (
      Math.round(
        parseFloat(this.state.offer.home_offer.activation_costs) * 100,
      ) / 100
    )
  }

  /**
   * Sum of activation prices for all offer types
   * @returns {number}
   */
  get activationCostTotal () {
    return parseFloat(
      (this.activationCostMobile + this.activationCostHome).toFixed(2),
    )
  }

  /**
   * Discounted activation price for mobile offer
   * @param {boolean} isEPUsed
   * @returns {number|*}
   */
  getDiscountedActivationCostMobile (isEPUsed = false) {
    if (
      (this.isHardCodedFreeActivationMobile &&
        (this.isMobile || this.isBundle)) ||
      (this.isHardCodedFreeActivationDevice && this.isDevice)
    ) {
      return 0
    }

    const discount = this.state.offer.mobile_offer?.discount

    if (
      (this.isDevice && this.offerProviderName.toLowerCase() === 'sunrise') ||
      !this.activationCostMobile ||
      !discount ||
      (discount &&
        discount.activation_benefit_only_for_exit_prevention &&
        !isEPUsed)
    ) {
      return this.activationCostMobile
    }

    const {
      activation_benefit_type: discountType,
      activation_benefit_value: discountValue,
    } = discount

    if (discountType && discountValue) {
      return this.calculateActivationDiscountedPrice(
        this.activationCostMobile,
        discountType,
        discountValue,
      )
    }

    return this.activationCostMobile
  }

  /**
   * Discounted activation price for home offer
   * @param {boolean} isEPUsed
   * @returns {number|*}
   */
  getDiscountedActivationCostHome (isEPUsed = false) {
    if (this.isHardCodedFreeActivationHome && (this.isHome || this.isBundle)) {
      return 0
    }

    const discount = this.state.offer.home_offer?.discount

    if (
      !this.activationCostHome ||
      !discount ||
      (discount &&
        discount.activation_benefit_only_for_exit_prevention &&
        !isEPUsed)
    ) {
      return this.activationCostHome
    }

    const {
      activation_benefit_type: discountType,
      activation_benefit_value: discountValue,
    } = discount

    if (discountType && discountValue) {
      return this.calculateActivationDiscountedPrice(
        this.activationCostHome,
        discountType,
        discountValue,
      )
    }

    return this.activationCostHome
  }

  /**
   * Sum of discounted activation prices for all offer types
   * @param {boolean} isEPUsed
   * @returns {number|*}
   */
  getActivationDiscountedCostTotal (isEPUsed = false) {
    const discountedMobile = this.getDiscountedActivationCostMobile(isEPUsed)
    const discountedHome = this.getDiscountedActivationCostHome(isEPUsed)

    return parseFloat((discountedMobile + discountedHome).toFixed(2))
  }

  get priceDiscountPercents (): number {
    if (this.isHome) {
      return Number(this.state.offer.home_offer.effective_percent)
    }

    if (this.isBundle || this.isDevice) {
      return Number(this.state.offer.effective_percent)
    }

    return Number(this.state.offer.mobile_offer.effective_percent)
  }

  /**
   * - Swisscom (home/mobile/device)
   * - Sunrise (home/device)
   * - UPC (home)
   * @function
   */
  get _hardCodedFreeActivationProvidersList () {
    return {
      mobile: [] as string[],
      home: ['sunrise'],
      device: ['sunrise'],
    }
  }

  get isHardCodedFreeActivationMobile () {
    const providersList = this._hardCodedFreeActivationProvidersList.mobile

    return providersList.includes(this.mobileOfferProviderName.toLowerCase())
  }

  get isHardCodedFreeActivationHome () {
    const providersList = this._hardCodedFreeActivationProvidersList.home

    return providersList.includes(this.homeOfferProviderName.toLowerCase())
  }

  get isHardCodedFreeActivationDevice () {
    const providersList = this._hardCodedFreeActivationProvidersList.device

    return providersList.includes(this.mobileOfferProviderName.toLowerCase())
  }

  get hardCodedFreeActivation () {
    return (
      (this.isMobile && this.isHardCodedFreeActivationMobile) ||
      (this.isHome && this.isHardCodedFreeActivationHome) ||
      (this.isDevice && this.isHardCodedFreeActivationDevice)
    )
  }

  get isDevicePreOrder (): boolean {
    if (!this.isDevice) {
      return false
    }

    return this.state.offer.device_offer.stock_status === 'PRE_ORDER'
  }

  get devicePreOrderDate () {
    if (!this.isDevicePreOrder) {
      return
    }

    return this.state.offer.device_offer.expected_date_in_stock
  }

  get discountDaysLeft () {
    const divideBy = 5

    const mobileDaysLeft = this.state.offer.mobile_offer
      ? (this.state.offer.mobile_offer.discount || {}).discount_days_left ||
        null
      : null
    const homeDaysLeft = this.state.offer.home_offer
      ? (this.state.offer.home_offer.discount || {}).discount_days_left || null
      : null

    // Return original number of days left for mobile offer with name `europe 1gb`
    if (this.isMobile && this.state.offer.mobile_offer.name === 'europe 1gb') {
      return daysLeft(mobileDaysLeft)
    }

    const daysLeftArr = []

    if (
      (this.isMobile || this.isDevice || this.isBundle) &&
      mobileDaysLeft !== null
    ) {
      daysLeftArr.push(parseFloat(mobileDaysLeft))
    }

    if ((this.isHome || this.isBundle) && homeDaysLeft !== null) {
      daysLeftArr.push(parseFloat(homeDaysLeft))
    }

    if (this.isBundle && this.state.offer.bundle_discount_days_left !== null) {
      daysLeftArr.push(parseFloat(this.state.offer.bundle_discount_days_left))
    }

    if (daysLeftArr.length === 0) {
      return null
    }

    // Return a smaller number
    const daysLeftMin = daysLeft(Math.min.apply(null, daysLeftArr))

    return daysLeftMin % divideBy
  }

  /**
   * Returns if an offer has lifetime discount
   */
  get isDiscountLifetime (): boolean {
    if (this.isMobile) {
      return (
        this.state.offer.mobile_offer.discount &&
        this.state.offer.mobile_offer.discount.discount_lifetime
      )
    }

    if (this.isHome) {
      return (
        this.state.offer.home_offer.discount &&
        this.state.offer.home_offer.discount.discount_lifetime
      )
    }

    if (this.isBundle) {
      return (
        this.state.offer.mobile_offer.discount &&
        this.state.offer.mobile_offer.discount.discount_lifetime &&
        this.state.offer.home_offer.discount &&
        this.state.offer.home_offer.discount.discount_lifetime
      )
    }

    if (this.isDevice) {
      return (
        this.state.offer.mobile_offer.discount &&
        this.state.offer.mobile_offer.discount.discount_lifetime
      )
    }

    return false
  }

  /**
   * Is an offer has EP only activation discount
   * @returns {boolean}
   */
  get isHasEPActivationDiscount () {
    const keys = []
    if (this.isMobile || this.isDevice || this.isBundle) {
      keys.push('mobile_offer')
    }
    if (this.isHome || this.isBundle) {
      keys.push('home_offer')
    }

    return keys.some(
      key =>
        this.state.offer[key].discount &&
        this.state.offer[key].discount
          .activation_benefit_only_for_exit_prevention,
    )
  }

  /**
   * Is an offer has EP only voucher or cashback
   * @returns {boolean}
   */
  get isHasEPBonus (): boolean {
    return this.EPCashbacks.length > 0 || this.EPVouchers.length > 0
  }

  get EPDiscountedActivationCostTotal () {
    return this.getActivationDiscountedCostTotal(true)
  }

  _deviceDownPaymentObj (prop: any) {
    if (!this.isDevice) {
      return 0
    }

    return this.state.offer.payments[0][prop]
  }

  get regularCashbacks () {
    const bonusesList: any[] = this.getBonusesByItsType('cashbacks')
    return this.filterBonuses(bonusesList, false)
  }

  get regularVouchers () {
    const bonusesList: any[] = this.getBonusesByItsType('vouchers')
    return this.filterBonuses(bonusesList, false)
  }

  get customBenefits () {
    const bonusesList: any[] = this.getBonusesByItsType('custom_benefits')
    return this.filterBonuses(bonusesList, false)
  }

  get EPCashbacks () {
    const bonusesList: any[] = this.getBonusesByItsType('cashbacks')
    return this.filterBonuses(bonusesList, true)
  }

  get EPVouchers () {
    const bonusesList: any[] = this.getBonusesByItsType('vouchers')
    return this.filterBonuses(bonusesList, true)
  }

  get welcomeBonuses () {
    return this.getBonusesByItsType('welcome_bonuses')
  }

  getBonusesByItsType (
    type: 'cashbacks' | 'vouchers' | 'welcome_bonuses' | 'custom_benefits',
  ) {
    if (this.isMobile) {
      return this.state.offer.mobile_offer[type] || []
    }

    if (this.isHome) {
      return this.state.offer.home_offer[type] || []
    }

    if (this.isBundle) {
      return [
        ...(this.state.offer[type] || []),
        ...(this.state.offer.mobile_offer[type] || []),
        ...(this.state.offer.home_offer[type] || []),
      ]
    }

    if (this.isDevice) {
      return [
        ...(this.state.offer[type] || []),
        ...(this.state.offer.mobile_offer[type] || []),
      ]
    }
  }

  filterBonuses (bonusesList: any[], isForEP: boolean) {
    return bonusesList.filter((bonus: any) => {
      return (
        bonus.only_for_exit_prevention === isForEP &&
        bonus.provided_only_by_promocode === false
      )
    })
  }

  get priceExplanationFormulaDuration () {
    return this.priceDiscountChangesDescription
      ? ONE_YEAR_IN_MONTHS
      : this.contractDuration || ONE_YEAR_IN_MONTHS
  }

  get priceExplanationCashback () {
    if (!this.regularCashbacks.length) {
      return false
    }

    const alaoCashbacks = this.regularCashbacks.filter(
      (bonus: any) => bonus.partner_name.toLowerCase() === 'alao',
    )

    if (!alaoCashbacks.length) {
      return false
    }

    return alaoCashbacks.reduce(
      (acc: number, current: Record<string, any>) =>
        acc + parseFloat(current.profit_value),
      0,
    )
  }

  get priceExplanationWelcome () {
    if (!this.welcomeBonuses.length) {
      return false
    }

    return this.welcomeBonuses.reduce(
      (acc: number, current: Record<string, any>) =>
        acc + parseFloat(current.benefit_value),
      0,
    )
  }

  get priceExplanationVoucher () {
    if (!this.regularVouchers.length) {
      return false
    }

    const alaoVouchers = this.regularVouchers.filter(
      (bonus: any) => bonus.partner_name.toLowerCase() === 'alao',
    )

    if (!alaoVouchers.length) {
      return false
    }

    return alaoVouchers.reduce(
      (acc: number, current: Record<string, any>) =>
        acc + parseFloat(current.profit_value),
      0,
    )
  }

  get isDownPaymentRequired () {
    return this.isDevice && this.state.offer.downpayment_required
  }

  get isDownPaymentPaidByAlao () {
    return this.isDevice && this.state.offer.down_payment_will_be_paid_by_alao
  }

  get regularDevicePrice () {
    if (!this.isDevice) {
      return 0 // should I just throw an exception?
    }

    return Number(this.state.offer.device_offer.full_device_price)
  }

  get finalDevicePrice () {
    if (this.specialDevicePriceFromMobileOperator) {
      return this.specialDevicePriceFromMobileOperator
    }

    return this.regularDevicePrice
  }

  /**
   * Price per contract duration
   */
  get specialDevicePriceFromMobileOperator (): number {
    if (!this.isDevice) {
      return 0 // should I just throw an exception?
    }

    return Number(this.state.offer.special_device_price_from_mobile_operator)
  }

  get deviceOneTimePayment () {
    return this._deviceDownPaymentObj('down_payment')
  }

  get deviceRegularMonthlyPrice () {
    return this._deviceDownPaymentObj('monthly_payment')
  }

  get deviceFinalMonthlyPayment () {
    if (this.specialDevicePriceFromMobileOperator) {
      const contractDuration = this.contractDuration || ONE_YEAR_IN_MONTHS
      return Number(
        (
          (this.specialDevicePriceFromMobileOperator -
            this.deviceOneTimePayment) /
          contractDuration
        ).toFixed(2),
      )
    }

    return this.deviceRegularMonthlyPrice
  }

  get regularPrice () {
    if (this.isMobile) {
      return (
        Math.round(parseFloat(this.state.offer.mobile_offer.price) * 100) / 100
      )
    }
    if (this.isHome) {
      return (
        Math.round(parseFloat(this.state.offer.home_offer.price) * 100) / 100
      )
    }
    if (this.isBundle) {
      return (
        Math.round(
          (parseFloat(this.state.offer.mobile_offer.price) +
            parseFloat(this.state.offer.home_offer.price)) *
            100,
        ) / 100
      )
    }
    if (this.isDevice) {
      return parseFloat(
        (
          Math.round(parseFloat(this.state.offer.mobile_offer.price) * 100) /
            100 +
          Math.round(this.deviceRegularMonthlyPrice * 100) / 100
        ).toFixed(2),
      )
    }

    return 0
  }

  get finalPrice () {
    let price = 0
    if (this.isMobile) {
      price =
        Math.round(
          parseFloat(this.state.offer.mobile_offer.final_price) * 100,
        ) / 100
    }
    if (this.isHome) {
      price =
        Math.round(parseFloat(this.state.offer.home_offer.final_price) * 100) /
        100
    }
    if (this.isBundle) {
      price =
        Math.round(parseFloat(this.state.offer.bundle_final_price) * 100) / 100
    }
    if (this.isDevice) {
      price =
        Math.round(
          parseFloat(this.state.offer.mobile_offer.final_price) * 100,
        ) /
          100 +
        Math.round(this.deviceFinalMonthlyPayment * 100) / 100

      if (
        this.state.offer.discount &&
        this.state.offer.discount.discount_benefit_value
      ) {
        price = price - this.state.offer.discount.discount_benefit_value
      }

      price = parseFloat(price.toFixed(2))
    }

    return price
  }

  get deviceSpecialDiscountDelta (): number {
    if (!this.isDevice) {
      return 0
    }

    if (this.specialDevicePriceFromMobileOperator) {
      const fullPrice = Number(this.state.offer.device_offer.full_device_price)

      return Number(
        (fullPrice - this.specialDevicePriceFromMobileOperator).toFixed(2),
      )
    }

    return 0
  }

  get savings (): number {
    if (this.isHome) {
      return Number(this.state.offer.home_offer.savings)
    }

    if (this.isBundle || this.isDevice) {
      return Number(this.state.offer.savings)
    }

    return Number(this.state.offer.mobile_offer.savings)
  }

  get priceDiscountChangesDescription (): string {
    if (this.isMobile) {
      return (
        this.state.offer.mobile_offer.discount?.price_changes_description || ''
      )
    }

    if (this.isHome) {
      return (
        this.state.offer.home_offer.discount?.price_changes_description || ''
      )
    }

    if (this.isBundle || this.isDevice) {
      return this.state.offer.discount?.price_changes_description || ''
    }

    return ''
  }

  get subscriptionRegularPrice () {
    if (!this.isDevice) {
      return this.regularPrice
    }

    return Number(Number(this.state.offer.mobile_offer.price).toFixed(2))
  }

  get subscriptionFinalPrice () {
    if (!this.isDevice) {
      return this.finalPrice
    }

    return (
      Math.round((this.finalPrice - this.deviceFinalMonthlyPayment) * 100) / 100
    )
  }

  get isHasTotalBenefit () {
    return this.isHasMonthlyDiscount
  }

  get totalBenefit () {
    const duration = this.maxContractDuration || TWO_YEARS_IN_MONTHS

    return parseFloat(
      ((this.regularPrice - this.finalPrice) * duration).toFixed(2),
    )
  }

  get isHasMonthlyDiscount () {
    return this.regularPrice !== this.finalPrice
  }

  get isContractHasDuration () {
    const keys = []
    if (this.isMobile || this.isDevice || this.isBundle) {
      keys.push('mobile_offer')
    }
    if (this.isHome || this.isBundle) {
      keys.push('home_offer')
    }

    return keys.some(key => this.state.offer[key].contract_duration !== 0)
  }

  get maxContractDuration () {
    return Math.max.apply(null, [
      this.contractDurationMobile || 0,
      this.contractDurationHome || 0,
    ])
  }

  get contractDurationMobile () {
    if (this.isHome) {
      return null
    }

    return this.state.offer.mobile_offer.contract_duration
  }

  get contractDurationHome () {
    if (this.isMobile || this.isDevice) {
      return null
    }

    return this.state.offer.home_offer.contract_duration
  }

  get contractDuration () {
    return this.contractDurationMobile ?? this.contractDurationHome
  }

  get isExclusive () {
    const keys = []
    if (this.isMobile || this.isDevice || this.isBundle) {
      keys.push('mobile_offer')
    }

    if (this.isHome || this.isBundle) {
      keys.push('home_offer')
    }

    return keys.some(
      key =>
        this.state.offer[key].discount &&
        this.state.offer[key].discount.exclusive_at_alao,
    )
  }

  get pricesDifference () {
    return Math.round((this.regularPrice - this.finalPrice) * 100) / 100
  }

  get isForExistingOnly () {
    if (this.isMobile) {
      return (
        this.state.offer.mobile_offer.discount
          ?.discount_for_existing_customers || false
      )
    }

    if (this.isHome) {
      return (
        this.state.offer.home_offer.discount?.discount_for_existing_customers ||
        false
      )
    }

    return false
  }

  // TODO: Make it dynamic (obtain from API)
  get isSaltFiberDiscount () {
    if (this.isHome) {
      return this.state.offer.home_offer.pk === 1671
    }

    return false
  }

  get youSaveDuration () {
    const defaultDuration = TWO_YEARS_IN_MONTHS

    if (this.isMobile || this.isDevice) {
      return this.contractDurationMobile || defaultDuration
    }
    if (this.isHome) {
      return this.contractDurationHome || defaultDuration
    }
    if (this.isBundle) {
      const durations = [this.contractDurationMobile, this.contractDurationHome]

      return Math.max.apply(null, durations) || defaultDuration
    }

    return defaultDuration
  }

  /**
   *
   * @param {string} bonusType
   * @returns {null|object}
   */
  getWelcomeBonusByType (bonusType: string) {
    if (this.isMobile) {
      return this.state.offer.mobile_offer[bonusType] || null
    }

    if (this.isHome) {
      return this.state.offer.home_offer[bonusType] || null
    }

    /*
     * Device bonuses have more priority than mobile ones.
     * */
    if (this.isDevice) {
      return this.state.offer[bonusType]
        ? this.state.offer[bonusType]
        : this.state.offer.mobile_offer[bonusType]
          ? this.state.offer.mobile_offer[bonusType]
          : null
    }

    /*
     * Bundle bonuses have more priority than the others.
     * Home bonuses have more priority than Mobile ones
     * */
    if (this.isBundle) {
      return this.state.offer[bonusType]
        ? this.state.offer[bonusType]
        : this.state.offer.home_offer[bonusType]
          ? this.state.offer.home_offer[bonusType]
          : this.state.offer.mobile_offer[bonusType]
            ? this.state.offer.mobile_offer[bonusType]
            : null
    }

    return null
  }

  /**
   *
   * @param {null|object} bonus
   * @param {string} lang
   * @returns {null|{logo: (string|null), message: (string)}}
   * @private
   */
  _pipeWelcomeBonus (bonus: any, lang: string) {
    if (!bonus) {
      return null
    }

    const messageKey = `applied_text_${lang}`

    return {
      message: bonus[messageKey] || '',
      logo: bonus.partner_logo || null,
    }
  }

  /**
   *
   * @param {string} lang
   * @returns {null|{logo: (string|null), message: (string)}}
   */
  getWelcomeCashback (lang = 'en') {
    const bonus = this.getWelcomeBonusByType('cashback')

    return this._pipeWelcomeBonus(bonus, lang)
  }

  /**
   *
   * @param {string} lang
   * @returns {null|{logo: (string|null), message: (string)}}
   */
  getWelcomeVoucher (lang = 'en') {
    const bonus = this.getWelcomeBonusByType('voucher')

    return this._pipeWelcomeBonus(bonus, lang)
  }

  /**
   *
   * @param {string} lang
   * @returns {null|{logo: (string|null), message: (string)}}
   */
  getEPWelcomeCashback (lang = 'en') {
    const bonus = this.getWelcomeBonusByType('exit_prevention_cashback')

    return this._pipeWelcomeBonus(bonus, lang)
  }

  /**
   *
   * @param {string} lang
   * @returns {null|{logo: (string|null), message: (string)}}
   */
  getEPWelcomeVoucher (lang = 'en') {
    const bonus = this.getWelcomeBonusByType('exit_prevention_voucher')

    return this._pipeWelcomeBonus(bonus, lang)
  }

  /**
   * If provider requires customer's signature we must show a step with signature box.
   * Mostly signature is required for mobile offers or bundle offers that includes mobile offer inside.
   * But recently we've started to ask signature for Sunrise home offers as well as customer's documents.
   * @returns {boolean}
   */
  get isSignatureRequired () {
    if (this.isMobile || this.isDevice || this.isBundle) {
      return this.state.offer.mobile_offer.provider.customer_signature_required
    }

    if (
      (this.isHome || this.isBundle) &&
      ['sunrise', 'yallo'].includes(this.state.offer.home_offer.provider.name.toLowerCase())
    ) {
      return this.state.offer.home_offer.provider.customer_signature_required
    }

    return false
  }

  get isAllowContractRendering () {
    if (this.isMobile || this.isDevice || this.isBundle) {
      return this.state.offer.mobile_offer.provider.allow_pdf_contract_rendering
    }

    return false
  }

  sumUserPaysDuringContractOffer (isEPUsed: boolean) {
    const results = {
      mobile_offer: 0,
      home_offer: 0,
    }

    if (this.isMobile || this.isBundle || this.isDevice) {
      const contractLength =
        this.state.offer.mobile_offer.contract_duration || ONE_YEAR_IN_MONTHS
      const activationCost = this.getDiscountedActivationCostMobile(isEPUsed)
      const finalPrice = this.state.offer.mobile_offer.final_price

      results.mobile_offer =
        Math.floor((contractLength * finalPrice + activationCost) * 100) / 100
    }

    if (this.isHome || this.isBundle) {
      const contractLength =
        this.state.offer.home_offer.contract_duration || ONE_YEAR_IN_MONTHS
      const activationCost = this.getDiscountedActivationCostHome(isEPUsed)
      const finalPrice = this.state.offer.home_offer.final_price

      results.home_offer =
        Math.floor((contractLength * finalPrice + activationCost) * 100) / 100
    }

    return results
  }

  get priceExplanationAll (): number {
    return Number(
      (
        this.priceExplanationCashback +
        this.priceExplanationVoucher +
        this.priceExplanationWelcome
      ).toFixed(2),
    )
  }

  get calculatedPriceExplanationAll (): number {
    let price = this.finalPrice

    price = Number(
      (
        price -
        this.priceExplanationAll / this.priceExplanationFormulaDuration
      ).toFixed(2),
    )

    return price
  }

  get bonuses (): Bonus[] {
    const bonusesList: Bonus[] = []

    if (this.priceExplanationVoucher) {
      bonusesList.push({
        type: BonusType.VOUCHER,
        translateKey: 'offerCard.priceExplanation.voucher',
        value: Number(this.priceExplanationVoucher),
      })
    }
    if (this.priceExplanationCashback) {
      bonusesList.push({
        type: BonusType.CASHBACK,
        translateKey: 'offerCard.priceExplanation.cashback',
        value: Number(this.priceExplanationCashback),
      })
    }
    if (this.priceExplanationWelcome) {
      bonusesList.push({
        type: BonusType.WELCOME,
        translateKey: 'offerCard.priceExplanation.bonus',
        value: Number(this.priceExplanationWelcome),
      })
    }

    return bonusesList
  }

  get badges (): OfferBadgeRaw[] | undefined {
    if (this.isHome) {
      // eslint-disable-next-line camelcase
      return this.state.offer?.home_offer?.badges ?? []
    }
    if (this.isMobile) {
      // eslint-disable-next-line camelcase
      return this.state.offer?.mobile_offer?.badges ?? []
    }
    if (this.isBundle) {
      return this.state.offer?.badges ?? []
    }
    if (this.isDevice) {
      return this.state.offer?.badges ?? []
    }
  }

  get expertTip () {
    let tip
    if (this.isMobile) {
      tip = this.state.offer.mobile_offer.tip
    }
    if (this.isHome) {
      tip = this.state.offer.home_offer.tip
    }
    if (this.isBundle || this.isDevice) {
      tip = this.state.offer.tip
    }

    if (!tip) {
      return
    }

    const { text: title, background_color: bgColor, text_color: textColor, prepend_icon: prependIcon } = tip

    return {
      title,
      bgColor,
      textColor,
      prependIcon,
    }
  }

  static setupShareExpiration (): boolean {
    const shareExpiration = window.localStorage.getItem('offerShareExpDatetime')
    if (!shareExpiration || Date.parse(shareExpiration) < Date.now()) {
      window.localStorage.setItem(
        'offerShareExpDatetime',
        addDays(new Date(), 7).toString(),
      )
      return true
    }

    return false
  }

  get effectiveMontlyPrice (): number {
    if (this.isMobile) {
      return Number(this.state.offer.mobile_offer.effective_monthly_price)
    } else if (this.isHome) {
      return Number(this.state.offer.home_offer.effective_monthly_price)
    }

    return Number(this.state.offer.effective_monthly_price)
  }

  get priceExplanation (): OfferPriceExplanation {
    return { ...this.priceExplanationFomula, benefits: this.benefits }
  }

  get priceExplanationFomula (): OfferPriceExplanationFormula {
    return {
      price: this.regularPrice,
      effectivePrice: this.effectiveMontlyPrice,
      contractDuration: this.relativeContractDuration,
      savings: this.savings,
    }
  }

  get benefits (): IPriceExplanationBenefit[] {
    if (this.isMobile) {
      return this.state.offer.mobile_offer.benefits
    } else if (this.isHome) {
      return this.state.offer.home_offer.benefits
    }

    return this.state.offer.benefits
  }

  get relativeContractDuration (): number {
    if (this.isMobile) {
      return Number(this.state.offer.mobile_offer.relative_contract_duration)
    } else if (this.isHome) {
      return Number(this.state.offer.home_offer.relative_contract_duration)
    }

    return Number(this.state.offer.relative_contract_duration)
  }

  get tag (): OfferTag | null {
    if (this.isMobile || this.isDevice || this.isBundle) {
      return this.state.offer.mobile_offer.tag ?? null
    }
    if (this.isHome) {
      return this.state.offer.home_offer.tag ?? null
    }
    return null
  }

  getOfferTitleByKey (key: string): string | undefined {
    if (this.isBundle) {
      return
    }
    if (this.isMobile) {
      return (
        this.state.offer.mobile_offer[key] ||
        this.state.offer.mobile_offer.title_de ||
        ''
      )
    }
    return (
      this.state.offer.home_offer[key] ||
      this.state.offer.home_offer.title_de ||
      ''
    )
  }

  get showPriceExplanation (): boolean {
    return Boolean(this.benefits && this.benefits.length)
  }
}
