import type { ActionTree } from 'vuex'
import { Context } from '@nuxt/types'
import { FeatureFlags } from '~/core/config/feature-flags'

import { ActionType as offerActionType, namespace as offerModuleNamespace } from '~/store/modules/Offer'
import { ActionType as commonActionType, namespace as commonModuleNamespace } from '~/store/modules/Common'
import { ActionType as GRActionType, namespace as GRModuleNamespace } from '~/store/modules/GRWidget'
import { ActionType as baseInfoActionType, namespace as baseInfoModuleNamespace } from '~/store/modules/BaseInfo'
import {
  ActionType as promoCampaignActionType,
  namespace as promoCampaignModuleNamespace,
} from '~/store/modules/PromoCampaign'

import {
  ActionType as mobileFiltersActionType,
  namespace as mobileFiltersModuleNamespace,
} from '~/store/modules/Filters/Mobile'

import {
  ActionType as homeFiltersActionType,
  namespace as homeFiltersModuleNamespace,
} from '~/store/modules/Filters/Home'

import {
  ActionType as AmpMobileFiltersActionType,
  namespace as AmpMobileFiltersModuleNamespace,
} from '~/store/modules/Amp/Filters/Mobile'

import {
  ActionType as AmpHomeFiltersActionType,
  namespace as AmpHomeFiltersModuleNamespace,
} from '~/store/modules/Amp/Filters/Home'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit ({ dispatch }, context: Context) {
    await Promise.all([
      dispatch(`${promoCampaignModuleNamespace}/${promoCampaignActionType.FETCH_CAMPAIGNS}`),
      dispatch(`${offerModuleNamespace}/${offerActionType.FETCH_PROVIDERS_LIST}`),
      dispatch(`${commonModuleNamespace}/${commonActionType.FETCH_SALES_STATISTICS}`),
      dispatch(`${GRModuleNamespace}/${GRActionType.FETCH_RATING}`),
      context.$featureFlags.isEnabled(FeatureFlags.AMP_BASE_INFO) && dispatch(`${baseInfoModuleNamespace}/${baseInfoActionType.FETCH_BASE_INFO}`),
    ]).catch((e) => {
      console.info('nuxtServerInit catch: ', e)
    })

    if (context.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)) {
      dispatch(`${AmpMobileFiltersModuleNamespace}/${AmpMobileFiltersActionType.INIT}`)
      dispatch(`${AmpHomeFiltersModuleNamespace}/${AmpHomeFiltersActionType.INIT}`)
    } else {
      dispatch(`${mobileFiltersModuleNamespace}/${mobileFiltersActionType.INIT}`)
      dispatch(`${homeFiltersModuleNamespace}/${homeFiltersActionType.INIT}`)
    }
  },
}
