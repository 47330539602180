import type { Component } from 'vue'

export type FiltersItemValue = string[] | string | boolean

export type FiltersItem = {
  name: string,
  component: string,
  props: {
    items?: {
      name: string,
      value: string | boolean,
      group?: string[],
      extra?: {
        logo?: string,
        slotBefore?: Component,
        slotAfter?: Component,
      },
    }[],
    defaultValue?: FiltersItemValue,
    deselectAll?: boolean,
    expandableLimit?: number,
    isExpandable?: boolean,
    title?: string,
    subTitle?: string,
  },
}

export type FiltersList = FiltersItem[]

export enum Exclusivity {
  REGULAR = 'regular_offers',
  EXCLUSIVE = 'alao_exclusive',
}

export type CommonFiltersState = {
  offer_types: Exclusivity[],
}

export interface MobileFiltersState {
  providers: string[],
  data_options: string[],
  call_options: string[],
  special_offers: string[],
  five_g_only: boolean,
}

export interface HomeFiltersState {
  providers: string[],
  household_size: string,
  data_speed: string[],
  home_call_options: string[],
  special_offers: string[],
  tv_options: string[],
}

export type AmpCommonFiltersState = {
  offer_types: Exclusivity[],
  special_offers: string[],
}

export interface AmpMobileFiltersState {
  providers: string[],
  data_options: string[],
  call_options: string[],

  five_g_only: boolean,
}

export interface AmpHomeFiltersState {
  providers: string[],
  household_size: string,
  data_speed: string[],
  home_call_options: string[],
  tv_options: string[],
}

export type FilterChangeEventPayload = {
  key: FiltersItem['name'],
  value: FiltersItemValue,
}
