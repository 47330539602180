
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

@Component
export default class AppHubspotChat extends Vue {
  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly openOnLoad!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly wide!: boolean

  private isChatVisible = false
  private isChatReady = false

  onBtnToggleClick () {
    this.isChatVisible = !this.isChatVisible

    this.checkBodyScroll()
  }

  onReady () {
    this.isChatReady = true
    this.isChatVisible = this.openOnLoad
  }

  whenHubSpotAPIReady () {
    return new Promise<void>((resolve, reject) => {
      if (!this.$hubSpot) {
        reject(new Error('hub spot disabled'))
        return
      }

      this.$hubSpot.hsConversationsOnReady.push(() => {
        resolve()
      })
    })
  }

  async created () {
    if (!process.client) {
      return
    }

    if (!this.$hubSpot) {
      return
    }

    if (!this.$hubSpot.isAPIReady) {
      await this.whenHubSpotAPIReady()
    }

    if (!this.$hubSpot.HubSpotConversations) {
      throw new Error('Something went wrong, the HubSpot API should be ready')
    }

    this.$hubSpot.HubSpotConversations.widget.load()

    this.onReady()
  }

  beforeDestroy () {
    this.$hubSpot?.HubSpotConversations?.widget.remove()
  }

  @Watch('$mq')
  checkBodyScroll () {
    if (this.isChatVisible && this.$mqUtils.isMobile) {
      disableBodyScroll(this.$el)
    } else {
      enableBodyScroll(this.$el)
    }
  }
}
