import type { GoogleOptimizeExtended } from '@alao-frontend/google-optimize-extended.module'
import { APIException } from '~/plugins/axios/lib/exception'
import { CreditCheckPayload, OfferTypeKey, Under18EmailRequestPayload } from '~/core/offer.types'
import { ApplicationService } from '~/services/core/application.service'
import { OrderAPIException } from '~/services/exceptions/orderAPI.exception'

// TODO: Move to types later
type OrderByShortUUIDResponse = {
  order_id: string,
  offer_id: number,
  offer_type: OfferTypeKey,
}

type SalesStatisticsResponse = {
  total_savings: number,
  completed_orders: number,
}

type OrderStepResponse = {
  order_id: string,
  step: string,
  back_document: string,
  front_document:string,
}

// TODO: Update type when the backend will be ready
export type ValidDiscountPayload = Record<string, any>[]

// TODO:
//  - Create offer interfaces to use as return type
export class OrderService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  async createOrder (
    payload: any,
    experiment?: GoogleOptimizeExtended,
  ) {
    try {
      return await this.post(
        '/api/sales/',
        Object.assign({}, payload, experiment && experiment.activeExperiment),
      )
    } catch (error) {
      throw new OrderAPIException('Unable to create a sale', (error as APIException))
    }
  }

  async createSaleDraft (payload: any) {
    try {
      return await this.post('/api/drafts/', payload)
    } catch (error) {
      throw new OrderAPIException('Unable to create a sale draft', (error as APIException))
    }
  }

  async updateSaleDraftById (id: string | number, payload: any) {
    try {
      return await this.patch(`/api/drafts/${id.toString()}/`, payload)
    } catch (error) {
      throw new OrderAPIException('Unable to update a sale draft', (error as APIException))
    }
  }

  async getSaleDraftById (id: string | number) {
    try {
      return await this.get(`/api/drafts/${id.toString()}/`)
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a sale draft by ID', (error as APIException))
    }
  }

  async getOrderByOrderId (orderId: string) {
    try {
      return await this.get('/api/sales/get_sale_by_id/', { order_id: orderId })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a sale by ID', (error as APIException))
    }
  }

  async getOfferByOrderId (orderId: string): Promise<any> {
    try {
      return await this.get('/api/sales/get_offer_data/', { order_id: orderId })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain an offer by sale ID', (error as APIException))
    }
  }

  async getOrderShortUrlByOrderId (orderId: string) {
    try {
      return await this.get('/api/sales/get_short_url/', { order_id: orderId })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a sale short URL by ID', (error as APIException))
    }
  }

  async getOrderByShortUUID (orderUUID: string): Promise<OrderByShortUUIDResponse> {
    try {
      return await this.get('/api/sales/get_offer_data_by_short_uuid/', { order_id: orderUUID })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a sale by short UUID', (error as APIException))
    }
  }

  async getOrderStepByOrderId (orderId: string): Promise<OrderStepResponse> {
    try {
      return await this.get('/api/order_step/', { order_id: orderId })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a sale step by ID', (error as APIException))
    }
  }

  async applyCouponToOrder (
    offerId: string,
    offerType: string,
    couponCode: string,
  ) {
    try {
      return await this.post<ValidDiscountPayload>(
        '/api/special_offers/verify_discount_code/',
        {
          offer_obj_pk: offerId,
          offer_type: offerType,
          promocode: couponCode,
        },
      )
    } catch (error) {
      throw new OrderAPIException('Unable to apply a coupon code', (error as APIException))
    }
  }

  async sendLinkViaSMS (orderId: string, phoneNumber: string) {
    try {
      return await this.post('/api/sales/send_link_to_mobile/', {
        order_id: orderId,
        phone_number: phoneNumber,
      })
    } catch (error) {
      throw new OrderAPIException('Unable to send link via SMS', (error as APIException))
    }
  }

  sendUnder18Email (payload: Under18EmailRequestPayload) {
    return this.post('/api/sales/under_18_email/', payload)
  }

  async getOrderContractPDFPreviewById (orderId: string) {
    try {
      return await this.get('/api/sales/get_pdf_contract/', { order_id: orderId }, { responseType: 'arraybuffer' })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a sale contract preview file', (error as APIException))
    }
  }

  async signOrderContractById (
    orderId: string,
    payload: any, // SignContractRequestPayload ?
  ) {
    try {
      return await this.post('/api/sales/attach_signature/', payload, { params: { order_id: orderId } })
    } catch (error) {
      throw new OrderAPIException('Unable to attach signature to a sale', (error as APIException))
    }
  }

  async salesStatistics (): Promise<SalesStatisticsResponse> {
    try {
      return await this.get('/api/sales/statistics')
    } catch (error) {
      throw new OrderAPIException('Unable to fetch sales statistics', (error as APIException))
    }
  }

  async signContractWithoutSignature (
    orderId: string,
  ) {
    try {
      return await this.post('/api/sales/attach_signature_no_file/', {}, { params: { order_id: orderId } })
    } catch (error) {
      throw new OrderAPIException('Unable to sign contract', (error as APIException))
    }
  }

  async requestCashbackByOrderId (payload: any) {
    try {
      return await this.post('/api/special_offers/request_cashback/', payload)
    } catch (error) {
      throw new OrderAPIException('Unable to submit a request cashback form', (error as APIException))
    }
  }

  async getNationalityID (nationality: string) {
    try {
      const code = nationality.substring(0, 2)

      const response = await this.get('/api/nationality/', { code }, {
        cache: {
          excludeFromCache: false,
        },
      })

      return response[0].pk
    } catch (error) {
      throw new OrderAPIException('Unable to obtain nationality ID', (error as APIException))
    }
  }

  async getCountries () {
    try {
      return await this.get('/api/nationality/')
    } catch (error) {
      throw new OrderAPIException('Unable to obtain countries list', (error as APIException))
    }
  }

  async checkAddressAvailability (offerId: string | number, city: string, zip: string, street: string, number: string) {
    try {
      return await this.post('/api/address_availability/', {
        offer_id: offerId,
        house: number,
        city,
        zip,
        street,
      })
    } catch (error) {
      throw new OrderAPIException('Unable to check address availability', (error as APIException))
    }
  }

  async sendFeedback (orderId: string, payload: Record<string, string>) {
    try {
      return await this.post('/api/sales/set_feedback/', {
        order_id: orderId,
        ...payload,
      })
    } catch (error) {
      throw new OrderAPIException('Unable to provide a feedback', (error as APIException))
    }
  }

  async sendCreditCheckRequest (payload: CreditCheckPayload) {
    try {
      return await this.post('/api/validate/', payload)
    } catch (error) {
      throw new OrderAPIException('Unable to send a credit check request', (error as APIException))
    }
  }

  async matchFace (orderId: string, frontSideDocument: string, backSideDocument: string, faceFilename: string) {
    try {
      return await this.post('/api/match_faces/', {
        order_id: orderId,
        doc_front_side: frontSideDocument,
        doc_back_side: backSideDocument,
        webcam_photo: faceFilename,
      })
    } catch (error) {
      const data = (error as APIException).response?.data || {}

      if (data.similarity && data.similarity < 70) {
        throw new OrderAPIException('Unable to match face. Similarity percent is too low', (error as APIException))
      }

      if (data.order_id && data.order_id.includes('The selfie is already passed')) {
        throw new OrderAPIException('ID verification has already been passed for this order', (error as APIException))
      }

      throw new OrderAPIException('Unknown error occurred during face matching procedure', (error as APIException))
    }
  }

  async getInvoiceUploadLink (orderId: string, documentName?: string) {
    try {
      return await this.get(`/api/sales/${orderId}/proof-documents/link`, {
        doc_name: documentName,
      })
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a voucher upload link by ID', (error as APIException))
    }
  }

  async confirmInvoiceUploaded (orderId: string) {
    try {
      return await this.post(`/api/sales/${orderId}/proof-documents/link`)
    } catch (error) {
      throw new OrderAPIException('Unable to obtain a voucher upload link by ID', (error as APIException))
    }
  }
}
