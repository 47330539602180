import axios, { CancelToken } from 'axios'
import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { createAmpOfferInstance, OfferCategoryType, Offers } from '@alao-frontend/core'
import { RootState } from '~/store'
import { $api } from '~/plugins/axios'

export const namespace = 'modules/Amp/OfferList'

export interface OfferState {
  /**
   * This is not entirely true. There are no mixed types of offers in offers.
   * But typescript has problems with array unions.
   */
  offers: Offers,
  count: number,
  loading: boolean,
  sort: string,
}

export enum ActionType {
  FETCH_OFFERS = 'FETCH_OFFERS',
}

export interface PublicAction {
  [ActionType.FETCH_OFFERS](payload: {
    payload: any,
    offerType: OfferCategoryType,
    isLoadMore: boolean,
    cancelToken?: CancelToken,
  }): Promise<| ReturnType<| typeof $api.PublicOfferService.searchOffers>>,
}

export const MutationType = {
  CLEAR_STATE: 'CLEAR_STATE',
  ADD_OFFERS: 'ADD_OFFERS',
  SET_OFFERS: 'SET_OFFERS',
  SET_COUNT: 'SET_COUNT',
  SET_LOADING: 'SET_LOADING',
  SET_OFFERS_SORT: 'SET_OFFERS_SORT',
}

export enum GettersType {
  GET_OFFERS = 'GET_OFFERS',
  GET_OFFERS_COUNT = 'GET_OFFERS_COUNT',
  IS_LOADING = 'IS_LOADING',
  GET_OFFERS_SORT = 'GET_OFFERS_SORT',
}

export interface PublicGetters {
  [GettersType.GET_OFFERS]: OfferState['offers'],
  [GettersType.GET_OFFERS_COUNT]: number,
  [GettersType.GET_OFFERS_SORT]: string,
  [GettersType.IS_LOADING]: boolean,
}

export const state = (): OfferState => ({
  offers: [],
  count: 0,
  loading: false,
  sort: '',
})

export const getters: GetterTree<OfferState, RootState> = {
  [GettersType.GET_OFFERS] (
    state: OfferState,
  ): PublicGetters[GettersType.GET_OFFERS] {
    return state.offers
  },
  [GettersType.GET_OFFERS_COUNT] (
    state: OfferState,
  ): PublicGetters[GettersType.GET_OFFERS_COUNT] {
    return state.count
  },
  [GettersType.GET_OFFERS_SORT] (
    state: OfferState,
  ): PublicGetters[GettersType.GET_OFFERS_SORT] {
    return state.sort
  },
  [GettersType.IS_LOADING] (
    state: OfferState,
  ): PublicGetters[GettersType.IS_LOADING] {
    return state.loading
  },
}

export const actions: ActionTree<OfferState, RootState> = {
  async [ActionType.FETCH_OFFERS] (
    { commit }, arg: Parameters<PublicAction[ActionType.FETCH_OFFERS]>[0],
  ) {
    const { payload, offerType, isLoadMore, cancelToken } = arg
    try {
      const { items, pagination, result_match_filter } = await $api.PublicOfferService.searchOffers({ ...payload, product_type: offerType }, { cancelToken })
      const offers = items.map(offer => createAmpOfferInstance(offer))
      if (isLoadMore) {
        commit(MutationType.ADD_OFFERS, offers)
      } else {
        commit(MutationType.SET_OFFERS, offers)
      }
      commit(MutationType.SET_COUNT, pagination.total)
      return { items, result_match_filter }
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw error
      }
    }
  },
}

export const mutations: MutationTree<OfferState> = {
  [MutationType.ADD_OFFERS] (
    state: OfferState,
    offers: OfferState['offers'],
  ): void {
    const offersType = state.offers[0].offerType ?? offers[0].offerType

    if (
      offers.some(
        (el: OfferState['offers'][number]) => el.offerType !== offersType,
      )
    ) {
      throw new Error('Do not mix offers with different types')
    }

    state.offers = [...state.offers, ...offers] as OfferState['offers']
  },
  [MutationType.SET_OFFERS] (state: OfferState, Offers: []): void {
    state.offers = Offers
  },
  [MutationType.SET_COUNT] (state: OfferState, count: number): void {
    state.count = count
  },
  [MutationType.SET_OFFERS_SORT] (state: OfferState, sort: string): void {
    state.sort = sort
  },
  [MutationType.CLEAR_STATE] (state: OfferState): void {
    state.offers = []
    state.count = 0
    state.loading = false
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
}
