
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { getCookie, setCookie } from '@alao-frontend/utils'
import { PageBanner } from '~/types/promo/page-banner'
import { PageBannerTemplate } from '~/types/promo/page-banner-template'
import IconClose from '~/components/banners/PageDynamicBanner/templates/PageDynamicBannerTemplate1/IconClose.vue'
import Countdown from '~/components/banners/PageDynamicBanner/templates/PageDynamicBannerTemplate1/Countdown.vue'
import { Campaign } from '~/types/promo/campaign'

@Component({
  components: {
    IconClose,
    Countdown,
  },
})
export default class PageDynamicBannerTemplate1 extends Vue {
  @Prop({ required: true, type: Object })
  readonly banner!: PageBanner

  @Prop({ required: true, type: Object })
  readonly template!: PageBannerTemplate

  @Prop({ required: true, type: Object })
  readonly campaign!: Campaign

  isDismissed = false

  get countDownTo () {
    return this.campaign.dateEnd
  }

  get isDisplay () {
    if (this.isDismissed) {
      return false
    }

    return (
      this.banner.visibility === 'both' ||
      (this.banner.visibility === 'desktop' && this.$device.isDesktop) ||
      (this.banner.visibility === 'mobile' && this.$device.isMobileOrTablet)
    )
  }

  get content () {
    switch (this.$i18n.locale) {
      case 'en':
        return this.banner.content.en

      case 'de':
        return this.banner.content.de

      case 'fr':
        return this.banner.content.fr

      default:
        return this.banner.content.en
    }
  }

  get bannerBodyStyles () {
    return {
      backgroundColor: this.template.backgroundColor,
      color: this.template.textColor,
    }
  }

  get cookieName () {
    return `dp_${this.banner.id}`
  }

  isDismissedCookieSet () {
    try {
      return !!getCookie(this.cookieName)
    } catch (e) {
      return false
    }
  }

  dismiss () {
    this.isDismissed = true

    try {
      setCookie(this.cookieName, 1, {
        expires: 1,
      })
    } catch (e) {
      console.error('Unable to set cookie')
    }

    this.$emit('dismiss', this.banner.id)
  }

  beforeMount () {
    this.isDismissed = this.isDismissedCookieSet()
  }
}
