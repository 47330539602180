
import { Component, namespace, Prop, Vue } from 'nuxt-property-decorator'
import IconGoogle from '~/components/icons/IconGoogle.vue'
import { GRWidgetState, namespace as grWidgetStore } from '~/store/modules/GRWidget'

const GRWidgetStore = namespace(grWidgetStore)

@Component({
  components: {
    IconGoogle,
  },
})
export default class GoogleRating extends Vue {
  @GRWidgetStore.State('isLoading') isLoading!: GRWidgetState['isLoading']
  @GRWidgetStore.State('isLoaded') isLoaded!: GRWidgetState['isLoaded']
  @GRWidgetStore.State('rating') rating!: GRWidgetState['rating']
  @GRWidgetStore.State('reviews') reviews!: GRWidgetState['reviews']

  @Prop({
    default: '',
    type: String,
  })
  borderColor!: string

  get wrapperStyles () {
    return Object.assign({},
      this.borderColor && {
        border: `1px solid ${this.borderColor}`,
      })
  }

  get ratingRounded (): number {
    return Math.ceil(this.rating)
  }
}
