
import { Component, Emit, Prop, Ref, Vue } from 'nuxt-property-decorator'

let animationFrame: number | null = null
const colors = ['#FBEEE2', '#F8CCA3']

// Most of the code was taken from https://codesandbox.io/p/sandbox/wheel-of-fortune-1475d
// and modified to fit our needs

@Component
export default class AppFortuneWheel extends Vue {
  @Ref('wheel')
  readonly wheel!: HTMLCanvasElement

  @Ref('spin')
  readonly spin!: HTMLSpanElement

  @Prop({
    type: Number,
    required: true,
  })
  readonly size!: number

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly once!: boolean

  @Prop({
    type: Array,
    required: true,
  })
  readonly items!: string[]

  isPlayed = false
  readonly friction = 0.98
  readonly TAU = Math.PI * 2

  angleVelocity = Math.random() * (0.25 - 0.15) + 0.15
  angleRadians = 0
  borderWidth = 8

  get wrapperStyles () {
    return {
      width: `${this.size + this.borderWidth * 2}px`,
      height: `${this.size + this.borderWidth * 2}px`,
      borderWidth: `${this.borderWidth}px`,
    }
  }

  get sectors () {
    return this.items.map((item, i) => ({
      label: item,
      color: i % 2 === 0 ? colors[0] : colors[1],
    }))
  }

  get slotsAmount () {
    return this.sectors.length
  }

  get wheelDimensions () {
    const {
      width,
      height,
    } = this.wheel.getBoundingClientRect()
    return [width, height]
  }

  get wheelRadius () {
    return this.wheelDimensions[0] / 2
  }

  get arc () {
    return this.TAU / this.slotsAmount
  }

  getIndex () {
    return Math.floor(this.slotsAmount - (this.angleRadians / this.TAU) * this.slotsAmount) % this.slotsAmount
  }

  wrapText (
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number,
  ) {
    const words = text.split(' ')
    const lines = []

    lines.push({
      text: '',
      x: 0,
      y: 0,
    })

    for (let i = 0; i < words.length; i++) {
      const currentLine: Record<string, any> = lines[lines.length - 1]
      const testLine = `${currentLine.text} ${words[i]}`

      if (ctx.measureText(testLine).width > maxWidth) {
        lines.push({
          text: words[i],
          x: 0,
          y: currentLine.y + lineHeight,
        })
      } else {
        currentLine.text = testLine
      }
    }

    const halfHeight = (lines.length * lineHeight) / 2

    lines.forEach((line) => {
      ctx.fillText(line.text, x, y - halfHeight + line.y)
    })
  }

  drawSector (sector: any, i: number, bgColor = sector.color, textColor = '#3C3C3B') {
    const ctx = this.wheel.getContext('2d') as CanvasRenderingContext2D

    const angle = this.arc * i
    const fontSize = this.$device.isMobile ? 12 : 20
    const lineHeight = this.$device.isMobile ? 10 : 20
    const maxTextWidth = this.$device.isMobile ? 80 : 120
    const offset = this.$device.isMobile ? this.wheelRadius - 5 : this.wheelRadius - 10

    ctx.save()

    // COLOR
    ctx.beginPath()
    ctx.fillStyle = bgColor
    ctx.moveTo(this.wheelRadius, this.wheelRadius)
    ctx.arc(this.wheelRadius, this.wheelRadius, this.wheelRadius, angle, angle + this.arc)
    ctx.lineTo(this.wheelRadius, this.wheelRadius)
    ctx.fill()

    // TEXT
    ctx.translate(this.wheelRadius, this.wheelRadius)
    ctx.rotate(angle + this.arc / 2)
    ctx.textAlign = 'right'
    ctx.fillStyle = textColor
    ctx.font = `400 ${fontSize}px Outfit`
    this.wrapText(ctx, sector.label, offset, 10, maxTextWidth, lineHeight)

    ctx.restore()
  }

  rotate () {
    const ctx = this.wheel.getContext('2d') as CanvasRenderingContext2D

    ctx.canvas.style.transform = `rotate(${this.angleRadians - Math.PI / 2}rad)`

    if (this.angleVelocity === 0) {
      this.onStop()
    }
  }

  frame () {
    if (!this.angleVelocity) {
      return
    }

    this.angleVelocity *= this.friction

    if (this.angleVelocity < 0.0005) {
      this.angleVelocity = 0
    }

    this.angleRadians += this.angleVelocity // Update angle
    this.angleRadians %= this.TAU // Normalize angle

    this.rotate()
  }

  @Emit('spin:end')
  onStop () {
    const index = this.getIndex()
    const sector = this.sectors[index]

    this.drawSector(sector, index, '#333', '#fff')

    cancelAnimationFrame(animationFrame as number)

    return index
  }

  @Emit('spin:start')
  start () {
    // Prevent multiple spins if once prop is true and wheel is already played
    if (this.once && this.isPlayed) {
      return
    } else {
      this.init()
    }

    this.runAnimation()

    this.isPlayed = true
  }

  runAnimation () {
    this.frame()
    animationFrame = requestAnimationFrame(this.runAnimation)
  }

  init () {
    this.sectors.forEach((sector, i) => this.drawSector(sector, i))
  }

  mounted () {
    this.init()
  }
}
