import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'

const trailingSlashRedirect: Middleware = ctx => createNuxtMiddleware(ctx, () => {
  const { route, redirect } = ctx

  if (!route.path.endsWith('/')) {
    const { path, query, hash } = route
    const nextPath = path + '/'
    const nextRoute = { path: nextPath, query, hash }

    redirect(nextRoute)
  }
})

export default trailingSlashRedirect
