import { AnalyticsUtils } from '~/plugins/analytics/utils'

export class BaseAnalytics {
  private readonly analyticsUtils!: AnalyticsUtils

  constructor (protected provider: any) {
    this.analyticsUtils = new AnalyticsUtils()
  }

  get utils () {
    return this.analyticsUtils
  }
}
