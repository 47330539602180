
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { getCookie, setCookie } from '@alao-frontend/utils'

@Component({
})
export default class CookieBanner extends Vue {
  @Prop({
    default: false,
    type: Boolean,
  })
  sticky!: boolean

  isCookieBannerHidden = true
  isTextEllipsis = false

  mounted () {
    if (this.$mqUtils.isMobile) {
      this.isTextEllipsis = true
    }
    this.isCookieBannerHidden = Boolean(getCookie('isCookieBannerHidden'))
  }

  handleClick () {
    const date = new Date()
    // set cookie expiration in 180 days
    setCookie('isCookieBannerHidden', 1, {
      expires: new Date(date.setDate(date.getDate() + 180)),
      path: '/',
    })
    this.isCookieBannerHidden = true
  }

  setFullText () {
    this.isTextEllipsis = false
  }
}
