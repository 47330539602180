import { MobileOfferDTO, HomeOfferDTO } from '@alao-frontend/core'
import { ApplicationService } from '~/services/core/application.service'

export type ExcludedBlock =
  | 'hero'
  | 'how_it_works'
  | 'mobile_offers'
  | 'home_offers'

/* eslint-disable camelcase */

export type PartnerPromo = {
  applied_text: string,
  code: string,
}
export interface Partner {
  color_hex_code: string,
  name: string,
  slug: string,
  logo: string,
  url: string,
  is_active: boolean,
  mobile_offers: MobileOfferDTO[],
  home_offers: HomeOfferDTO[],
  show_goal: boolean,
  goal: string,
  goal_text: string,
  offset: string,
  achieved: string,
  hero_text: string,
  exclusive_promo: PartnerPromo | null,
  excluded_blocks: ExcludedBlock[] | null,
  reset_unfinished_orders: boolean,
  skip_selfie: boolean,
}

export class PartnerService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  fetchPartner (slug: string): Promise<Partner> {
    return this.get(
      `/api/whitelabels/partners/${slug}/`,
    )
  }
}
