import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware, atob } from '@alao-frontend/utils'

const siteLevelAdsCookies: Middleware = ctx => createNuxtMiddleware(
  ctx,
  (
    utils,
  ) => {
    const {
      ADS_COOKIES_QUERY_PARAM_NAME: paramName,
      ADS_COOKIES_EXP: expiration,
      APP_DOMAIN: domain,
    } = ctx.$config

    const { route, redirect } = ctx
    const paramValue = route.query[paramName]
    if (!paramValue) {
      return
    }

    try {
      const adsCookies = JSON.parse(atob(String(paramValue)))

      Object.entries(adsCookies).forEach(([name, value]) => {
        utils.setCookie(name, String(value), {
          expires: expiration,
          domain,
          path: '/',
        })
      })
    } catch (e) {
      ctx.$sentry.captureException(e)
      console.error(e)
    }

    const { path, query, hash } = route
    delete query[paramName]
    const nextRoute = { path, query, hash }

    redirect(nextRoute)
  })

export default siteLevelAdsCookies
