
import { Component, namespace, Prop, Vue } from 'nuxt-property-decorator'
import { OnBeforeDestroy, OnMounted } from '~/core/vue.types'
import AppLangSwitcher from '~/components/snippets/AppLangSwitcher.vue'
import { Partner } from '~/services/partner.service'
import { GettersType as partnerGettersType, namespace as partnerStore } from '~/store/modules/Partner'

const PartnerStore = namespace(partnerStore)

@Component({
  components: {
    AppLangSwitcher,
  },
})
export default class PartnerHeader
  extends Vue
  implements OnMounted, OnBeforeDestroy {
  @Prop({ required: false, default: 'common' }) readonly type!: string
  @Prop({ required: true, default: true }) readonly isShadow!: boolean

  @PartnerStore.Getter(partnerGettersType.GET_CURRENT_PARTNER)
  private partner!: Partner

  private isMobileNavActive = false
  private isShadowAppliedByScroll = false

  toggleMobileNav () {
    this.isMobileNavActive = !this.isMobileNavActive
  }

  onScrollHandler () {
    this.isShadowAppliedByScroll = window.scrollY > 50
  }

  mounted () {
    window.addEventListener('scroll', this.onScrollHandler.bind(this), {
      passive: true,
    })
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScrollHandler.bind(this))
  }
}
