import { OfferCategoryNamesType, OfferTypeKey } from '~/core/offer.types'

export const ActionSource = 'website'
export const Currency = 'CHF'

export enum ContentType {
  PRODUCT = 'product',
}

/* eslint-disable camelcase */
export enum OrderStepsEventName {
  REVIEW_ORDER = '1REVIEWORDER',
  ORDER_CONFIRMED = '2ORDERCONFIRMED',
  DETAILS_SUBMITTED = '3DETAILSSUBMITTED',
  ID_VERIFIED = '4IDVERIFIED',
  ALL_SET = '5ALLSET',
  FAILED_CREDIT_CHECK = 'FAILEDCREDITCHECK',
  VIEW_DOWN_PAYMENT = 'VIEWDOWNPAYMENT',
  DOWN_PAYMENT_COMPLETED = 'DOWNPAYMENTCOMPLETED',
  FRAUD_PREVENTION_MODAL_VIEW = 'FRAUD_PREVENTION_MODAL_VIEW',
  TEST_EVENT = 'TEST_EVENT',
}

export enum EventType {
  TRACK = 'track',
  TRACK_CUSTOM = 'trackCustom',
}

export type OfferType = OfferTypeKey

interface EventProductContent {
  product_id: string | number,
  quantity: number,
  item_price?: number,
  title?: string,
  description?: string,
  brand?: string,
  category?: string,
  delivery_category?: string,
}

enum DeliveryCategory {
  /**
   * Customer needs to enter the store to get the purchased product.
   */
  IN_STORE = 'in_store',

  /**
   * Customer picks up their order by driving to a store and waiting inside their vehicle.
   */
  CURBSIDE = 'curbside',

  /**
   * Purchase is delivered to the customer's home.
   */
  HOME_DELIVERY = 'home_delivery',
}

export type BaseEventPayload = {
  content_type: string,
  content_ids: string[],
  value?: number,
  currency?: string,
  content_name?: string,
  content_category?: string,
  contents?: EventProductContent[],
  order_id?: string,
  predicted_ltv?: number,
  num_items?: number,
  status?: string,
  search_string?: string,
  delivery_category?: DeliveryCategory,
  item_number?: string,
  custom_properties?: Record<string, unknown>,
}

export type PurchaseEventPayload = BaseEventPayload & Required<{
  value: number,
  currency: string,
}>

export type OrderStepsPayload = BaseEventPayload & {
  custom_properties?: {
    event_category: OfferCategoryNamesType,
    offer_name: string,
    offer_price: number,
    provider: string,
  },
}

export type StandardEventPayload =
  BaseEventPayload |
  PurchaseEventPayload

export type CustomEventPayload = OrderStepsPayload

/**
 * Uses for 'track' events
 * For more information read [here](https://developers.facebook.com/docs/facebook-pixel/reference#standard-events)
 */
export enum StandardEventName {
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WISHLIST = 'AddToWishlist',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  CONTACT = 'Contact',
  CUSTOMIZE_PRODUCT = 'CustomizeProduct',
  DONATE = 'Donate',
  FIND_LOCATION = 'FindLocation',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  LEAD = 'Lead',
  PAGE_VIEW = 'PageView',
  PURCHASE = 'Purchase',
  SCHEDULE = 'Schedule',
  SEARCH = 'Search',
  START_TRIAL = 'StartTrial',
  SUBMIT_APPLICATION = 'SubmitApplication',
  SUBSCRIBE = 'Subscribe',
  VIEW_CONTENT = 'ViewContent',
  LEAD_ALAO_PLUS = 'lead_alao_plus',
}

/**
 * Uses for 'trackCustom' events
 */
export type CustomEventName = OrderStepsEventName

/**
 * Common type of event name
 */
export type EventName =
  StandardEventName |
  CustomEventName

/**
 * ---------------------------------------------------
 * Server Side Tracking
 * ---------------------------------------------------
 */

export enum SSTUserGender {
  MALE = 'm',
  FEMALE = 'f',
}

export interface SSTUserData {
  email?: string,
  phone?: string,
  gender?: SSTUserGender,
  date_of_birth?: string,
  last_name?: string,
  first_name?: string,
  city?: string,
  state?: string,
  country_code?: string,
  zip_code?: string,
  external_id?: string,
  subscription_id?: string,
  fb_login_id?: string,
  lead_id?: string,
  f5first?: string,
  f5last?: string,
  fi?: string,
  dobd?: string,
  dobm?: string,
  doby?: string,
  // The params below are being populated on backend side
  client_ip_address?: string,
  client_user_agent?: string,
  fbc?: string,
  fbp?: string,
}

export interface SSTPayload {
  custom_data?: BaseEventPayload,
  user_data?: SSTUserData,
  test_event_code?: string,
}

export interface SSTEventData {
  event_name: EventName,
  event_id: string,
  event_time: number,
  event_source_url: string,
  action_source: typeof ActionSource,
  payload: SSTPayload,
}
/* eslint-enable camelcase */
