import debounce from 'lodash/debounce'

export function Debounce (delay: number, options?: Parameters<typeof debounce>[2]) {
  return (target: any, prop: string) => {
    return {
      configurable: true,
      enumerable: false,
      value: debounce(target[prop], delay, options),
    }
  }
}
