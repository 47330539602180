import { ABTestModel } from '@alao-frontend/erp-services'

const EXPERIMENT_ID = 'ADsz9yrJQASaS7ylzn9PhA'

const experimentOptions: ABTestModel = {
  experiment: EXPERIMENT_ID,
  variations: [
    {
      code: 0,
      weight: 1,
    },
    {
      code: 1,
      weight: 1,
    },
  ],
}

export default experimentOptions
