import { ApplicationService } from '~/services/core/application.service'

export enum NotifiesOfferType {
  MOBILE_OFFER = 'mobile_offer',
  HOME_OFFER = 'home_offer',
  DEVICE_OFFER = 'device_offer',
}

type SubscribePayload = {
  id: string,
  subscribe: boolean,
  service: string,
  offer_type?: NotifiesOfferType,
  contact_list_id?: number | string,
}

export class NotifierService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.NOTIFIER_API_BASE_URL
  }

  createLead (email: string) {
    return this.post('/api/leads/create', { email })
  }

  subscribe (payload: SubscribePayload) {
    return this.put('/api/notifies/subscribe', payload)
  }
}
