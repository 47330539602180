import HubSpotModule from '~hub-spot/class'

export default (ctx, inject) => {
  /**
   * @type {ModuleOptions | undefined }
   */
  const parsedOptions = JSON.parse('{"disabled":false,"hsConversationsSettings":{"loadImmediately":false,"enableWidgetCookieBanner":false,"disableAttachment":false,"inlineEmbedSelector":"#hubspot-inline-chat"},"portalId":7184486}')

  /**
   * @type ModuleOptions
   */
  const options = Object.assign({}, parsedOptions)

  if (process.client) {
    (function(id) {
      if (document.getElementById(id)) {
        return
      }
      const js = document.createElement('script')
      js.id = id
      js.src = `//js.hs-scripts.com/${options.portalId}.js`
      document.body.appendChild(js)
    })('hs-scripts')
  }

  const instance = new HubSpotModule(options)

  ctx.$hubSpot = instance
  inject('hubSpot', instance)
}
