
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'

@Component
export default class AppDixaChat extends Vue {
  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly openOnLoad!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly wide!: boolean

  isChatVisible = false
  isChatReady = false

  onReady () {
    this.isChatReady = true

    this.$dixa.on('on-widget-open-changed', (isOpen: boolean) => {
      this.isChatVisible = isOpen
    })

    if (this.openOnLoad) {
      this.$dixa.setWidgetOpen(true)
      this.isChatVisible = true

      return
    }

    this.isChatVisible = false
  }

  onBtnToggleClick () {
    if (this.isChatVisible) {
      this.$dixa.setWidgetOpen(false)
      this.isChatVisible = false
    } else {
      this.$dixa.setWidgetOpen(true)
      this.isChatVisible = true
    }
  }

  created () {
    if (!process.client) {
      return
    }

    if (!this.$dixa) {
      return
    }

    this.$dixa.init(this.$i18n.locale, {
      verticalPadding: 20,
      horizontalPadding: 16,
    })

    this.onReady()
  }

  @Watch('$i18n.locale')
  onLocaleChange () {
    if (!this.$dixa) {
      return
    }

    if (!this.$dixa.isBusy) {
      this.isChatReady = false
      this.$dixa.restart(this.$i18n.locale)
      setTimeout(this.onReady.bind(this), 100)
    }
  }

  beforeDestroy () {
    this.$dixa.shutdown()
  }
}
