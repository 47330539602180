
import { Component, Vue } from 'nuxt-property-decorator'

import AppLayout from '~/components/AppLayout.vue'
import AppOfflineBar from '~/components/AppOfflineBar.vue'
import AlaoNotification from '~/components/AlaoNotification/AlaoNotification.vue'

@Component({
  components: {
    AppLayout,
    AppOfflineBar,
    AlaoNotification
  },
})
export default class LandingLayout extends Vue {
}
