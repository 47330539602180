import DixaModule from '~dixa/class'

export default (ctx, inject) => {
  /**
   * @type {ModuleOptions | undefined }
   */
  const parsedOptions = JSON.parse('{"disabled":false,"useCustomToggle":true,"tokens":{"en":"281fa330000b457b817f7b697b1a5e61","de":"112a5856fb8b45e0b48283105fe6cb76","fr":"14e010edbb4d4930b383157d3d2571d1"}}')

  /**
   * @type ModuleOptions
   */
  const options = Object.assign({}, parsedOptions)

  if (process.client) {
    (function (w, d, s) {
      if (w._dixa_) return;
      w._dixa_ = {
        invoke: function (m, a) {
          w._dixa_.pendingCalls = w._dixa_.pendingCalls || [];
          w._dixa_.pendingCalls.push([m, a]);
        },
        addListener: function (event, listener) {
          w._dixa_.pendingAddListenerCalls = w._dixa_.pendingAddListenerCalls || [];
          w._dixa_.pendingAddListenerCalls.push([event, listener]);
        },
      };
      s = d.createElement('script');
      s.type = 'text/javascript';
      s.setAttribute('charset', 'utf-8');
      s.async = true;
      s.src = 'https://messenger.dixa.io/bootstrap.js';
      var before = d.getElementsByTagName('script')[0];
      before.parentNode.insertBefore(s, before);
    })(window, document);
  }

  const instance = new DixaModule(options)

  ctx.$dixa = instance
  inject('dixa', instance)
}