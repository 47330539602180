
import { Component, mixins } from 'nuxt-property-decorator'

import ErrorCapturedMixin from '@alao-frontend/error-captured.mixin'
import AppOfflineBar from '~/components/AppOfflineBar.vue'

import SeoLayoutMixin from '~/layouts/mixins/seo'

import PartnerHeader from '~/components/partners/PartnerHeader.vue'
import PartnerFooter from '~/components/partners/PartnerFooter.vue'
import AppLayoutOverlay from '~/components/AppLayoutOverlay/AppLayoutOverlay.vue'
import AlaoNotification from '~/components/AlaoNotification/AlaoNotification.vue'

@Component({
  components: {
    AppLayoutOverlay,
    AppOfflineBar,
    PartnerHeader,
    PartnerFooter,
    AlaoNotification,
  },
})
export default class PartnerLayout extends mixins(ErrorCapturedMixin, SeoLayoutMixin) {
  get isHeaderWithShadow () {
    return this.localePath('index') !== this.$route.path
  }

  get isShowFooter () {
    return this.localePath('order') !== this.$route.path
  }
}
