/* eslint-disable no-use-before-define */

// See more
// https://firebase.google.com/docs/firestore/reference/rest/v1/StructuredQuery
type Field = {
  fieldPath: string,
}

export enum Operator {
  OPERATOR_UNSPECIFIED = 'OPERATOR_UNSPECIFIED',
  AND = 'AND',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  ARRAY_CONTAINS = 'ARRAY_CONTAINS',
  IN = 'IN',
  ARRAY_CONTAINS_ANY = 'ARRAY_CONTAINS_ANY',
  NOT_IN = 'NOT_IN',
  IS_NAN = 'IS_NAN',
  IS_NOT_NAN = 'IS_NOT_NAN',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL',
}

type LatLng = {
  latitude: number,
  longitude: number,
}

type Value =
  | NullValue
  | BooleanValue
  | IntegerValue
  | DoubleValue
  | TimestampValue
  | StringValue
  | BytesValue
  | ReferenceValue
  | GeoPointValue
  | ArrayValue<any>
  | MapValue<any>

export type NullValue = Record<'nullValue', null>
export type BooleanValue = Record<'booleanValue', boolean>
export type IntegerValue = Record<'integerValue', string> // @justlstn: idk why it uses string
export type DoubleValue = Record<'doubleValue', number>
export type TimestampValue = Record<'timestampValue', string>
export type StringValue = Record<'stringValue', string>
export type BytesValue = Record<'bytesValue', string>
export type ReferenceValue = Record<'referenceValue', string>
export type GeoPointValue = Record<'geoPointValue', LatLng>

export type ArrayValue<
  T extends Omit<Value, 'ArrayValue'>,
> = {
  arrayValue: {
    values: T[],
  },
}
export type MapValue<
  T extends Omit<Value, 'MapValue'>,
> = {
  mapValue: {
    fields: T,
  },
}

type CompositeFilter = {
  op:
    | Operator.OPERATOR_UNSPECIFIED
    | Operator.AND,
  filters: Filter[],
}

type FieldFilter = {
  field: Field,
  op:
    | Operator.OPERATOR_UNSPECIFIED
    | Operator.LESS_THAN
    | Operator.LESS_THAN_OR_EQUAL
    | Operator.GREATER_THAN
    | Operator.GREATER_THAN_OR_EQUAL
    | Operator.EQUAL
    | Operator.NOT_EQUAL
    | Operator.ARRAY_CONTAINS
    | Operator.IN
    | Operator.ARRAY_CONTAINS_ANY
    | Operator.NOT_IN,
  value: Value,
}

type UnaryFilter = {
  op:
    | Operator.OPERATOR_UNSPECIFIED
    | Operator.IS_NAN
    | Operator.IS_NULL
    | Operator.IS_NOT_NAN
    | Operator.IS_NOT_NULL,
  field: Field,
}

type Filter = {
  fieldFilter: FieldFilter,
}

type FirebaseQueryCollection = {
  collectionId: string,
}

type FirebaseQuerySelect = {
  fields: Field[],
}

type FirebaseQueryOrderBy = {
  field: Field,
  direction: 'ASCENDING' | 'DESCENDING',
}

export type FirebaseWhere = {
  compositeFilter: CompositeFilter,
} | {
  fieldFilter: FieldFilter,
} | {
  unaryFilter: UnaryFilter,
}

type StructuredQuery = Partial<{
  select: FirebaseQuerySelect,
  from: FirebaseQueryCollection[],
  orderBy: FirebaseQueryOrderBy[],
  where: FirebaseWhere,
  startAt: unknown,
  endAt: unknown,
  offset: number,
  limit: number,
}>

export type FirebaseQuery = {
  structuredQuery: StructuredQuery,
}

export type FirebaseResponseDocument<T extends Record<string, any>> = {
  name: string,
  fields: T,
  createTime: string,
  updateTime: string,
}

export type FirebaseResponseItem<T extends Record<string, any>> = {
  document: FirebaseResponseDocument<T>,
  readTime: string,
}

type CompositeConditionFilter = {
  type: 'composite',
  op: CompositeFilter['op'],
  filters: Filter[],
}

type FieldConditionFilter = {
  type: 'field',
  filter: FieldFilter,
}

type UnaryConditionFilter = {
  type: 'unary',
  filter: UnaryFilter,
}

export type ConditionFilter =
  | CompositeConditionFilter
  | FieldConditionFilter
  | UnaryConditionFilter
