import { isBundleOffer, isHomeOffer, isMobileOffer, Offer } from '@alao-frontend/core'
import { GATrackEvent } from '~/plugins/analytics/events/ga.event'
import {
  AnalyticsOffersList,
  AnalyticsReadableOfferTypes,
  ECommerceEventAction,
  ECommerceEventName,
  ECommerceEventPayload,
  TrackEventOptions,
  WithEventProperty,
} from '~/plugins/analytics/types'

export type ECommerceUserData = {
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  number: string | number,
  street: string,
  city: string,
  zip: string,
  country: string,
}

export type ECommerceEventMeta = {
  eventName: ECommerceEventName,
  eventAction?: ECommerceEventAction,
  userData?: ECommerceUserData,
  offer: Offer,
  currency: string,
  orderId?: string,
  promoCode?: string,
  idVerification?: {
    idType?: string,
    idFailReason?: string,
  },
  // The position in the product list which the item has when was clicked by user if applicable
  position: {
    // Positive number starting from 1
    index: number,
    name: AnalyticsOffersList,
  },
}

export class ECommerceEvent extends GATrackEvent<ECommerceEventPayload> {
  readonly eventCategory = 'enhanced-ecommerce'

  constructor (private meta: ECommerceEventMeta, protected options: TrackEventOptions) {
    super(options.provider, options.debug)
  }

  get payload (): WithEventProperty<ECommerceEventPayload> {
    const {
      eventName,
    } = this.meta

    return Object.assign({},
      this.userData && {
        user_data: this.userData,
      },
      {
        data: this.data,
      },
      {
        ecommerce: this.ecommerce,
      },
      {
        event: 'GTMecommerce-v2',
        eventName,
        _clear: true,
      })
  }

  get userData () {
    const { userData } = this.meta

    if (!userData) {
      return null
    }

    return {
      email: userData?.email ?? '',
      phone_number: userData?.phoneNumber ?? '',
      address: {
        first_name: userData?.firstName ?? '',
        last_name: userData?.lastName ?? '',
        street: userData
          ? `${userData.number}, ${userData.street}`
          : '',
        region: '', // We don't have this data but it's required
        city: userData?.city || '',
        postal_code: userData?.zip || '',
        country: userData?.country || '',
      },
    }
  }

  get data () {
    const {
      eventAction,
      orderId,
      idVerification,
    } = this.meta

    const idType = idVerification?.idType
    const idFailReason = idVerification?.idFailReason

    return Object.assign(
      {},
      eventAction && {
        event_action: eventAction,
      },
      {
        event_category: this.eventCategory,
        event_label: `${this.offerCategory}_${this.offerName}`,
        plan_category: this.offerCategory,
        plan_name: this.offerName,
        plan_id: String(this.meta.offer.id),
        provider: this.meta.offer.providers.map(provider => provider.name).join('_'),
      }, orderId && {
        order_id: orderId,
      }, idType && {
        id_type: idType,
      }, idFailReason && {
        id_fail_reason: idFailReason,
      })
  }

  get ecommerce () {
    const {
      orderId,
      currency,
      promoCode,
    } = this.meta

    return Object.assign(
      {},
      this.meta.eventName === ECommerceEventName.PURCHASE && {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: this.meta.offer.dtoData._uetvid, // TODO: Add commission value to the DTO
        tax: 0,
        shipping: 0,
        transaction_id: orderId,
        coupon: promoCode || '',
      },
      {
        currency,
        items: this.ecommerceItems,
      })
  }

  get ecommerceItems () {
    const { offer, position } = this.meta

    const items = []

    if (isMobileOffer(offer) || isHomeOffer(offer)) {
      items.push({
        affiliation: 'alao',
        item_name: offer.name,
        item_id: String(offer.id),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        price: offer.dtoData._uetvid, // TODO: Add commission value to the DTO
        item_brand: offer.providers[0].name,
        item_category: this.offerCategory,
        item_variant: 'Individual Plan',
        cashback_amount: offer.regularCashbacks.map(c => c.value).reduce((a, b) => a + b, 0),
        voucher_amount: offer.regularVouchers.map(v => v.value).reduce((a, b) => a + b, 0),
        price_per_month: offer.finalPrice,
        full_price_per_month: offer.regularPrice,
        index: position.index,
        item_list_id: position.name,
        item_list_name: position.name,
        quantity: 1,
      })
    }

    if (isBundleOffer(offer)) {
      items.push({
        affiliation: 'alao',
        item_name: offer.mobileOfferInstance.name,
        item_id: String(offer.id),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        price: offer.mobileOfferInstance.dtoData._uetvid, // TODO: Add commission value to the DTO
        item_brand: offer.mobileOfferInstance.providers[0].name,
        item_category: AnalyticsReadableOfferTypes[offer.mobileOfferInstance.type],
        item_variant: 'Individual Plan',
        cashback_amount: offer.mobileOfferInstance.regularCashbacks.map(c => c.value).reduce((a, b) => a + b, 0),
        voucher_amount: offer.mobileOfferInstance.regularVouchers.map(v => v.value).reduce((a, b) => a + b, 0),
        price_per_month: offer.mobileOfferInstance.finalPrice,
        full_price_per_month: offer.mobileOfferInstance.regularPrice,
        index: position.index,
        item_list_id: position.name,
        item_list_name: position.name,
        quantity: 1,
      })

      items.push({
        affiliation: 'alao',
        item_name: offer.homeOfferInstance.name,
        item_id: String(offer.id),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        price: offer.homeOfferInstance.dtoData._uetvid, // TODO: Add commission value to the DTO
        item_brand: offer.homeOfferInstance.providers[0].name,
        item_category: AnalyticsReadableOfferTypes[offer.homeOfferInstance.type],
        item_variant: 'Individual Plan',
        cashback_amount: offer.homeOfferInstance.regularCashbacks.map(c => c.value).reduce((a, b) => a + b, 0),
        voucher_amount: offer.homeOfferInstance.regularVouchers.map(v => v.value).reduce((a, b) => a + b, 0),
        price_per_month: offer.homeOfferInstance.finalPrice,
        full_price_per_month: offer.homeOfferInstance.regularPrice,
        index: position.index,
        item_list_id: position.name,
        item_list_name: position.name,
        quantity: 1,
      })
    }

    return items
  }

  get offerCategory () {
    return AnalyticsReadableOfferTypes[this.meta.offer.offerType]
  }

  get offerName () {
    return this.meta.offer.name
  }
}
