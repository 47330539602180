import { ApplicationService } from '~/services/core/application.service'

const ServiceAbTypes = {
  VOUCHER_EXPERIMENT: '/api/ab/experiment/',
}

type VoucherExperiment = {
  code: string,
  variations: Record<'code', string>[],
}
export class AbService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  getVoucherExperiment (): Promise<VoucherExperiment> {
    return this.get(ServiceAbTypes.VOUCHER_EXPERIMENT, {})
  }
}
