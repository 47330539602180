var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppTransition',{attrs:{"name":_vm.overlayTransitionName,"appear":""}},[_c('div',_vm._b({staticClass:"app-modal",class:[`app-modal-${_vm.size}`, {
      'app-modal--centered': _vm.centered,
    }]},'div',_vm.$attrs,false),[_c('div',{staticClass:"app-modal__overlay",class:{
        'app-modal__overlay--transparent': _vm.overlayTransparent,
        'app-modal__overlay--blur': _vm.overlayBlur,
      }}),_vm._v(" "),_c('div',{staticClass:"app-modal__content",attrs:{"data-e2e":"modalContent"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && !_vm.loadingDone),expression:"!loading && !loadingDone"},{name:"click-outside",rawName:"v-click-outside",value:({
          handler: _vm.close,
          middleware: () => _vm.closeFromBg,
        }),expression:"{\n          handler: close,\n          middleware: () => closeFromBg,\n        }"}],staticClass:"app-modal__body"},[_c('button',{staticClass:"app-modal__close",class:{ 'app-modal__close--upper': _vm.filledClose },attrs:{"data-e2e":"closeModalButton"},on:{"click":function($event){return _vm.close()}}},[(_vm.filledClose)?_c('IconFilledClose'):_c('i',{staticClass:"icon-close"})],1),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),(_vm.loading && !_vm.loadingDone)?_c('AppModalState',{staticClass:"app-modal__state",attrs:{"data-e2e":"loadingModal"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("loading")]},proxy:true},{key:"icon",fn:function(){return [_c('Loader')]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.loadingDone)?_c('AppModalState',{staticClass:"app-modal__state",scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("loading-done")]},proxy:true},{key:"icon",fn:function(){return [_c('CheckmarkAnimated')]},proxy:true}],null,true)}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }