import Vue from 'vue'
import type { ActionTree, MutationTree } from 'vuex'

import { RootState } from '~/store'

export const namespace = 'modules/redirect'

export interface IRedirectFrom {
  name: string,
  query: Record<string, any>,
  params: Record<string, any>,
}

export interface RedirectState {
  redirectedFrom: IRedirectFrom,
  savedData: any,
}

const initialState = (): RedirectState => ({
  redirectedFrom: {
    name: '',
    query: {},
    params: {},
  },
  savedData: false,
})

export const state = (): RedirectState => initialState()

export const ActionType = {
  SET_REDIRECTED_FROM: 'SET_REDIRECTED_FROM',
  SET_SAVED_DATA: 'SET_SAVED_DATA',
  RESET_REDIRECT: 'RESET_REDIRECT',
}

export const MutationType = {
  SET_REDIRECTED_FROM: 'SET_REDIRECTED_FROM',
  SET_SAVED_DATA: 'SET_SAVED_DATA',
  RESET_REDIRECT: 'RESET_REDIRECT',
}

export const actions: ActionTree<RedirectState, RootState> = {
  [ActionType.SET_REDIRECTED_FROM] ({ commit }, route: IRedirectFrom): void {
    commit(MutationType.SET_REDIRECTED_FROM, route)
  },
  [ActionType.SET_SAVED_DATA] ({ commit }, savedData: any): void {
    commit(MutationType.SET_SAVED_DATA, savedData)
  },
  [ActionType.RESET_REDIRECT] ({ commit }): void {
    commit(MutationType.RESET_REDIRECT)
  },
}

export const mutations: MutationTree<RedirectState> = {
  [MutationType.SET_REDIRECTED_FROM] (state, route: IRedirectFrom): void {
    state.redirectedFrom = {
      name: route.name,
      query: route.query,
      params: route.params,
    }
  },
  [MutationType.SET_SAVED_DATA] (state, savedData: any): void {
    Vue.set(state, 'savedData', savedData)
  },
  [MutationType.RESET_REDIRECT] (state): void {
    Object.assign(state, initialState())
  },
}
