/* eslint-disable */

export default () => {
  /**
   * @type {ModuleOptions | undefined }
   */
  const parsedOptions = JSON.parse('{"jsUrl":"https://d1aas08s548e9j.cloudfront.net/drawer/dev/index.js","cssUrl":"https://d1aas08s548e9j.cloudfront.net/drawer/dev/index.css","iframeUrl":"https://dev.adviser.alao.ch","disabled":false}')

  /**
   * @type ModuleOptions
   */
  const options = Object.assign({}, parsedOptions)

  if (process.client) {
    (function(id) {
      if (document.getElementById(id)) {
        return
      }
      const js = document.createElement('script')
      js.id = id + '-js'
      js.src = options.jsUrl + '?v=' + Date.now()
      js.crossOrigin = ""
      js.defer = true
      js.dataset.iframeSrc = options.iframeUrl
      const fs = document.getElementsByTagName('script')[0]
      fs.parentNode.insertBefore(js, fs)

      const css = document.createElement('link')
      css.href = options.cssUrl + '?v=' + Date.now()
      css.id = id + '-css'
      css.rel = 'stylesheet'
      css.crossOrigin = ""
      const fl = document.getElementsByTagName('link')[0]
      fl.parentNode.insertBefore(css, fl)
    })('alao-assistant')
  }
}
