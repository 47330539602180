import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'
import {
  ActionType as partnerActionType,
  MutationType as partnerMutationType,
  namespace as partnerModuleNamespace,
} from '~/store/modules/Partner'
import { FeatureFlags } from '~/core/config/feature-flags'

const pageListWithQuery = [
  'order',
  'compare-mobile',
  'compare-home',
  'compare-bundle',
  'compare-mobile-slug',
  'compare-home-slug',
  'compare-bundle-slug',
]
const pageListWithSlug = [
  'partner-slug',
]

const partnerLayout: Middleware = ctx => createNuxtMiddleware(ctx, () => {
  const routeName = ctx.route.name?.split('__')[0] || ''
  let slug!: string

  if (pageListWithQuery.includes(routeName)) {
    slug = ctx.route.query.partner as string
  } else if (pageListWithSlug.includes(routeName)) {
    slug = ctx.params.slug
  } else {
    ctx.store.commit(`${partnerModuleNamespace}/${partnerMutationType.CLEAR_CURRENT_PARTNER}`)
    return
  }

  if (slug) {
    return ctx.store.dispatch(`${partnerModuleNamespace}/${ctx.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? partnerActionType.FETCH_AMP_PARTNER : partnerActionType.FETCH_PARTNER}`, {
      lang: ctx.app.i18n.locale,
      slug,
    })
  }
})

export default partnerLayout
