import { ActionTree, GetterTree, MutationTree } from 'vuex'
import isEqual from 'lodash/isEqual'
import { AmpCommonFiltersState as CommonFiltersState, Exclusivity } from '~/core/config/filters/types'
import { getInitialState } from '~/core/config/filters/amp/common.config'
import { RootState } from '~/store'

export const namespace = 'modules/Amp/Filters/Common'

export const ActionType = {
  RESET_FILTER: 'RESET_FILTER',
}

export const MutationType = {
  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',
}

export const GettersType = {
  GET_FILTERS: 'GET_FILTERS',
  IS_FILTERS_AT_INITIAL_STATE: 'IS_FILTERS_AT_INITIAL_STATE',
}

export const state = (): CommonFiltersState => getInitialState()

export const getters: GetterTree<CommonFiltersState, RootState> = {
  [GettersType.GET_FILTERS] (state: CommonFiltersState): CommonFiltersState {
    return state
  },
  [GettersType.IS_FILTERS_AT_INITIAL_STATE] (state: CommonFiltersState): boolean {
    const initState = getInitialState()
    return isEqual(initState, state)
  },
}

export const actions: ActionTree<CommonFiltersState, RootState> = {
  [ActionType.RESET_FILTER] ({ commit }): void {
    commit(MutationType.RESET_FILTER)
  },
}

export const mutations: MutationTree<CommonFiltersState> = {
  [MutationType.SET_FILTER] (state: CommonFiltersState, payload: {key: keyof typeof state, value: typeof state[keyof typeof state]}): void {
    switch (payload.key) {
      case 'offer_types': {
        state[payload.key] = payload.value as Exclusivity[]
        break
      }
      case 'special_offers': {
        state[payload.key] = payload.value
        break
      }
    }
  },
  [MutationType.RESET_FILTER] (state: CommonFiltersState): void {
    Object.assign(state, getInitialState())
  },
}
