var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.isCookieBannerHidden)?_c('div',{class:[
      'cookie-banner container',
      {
        'cookie-banner--sticky': _vm.sticky
      }
    ]},[_c('div',{staticClass:"cookie-banner__wrapper"},[(_vm.isTextEllipsis)?_c('div',{staticClass:"cookie-banner__text",domProps:{"innerHTML":_vm._s(
          _vm.$t('cookieBanner.title.short', {
            data_protection_link: _vm.localePath({
              name: 'page-slug',
              params: { slug: 'privacy-policy' }
            })
          })
        )},on:{"click":_vm.setFullText}}):_c('div',{staticClass:"cookie-banner__text",domProps:{"innerHTML":_vm._s(
          _vm.$t('cookieBanner.title.long', {
            data_protection_link: _vm.localePath({
              name: 'page-slug',
              params: { slug: 'privacy-policy' }
            })
          })
        )}}),_vm._v(" "),_c('AlaoButton',{staticClass:"cookie-banner__button",attrs:{"size":"md"},on:{"click":_vm.handleClick}},[_vm._v("\n        "+_vm._s(_vm.$t('cookieBanner.ok'))+"\n      ")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }