
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import AppCountdown from '~/components/AppCountdown'

@Component({
  components: {
    AppCountdown,
  },
})
export default class Countdown extends Vue {
  @Prop({
    required: true,
    type: Date,
  })
  readonly countToDate!: Date
}
