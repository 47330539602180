import type { ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { $api } from '~/plugins/axios'

export const namespace = 'modules/Blog'

/* eslint-disable camelcase */
interface Article {
  content_preview: string,
  image_preview_url: string,
  published_date: string,
  show_on_main_page: boolean,
  slug: string,
  title: string,
}

export interface ArticleSlugMeta {
  en: string,
  de: string,
  fr: string,
}

export interface SingleBlogArticle {
  content: string,
  image_url: string,
  image_alt?: string,
  meta_description: string,
  published_date: string,
  slug: string,
  slug_meta: ArticleSlugMeta,
  title: string,
}

export interface SingleProviderArticle {
  content: string,
  image_url: string,
  image_alt?: string,
  meta_description: string,
  pk: number,
  published_date: string,
  slug: string,
  slug_meta: ArticleSlugMeta,
  sort_order: number,
  title: string,
}

/* eslint-enable camelcase */

export interface BlogState {
  articlesList: Article[],
  count: number,
  page: number,
  perPage: number,
}

export interface FetchArticlesListPayload {
  page: string,
  lang: string,
}

export const state = (): BlogState => ({
  articlesList: [],
  count: 0,
  page: 1,
  perPage: 3,
})

export const ActionType = {
  SET_ARTICLES_PAGE: 'setArticlesPage',
  FETCH_ARTICLES_LIST: 'fetchArticlesList',
}
export const MutationType = {
  SET_ARTICLES_PAGE: 'setArticlesPage',
  SET_ARTICLES_LIST: 'setArticlesList',
  SET_ARTICLES_COUNT: 'setArticlesCount',
}

export const actions: ActionTree<BlogState, RootState> = {
  async [ActionType.FETCH_ARTICLES_LIST] ({ commit, state }) {
    const { page, perPage: limit } = state
    const offset = (page * limit) - limit

    const data = await $api.BlogService.getArticlesList({ limit, offset })

    const { results, count } = data
    commit(MutationType.SET_ARTICLES_LIST, results)
    commit(MutationType.SET_ARTICLES_COUNT, count)
  },

  [ActionType.SET_ARTICLES_PAGE] ({ commit }, page) {
    commit(MutationType.SET_ARTICLES_PAGE, page)
  },
}

export const mutations: MutationTree<BlogState> = {
  [MutationType.SET_ARTICLES_LIST] (state, articles): void {
    state.articlesList = articles
  },
  [MutationType.SET_ARTICLES_COUNT] (state, count): void {
    state.count = count
  },
  [MutationType.SET_ARTICLES_PAGE] (state, page): void {
    state.page = page
  },
}
