import { getCookie, setCookie } from '@alao-frontend/utils'
import { IGoogleRating } from '~/core/offer.types'
import { ApplicationService } from '~/services/core/application.service'

export class GeocodeService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  private readonly companyAddress: string =
    'alao.ch, Ottikerstrasse, Zürich, Switzerland'

  private readonly isCacheResults: boolean = true
  private readonly cacheResultsFor: number = 1 / 24 // 1 hour
  private readonly cacheKey: string = 'ALAO_RATINGS'

  async getRatingsAndReviews (): Promise<IGoogleRating> {
    if (this.isCacheResults) {
      const cachedResults = getCookie(this.cacheKey)

      if (cachedResults) {
        // eslint-disable-next-line no-console
        console.debug('[API] Use cached reviews')
        return JSON.parse(cachedResults)
      }
    }

    try {
      const { rating, reviews } = await this.getRatingData()

      if (this.isCacheResults) {
        const cachePayload = JSON.stringify({ rating, reviews })
        setCookie(this.cacheKey, cachePayload, { expires: this.cacheResultsFor })
      }

      return {
        rating,
        reviews,
      }
    } catch (e) {}

    return {
      rating: 0,
      reviews: 0,
    }
  }

  private getRatingData (): Promise<any> {
    return this.get('/api/google-rating/')
  }
}
