
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class OnMouseLeaveDisplay extends Vue {
  @Prop({ type: Number, default: 0 })
  timeout!: number

  @Prop({ type: Boolean, default: false })
  once!: boolean

  timer: number | null = null
  isVisible = false

  clearTimer () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  mouseLeaveHandler (event: MouseEvent) {
    if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
      this.timer = window.setTimeout(() => {
        this.isVisible = true
        this.onStateChange()
      }, this.timeout)
    }
  }

  mouseEnterHandler () {
    this.clearTimer()
  }

  attachTimerListeners () {
    document.body.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this), {
      once: this.once,
      passive: true,
    })
    document.body.addEventListener('mouseenter', this.mouseEnterHandler.bind(this), {
      once: this.once,
      passive: true,
    })
  }

  detachTimerListeners () {
    document.body.removeEventListener('mouseleave', this.mouseLeaveHandler)
    document.body.removeEventListener('mouseenter', this.mouseEnterHandler)
  }

  @Emit('change')
  onStateChange () {
    return this.isVisible
  }

  mounted () {
    if (process.client) {
      this.attachTimerListeners()
    }
  }

  beforeDestroy () {
    if (process.client) {
      this.detachTimerListeners()
      this.clearTimer()
    }
  }
}
