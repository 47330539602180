import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'

const mailRedirects: Middleware = ctx => createNuxtMiddleware(ctx, () => {
  const { route, redirect } = ctx

  if (route.path === '/en/compare/') {
    const { query, hash } = route
    const nextPath = '/en/compare/mobile/'
    const nextRoute = { path: nextPath, query, hash }
    redirect(nextRoute)
  }
})

export default mailRedirects
