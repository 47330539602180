import { FirebaseResponseItem } from '~/types/promo/firebase'
import { Campaign, CampaignDocument } from '~/types/promo/campaign'
import { parseFirebaseDocumentIdFromName } from '~/services/promo/utils'

export const campaignsConverter = (documents: FirebaseResponseItem<CampaignDocument>[]): Campaign[] => {
  const campaigns = documents.map((item) => {
    if (!item.document) {
      return null
    }

    const id = parseFirebaseDocumentIdFromName(item.document.name)

    const { dateStart, dateEnd, name } = item.document.fields

    return {
      id,
      dateStart: new Date(dateStart.timestampValue),
      dateEnd: new Date(dateEnd.timestampValue),
      name: name.stringValue,
    } as Campaign
  })

  return campaigns.filter(item => item !== null) as Campaign[]
}
