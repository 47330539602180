import type { FirebaseResponseItem } from '~/types/promo/firebase'
import type { OfferBanner, OfferBannerDocument } from '~/types/promo/offer-banner'
import { parseFirebaseDocumentIdFromName } from '~/services/promo/utils'

export const offerBannersConverter = (
  response: FirebaseResponseItem<OfferBannerDocument>[],
): OfferBanner[] => {
  const banners = response.map((item) => {
    if (!item.document) {
      return null
    }

    const id = parseFirebaseDocumentIdFromName(item.document.name)

    const {
      contentEN,
      contentDE,
      contentFR,
      offers,
      templateId,
      campaignId,
    } = item.document.fields

    return {
      id,
      templateId: templateId.stringValue,
      campaignId: campaignId.stringValue,
      contentEN: contentEN.stringValue,
      contentDE: contentDE.stringValue,
      contentFR: contentFR.stringValue,
      offers: offers.arrayValue.values.map(value => ({
        externalId: value.mapValue.fields.externalId.stringValue,
        type: value.mapValue.fields.type.stringValue,
      })),
    } as OfferBanner
  })

  return banners.filter(item => item !== null) as OfferBanner[]
}
