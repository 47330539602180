import VueI18n from 'vue-i18n'
import { gbToMb } from '@alao-frontend/filters'

export class OfferBenefitsUtils {
  constructor (
    private readonly offer: any,
    private $i18n: VueI18n,
    private readonly lang: string,
  ) {}

  get mobileCallsInEU () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      if (this.isRoamingUnlimitedCalls) {
        return this._translate('offerDetailsBenefits.unlimitedCallsInEU')
      }

      if (this.isRoamingLimitedCalls) {
        return this._translate('offerDetailsBenefits.limitedCallsInEU', {
          calls: this.offer.mobile_offer.roaming_call_minutes,
        })
      }

      if (this.isPerMinuteCallsEverywhere) {
        return this._translate('offerDetailsBenefits.perMinuteRate')
      }
    }

    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get mobileCallsFromEUToCH () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      if (this.isUnlimitedEUToCHCalls) {
        return this._translate('offerDetailsBenefits.unlimitedCallsFromEUToCH')
      }

      if (this.isLimitedEUToCHCalls) {
        return this._translate('offerDetailsBenefits.limitedCallsFromEUToCH', {
          calls: this.offer.mobile_offer.call_minutes_to_ch,
        })
      }
    }

    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get mobileCallsFromCHToEU () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      if (this.isUnlimitedCallsCHEU) {
        return this._translate('offerDetailsBenefits.unlimitedCallsInCHEU')
      }

      if (this.isUnlimitedCallsCHPlusLimitedEU) {
        return this._translate(
          'offerDetailsBenefits.unlimitedCallsCHPlusLimitedEU',
          { minutes: this.offer.mobile_offer.call_minutes_to_eu },
        )
      }

      if (this.isPerMinuteCallsEverywhere) {
        return this._translate('offerDetailsBenefits.perMinuteRate')
      }
    }

    return this._translate('offerDetailsBenefits.notIncluded')
  }

  // TODO: Needs some refactoring
  get mobileInternetInCH () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      if (this.isUnlimitedUltraHighSpeedData) {
        return this._translate(
          'offerDetailsBenefits.unlimitedUltraHighSpeedData',
        )
      }

      if (this.isUnlimitedHighSpeedData) {
        return this._translate('offerDetailsBenefits.unlimitedHighSpeedData')
      }

      if (this.isUnlimitedMediumSpeedData) {
        return this._translate('offerDetailsBenefits.unlimitedMediumSpeedData')
      }

      if (this.isUnlimitedLowSpeedData) {
        return this._translate('offerDetailsBenefits.unlimitedLowSpeedData')
      }

      if (
        this.isLimitedHighSpeedData &&
        Number(this.offer.mobile_offer.data) >= 1
      ) {
        return this._translate('offerDetailsBenefits.gbHighSpeedData', {
          data: Number(this.offer.mobile_offer.data),
        })
      }

      if (
        this.isLimitedHighSpeedData &&
        Number(this.offer.mobile_offer.data) < 1
      ) {
        return this._translate('offerDetailsBenefits.mbHighSpeedData', {
          data: gbToMb(Number(this.offer.mobile_offer.data)),
        })
      }

      if (
        this.isLimitedMediumSpeedData &&
        Number(this.offer.mobile_offer.data) >= 1
      ) {
        return this._translate('offerDetailsBenefits.gbMediumSpeedData', {
          data: Number(this.offer.mobile_offer.data),
        })
      }

      if (
        this.isLimitedMediumSpeedData &&
        Number(this.offer.mobile_offer.data) < 1
      ) {
        return this._translate('offerDetailsBenefits.mbMediumSpeedData', {
          data: gbToMb(Number(this.offer.mobile_offer.data)),
        })
      }

      if (
        this.isLimitedLowSpeedData &&
        Number(this.offer.mobile_offer.data) >= 1
      ) {
        return this._translate('offerDetailsBenefits.gbLowSpeedData', {
          data: Number(this.offer.mobile_offer.data),
        })
      }

      if (
        this.isLimitedLowSpeedData &&
        Number(this.offer.mobile_offer.data) < 1
      ) {
        return this._translate('offerDetailsBenefits.mbLowSpeedData', {
          data: gbToMb(Number(this.offer.mobile_offer.data)),
        })
      }

      if (this.isSharedData) {
        return this._translate('offerDetailsBenefits.gbHighSpeedDataCHEU', {
          data: Number(this.offer.mobile_offer.shared_data),
        })
      }
    }

    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get mobileInternetInEU () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      if (this.isRoamingUnlimitedData) {
        return this._translate('offerDetailsBenefits.unlimitedRoamingDataInEU')
      }

      if (this.isRoamingDataInMB) {
        return this._translate('offerDetailsBenefits.roamingDataMB', {
          data: gbToMb(Number(this.offer.mobile_offer.roaming_data)),
        })
      }

      if (this.isRoamingDataInGB) {
        return this._translate('offerDetailsBenefits.roamingInEU', {
          data: Number(this.offer.mobile_offer.roaming_data),
        })
      }

      if (this.isSharedData) {
        return this._translate('offerDetailsBenefits.gbHighSpeedDataCHEU', {
          data: Number(this.offer.mobile_offer.shared_data),
        })
      }
    }

    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get mobileNetworkType () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      return this.offer.mobile_offer.network_type
        .split(',')
        .map((item: string) => item.toUpperCase())
    }

    return ''
  }

  get mobileContractDuration () {
    if (this.isMobile || this.isBundle || this.isDevice) {
      return this._translate('offerDetailsBenefits.contractLength', {
        months: this.offer.mobile_offer.contract_duration,
      })
    }

    return ''
  }

  get homeCallsFromCHToEU () {
    if (this.isHome || this.isBundle) {
      if (this.isUnlimitedLandlineCallsCHEU) {
        return this._translate(
          'offerDetailsBenefits.unlimitedCallsLandlineInCHEU',
        )
      }

      if (this.isLimitedLandlineCalls) {
        return this._translate('offerDetailsBenefits.perMinuteRateLandline')
      }
    }
    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get homeInternet () {
    if (this.isHome || this.isBundle) {
      if (this.isInternet) {
        return this._translate('offerDetailsBenefits.internetSpeed', {
          speed: this.offer.home_offer.internet_speed,
        })
      }
    }
    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get homeTV () {
    if (this.isHome || this.isBundle) {
      if (this.isTVChannels && !this.isTVChannelsHD) {
        return this._translate('offerDetailsBenefits.tvChannels', {
          channels: this.offer.home_offer.tv_channels,
        })
      }

      if (this.isTVChannels && this.isTVChannelsHD) {
        return this._translate('offerDetailsBenefits.tvChannelsHD', {
          channels: this.offer.home_offer.tv_channels,
          HDChannels: this.offer.home_offer.tv_channels_hd,
        })
      }
    }
    return this._translate('offerDetailsBenefits.notIncluded')
  }

  get homeContractDuration () {
    if (this.isHome || this.isBundle) {
      return this._translate('offerDetailsBenefits.contractLength', {
        months: this.offer.home_offer.contract_duration,
      })
    }

    return ''
  }

  get homeInternetInEU () {
    return this._translate('offerDetailsBenefits.notIncluded')
  }

  _translate (key = '', payload?: { [k: string]: any }) {
    return this.$i18n.t(key, this.lang, payload)
  }

  get isMobile () {
    return Boolean(
      this.offer.mobile_offer &&
        !this.offer.device_offer &&
        !this.offer.home_offer,
    )
  }

  get isHome () {
    return Boolean(!this.offer.mobile_offer && this.offer.home_offer)
  }

  get isBundle () {
    return Boolean(this.offer.mobile_offer && this.offer.home_offer)
  }

  get isDevice () {
    return Boolean(this.offer.mobile_offer && this.offer.device_offer)
  }

  get getSumOfDatas () {
    let { data, shared_data: sharedData } = this.offer.mobile_offer
    data = data || 0
    sharedData = sharedData || 0

    return data + sharedData
  }

  /* --------  Great for  -------- */
  get isGreatForWhatsApp () {
    if (this.isDevice) {
      return false
    }

    return this.offer.data_speed_type === 'LOW'
  }

  get isGreatForSocialMedia () {
    if (this.isDevice) {
      return false
    }

    const data = this.getSumOfDatas
    return (
      data > 1.5 &&
      data <= 3.5 &&
      this.offer.mobile_offer.data_speed_type === 'HIGH'
    )
  }

  get isGreatForGamesAndNetflix () {
    if (this.isDevice) {
      return false
    }

    const data = this.getSumOfDatas
    return data > 3.5 && this.offer.mobile_offer.data_speed_type === 'HIGH'
  }

  /* --------  Data  -------- */
  // Unlimited
  get isUnlimitedData () {
    return Number(this.offer.mobile_offer.data) === 9999
  }

  get isUnlimitedUltraHighSpeedData () {
    return (
      this.isUnlimitedData &&
      this.offer.mobile_offer.data_speed_type === 'ULTRA_HIGH_5G'
    )
  }

  get isUnlimitedHighSpeedData () {
    return (
      this.isUnlimitedData && this.offer.mobile_offer.data_speed_type === 'HIGH'
    )
  }

  get isUnlimitedMediumSpeedData () {
    return (
      this.isUnlimitedData &&
      this.offer.mobile_offer.data_speed_type === 'MEDIUM'
    )
  }

  get isUnlimitedLowSpeedData () {
    return (
      this.isUnlimitedData && this.offer.mobile_offer.data_speed_type === 'LOW'
    )
  }

  // Limited
  get isLimitedHighSpeedData () {
    return (
      !this.isUnlimitedData &&
      this.offer.mobile_offer.data !== null &&
      this.offer.mobile_offer.data_speed_type === 'HIGH'
    )
  }

  get isLimitedMediumSpeedData () {
    return (
      !this.isUnlimitedData &&
      this.offer.mobile_offer.data !== null &&
      this.offer.mobile_offer.data_speed_type === 'MEDIUM'
    )
  }

  get isLimitedLowSpeedData () {
    return (
      !this.isUnlimitedData &&
      this.offer.mobile_offer.data !== null &&
      this.offer.mobile_offer.data_speed_type === 'LOW'
    )
  }

  // Shared
  get isSharedData () {
    return this.offer.mobile_offer.shared_data
  }

  /* --------  Roaming data  -------- */
  // Helpers
  get isRoamingData () {
    return this.offer.mobile_offer.roaming_data
  }

  get isRoamingDataInMB () {
    return (
      this.isRoamingData &&
      Number(this.offer.mobile_offer.roaming_data) < 1
    )
  }

  get isRoamingDataInGB () {
    return (
      this.isRoamingData &&
      Number(this.offer.mobile_offer.roaming_data) >= 1 &&
      Number(this.offer.mobile_offer.roaming_data) < 9999
    )
  }

  get isRoamingUnlimitedData () {
    return (
      this.isRoamingData &&
      Number(this.offer.mobile_offer.roaming_data) === 9999
    )
  }

  /* --------  Calls  -------- */
  // Helpers
  get isUnlimitedCallsCH () {
    return this.offer.mobile_offer.call_options === 'unlimited_ch'
  }

  get isLimitedCalls () {
    return this.offer.mobile_offer.call_options === 'per_minute'
  }

  //
  get isUnlimitedCallsCHEU () {
    return this.offer.mobile_offer.call_options === 'unlimited_ch_eu'
  }

  get isUnlimitedCallsCHOnly () {
    return (
      this.isUnlimitedCallsCH && !this.offer.mobile_offer.call_minutes_to_eu
    )
  }

  // Unlimited CH + Limited EU
  get isUnlimitedCallsCHPlusLimitedEU () {
    return this.isUnlimitedCallsCH && this.offer.mobile_offer.call_minutes_to_eu
  }

  get isLimitedCallsCH () {
    return this.isLimitedCalls && this.offer.mobile_offer.call_minutes_to_ch
  }

  get isPerMinuteCallsEverywhere () {
    return this.isLimitedCalls && !this.offer.mobile_offer.call_minutes_to_ch
  }

  get isUnlimitedEUToCHCalls () {
    return this.offer.mobile_offer.call_minutes_to_ch && this.offer.mobile_offer.call_minutes_to_ch === 9999
  }

  get isLimitedEUToCHCalls () {
    return this.offer.mobile_offer.call_minutes_to_ch && this.offer.mobile_offer.call_minutes_to_ch < 9999
  }

  /* --------  Roaming calls  -------- */
  // Helpers
  get isRoamingCalls () {
    return this.offer.mobile_offer.roaming_call_minutes
  }

  get isRoamingUnlimitedCalls () {
    return (
      this.isRoamingCalls &&
      this.offer.mobile_offer.roaming_call_minutes === 9999
    )
  }

  get isRoamingLimitedCalls () {
    return (
      this.isRoamingCalls &&
      this.offer.mobile_offer.roaming_call_minutes < 9999
    )
  }

  get isFeaturesExist () {
    return (
      this.offer.mobile_offer.features &&
      this.offer.mobile_offer.features.length > 0
    )
  }

  get isTVAppNeoFeature () {
    return (
      this.isFeaturesExist &&
      this.offer.mobile_offer.features.includes('tv_app_neo')
    )
  }

  get isTVAppNeoLightFeature () {
    return (
      this.isFeaturesExist &&
      this.offer.mobile_offer.features.includes('tv_app_neo_light')
    )
  }

  /**
   *
   * Home offers
   */
  get isInternet () {
    return this.offer.home_offer.internet_speed
  }

  get isTVChannels () {
    return this.offer.home_offer.tv_channels
  }

  get isTVChannelsHD () {
    return this.offer.home_offer.tv_channels_hd
  }

  get isUnlimitedLandlineCallsCH () {
    return this.offer.home_offer.landline === 'unlimited_ch'
  }

  get isLimitedLandlineCalls () {
    return this.offer.home_offer.landline === 'per_minute'
  }

  get isUnlimitedLandlineCallsCHEU () {
    return this.offer.home_offer.landline === 'unlimited_ch_eu'
  }

  get isSMSExist (): boolean {
    return Boolean(this.offer.mobile_offer?.sms)
  }

  get isMMSExist (): boolean {
    return Boolean(this.offer.mobile_offer?.mms)
  }

  get sms (): Record<'value' | 'name' | 'fullName', string | number | null>[] {
    return [
      {
        value: this.offer.mobile_offer?.sms?.at_home,
        name: this.$i18n.t('offerDetailsBenefits.textMessages.inCH') as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.sms.inCH',
        ) as string,
      },
      {
        value: this.offer.mobile_offer?.sms?.at_europe,
        name: this.$i18n.t('offerDetailsBenefits.textMessages.inEU') as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.sms.inEU',
        ) as string,
      },
      {
        value: this.offer.mobile_offer?.sms?.from_europe_to_home,
        name: this.$i18n.t(
          'offerDetailsBenefits.textMessages.EUtoCH',
        ) as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.sms.EUtoCH',
        ) as string,
      },
      {
        value: this.offer.mobile_offer?.sms?.from_home_to_europe,
        name: this.$i18n.t(
          'offerDetailsBenefits.textMessages.CHtoEU',
        ) as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.sms.CHtoEU',
        ) as string,
      },
    ]
  }

  get mms (): Record<'value' | 'name' | 'fullName', string | number | null>[] {
    return [
      {
        value: this.offer.mobile_offer?.mms?.at_home,
        name: this.$i18n.t('offerDetailsBenefits.textMessages.inCH') as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.mms.inCH',
        ) as string,
      },
      {
        value: this.offer.mobile_offer?.mms?.at_europe,
        name: this.$i18n.t('offerDetailsBenefits.textMessages.inEU') as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.mms.inEU',
        ) as string,
      },
      {
        value: this.offer.mobile_offer?.mms?.from_europe_to_home,
        name: this.$i18n.t(
          'offerDetailsBenefits.textMessages.EUtoCH',
        ) as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.mms.EUtoCH',
        ) as string,
      },
      {
        value: this.offer.mobile_offer?.mms?.from_home_to_europe,
        name: this.$i18n.t(
          'offerDetailsBenefits.textMessages.CHtoEU',
        ) as string,
        fullName: this.$i18n.t(
          'offerDetailsBenefits.textMessages.mms.CHtoEU',
        ) as string,
      },
    ]
  }
}
