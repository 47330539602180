import { v4 as uuidv4 } from 'uuid'

import { BaseAnalytics } from '../base-analytics'
import { $api } from '~/plugins/axios'
import {
  ActionSource,
  BaseEventPayload,
  CustomEventName,
  CustomEventPayload,
  EventType,
  OrderStepsEventName,
  OrderStepsPayload,
  PurchaseEventPayload,
  SSTEventData,
  SSTUserData,
  StandardEventName,
  StandardEventPayload,
} from '~/plugins/analytics/facebook/types'
import { getProperty, setProperty } from '~/utils'

type FacebookAnalyticsOptions = {
  testEventCode?: string,
}

export class FacebookAnalytics extends BaseAnalytics {
  private userData: SSTUserData = {}

  constructor (protected provider: any, private options: FacebookAnalyticsOptions = {}) {
    super(provider)
  }

  private generateEventID (eventName: StandardEventName | CustomEventName): string {
    return uuidv4()
  }

  private getEventTime (): number {
    return Math.floor(Date.now() / 1000)
  }

  private prepareSSTEventPayload (
    eventName: StandardEventName | CustomEventName,
    eventId: string,
    payload?: StandardEventPayload | CustomEventPayload,
  ): SSTEventData {
    const eventTime = this.getEventTime()
    const testEventCode = this.options.testEventCode

    const eventPayload: SSTEventData = {
      event_name: eventName,
      event_id: eventId,
      event_time: eventTime,
      event_source_url: location.href,
      action_source: ActionSource,
      payload: {},
    }

    if (testEventCode) {
      eventPayload.payload.test_event_code = testEventCode
    }

    if (payload) {
      eventPayload.payload.custom_data = payload
    }

    if (this.isUserDataDefined) {
      eventPayload.payload.user_data = this.userData
    }

    return eventPayload
  }

  get isUserDataDefined () {
    return Object.keys(this.userData).length > 0
  }

  setUserData (data: SSTUserData, replace = true): void {
    if (replace) {
      this.userData = data
      return
    }

    for (const _key in data) {
      const key = (_key as keyof typeof data)
      const value = getProperty(data, key)

      setProperty(this.userData, key, value)
    }
  }

  async push (
    eventType: EventType,
    eventName: StandardEventName | CustomEventName,
    payload?: StandardEventPayload | CustomEventPayload,
  ): Promise<void> {
    const eventId = this.generateEventID(eventName)

    const sstEventPayload = this.prepareSSTEventPayload(eventName, eventId, payload)

    try {
      this.provider.fbq(eventType, eventName, payload, { eventID: eventId })
      await $api.TrackerService.pushFacebookEvent(sstEventPayload)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  /**
   * Send a custom event into FB
   *
   * @param {String} eventName
   * @param {Object} payload
   * @returns {void}
   */
  trackCustomEvent (eventName: CustomEventName, payload: CustomEventPayload): void {
    this.push(EventType.TRACK_CUSTOM, eventName, payload)
  }

  /**
   * Send a standard event into FB
   *
   * @param {String} eventName
   * @param {Object} payload
   * @returns {void}
   */
  trackEvent (
    eventName: StandardEventName,
    payload: StandardEventPayload,
  ): void {
    this.push(EventType.TRACK, eventName, payload)
  }

  sendOrderStepsEvent (
    eventName: OrderStepsEventName,
    payload: OrderStepsPayload,
  ): void {
    this.trackCustomEvent(eventName, payload)
  }

  sendViewContentEvent (payload: BaseEventPayload) {
    this.trackEvent(StandardEventName.VIEW_CONTENT, payload)
  }

  sendAddToCartEvent (payload: BaseEventPayload) {
    this.trackEvent(StandardEventName.ADD_TO_CART, payload)
  }

  sendPurchaseEvent (payload: PurchaseEventPayload) {
    this.trackEvent(StandardEventName.PURCHASE, payload)
  }

  sendFraudPreventionEvent (payload: OrderStepsPayload) {
    this.sendOrderStepsEvent(OrderStepsEventName.FRAUD_PREVENTION_MODAL_VIEW, payload)
  }

  sendSignUpEvent (payload: any) {
    this.trackEvent(StandardEventName.LEAD_ALAO_PLUS, payload)
  }

  sendLeadEvent (payload?: any) {
    this.trackEvent(StandardEventName.LEAD, payload)
  }
}
