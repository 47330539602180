
import { Vue, Component } from 'nuxt-property-decorator'
import { OnBeforeDestroy, OnMounted } from '~/core/vue.types'

@Component
export default class AppOfflineBar extends Vue implements OnMounted, OnBeforeDestroy {
  private isOnline = true

  updateOnlineStatus () {
    this.isOnline = navigator.onLine
  }

  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }

  mounted () {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  }
}
