import { Component, Vue } from 'nuxt-property-decorator'
import type { MetaInfo } from 'vue-meta'
import { LinkPropertyBase, LinkPropertyHref, LinkPropertyHrefCallback } from 'vue-meta/types/vue-meta'
import { WithHead } from '~/core/vue.types'

enum UserType {
  AUTHORIZED = 'alao_plus_user',
  DEFAULT = 'default_user',
}

@Component
export default class SeoLayoutMixin extends Vue implements WithHead {
  head (): MetaInfo {
    const { link }: MetaInfo = this.$nuxtI18nHead({
      addSeoAttributes: true,
      addDirAttribute: true,
    })

    const alternateLinks = (link || []).filter((item: (LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback)) => {
      return item.hreflang !== 'en' && item.hreflang !== 'de' && item.hreflang !== 'fr' && item.rel !== 'canonical'
    })

    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          hid: 'data-layer-init',
          innerHTML: 'window.dataLayer = window.dataLayer || []',
          type: 'text/javascript',
        },
        {
          hid: 'data-layer-lang',
          innerHTML: `window.dataLayer.push({
            language_code: '${this.$i18n.locale.toUpperCase()}',
            user_type: '${UserType.DEFAULT}',
          })`,
          type: 'text/javascript',
        },
      ],

      // Just a hack to this https://github.com/nuxt/nuxt.js/issues/226
      __dangerouslyDisableSanitizers: ['script'],

      link: [
        ...alternateLinks as any[],
      ],

      bodyAttrs: {
        class: this.$exp.classes.join(' '),
      },
    }
  }
}
