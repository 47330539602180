import Vue from 'vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate, {
  "errorBagName": "vErrors"
})

export default ({ app }, inject) => {
  app.validator = VeeValidate.Validator
}
