
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { HTMLString } from '@alao-frontend/types'
import { NotificationType } from './types'

@Component({
  components: {},
})
export default class AlaoNotificationItem extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  readonly id!: string

  @Prop({
    default: 0,
    type: Number,
  })
  readonly duration!: number

  @Prop({
    default: NotificationType.ERROR,
    type: String,
  })
  readonly type!: string

  @Prop({
    required: true,
    type: String,
  })
  readonly title!: string

  @Prop({
    required: true,
    type: String,
  })
  readonly message!: HTMLString

  private timeout!: number

  @Emit('on-close')
  onClose () {
    return this.id
  }

  runTimer () {
    if (this.duration <= 0) {
      return
    }

    this.timeout = window.setTimeout(this.onClose, this.duration)
  }

  stopTimer () {
    if (!this.timeout) {
      return
    }

    clearTimeout(this.timeout)
  }

  onMouseOver () {
    this.stopTimer()
  }

  onMouseLeave () {
    this.runTimer()
  }

  beforeDestroy () {
    this.stopTimer()
  }

  mounted () {
    this.runTimer()
  }
}
