import { QuizAction } from '~/core/config/comparator/quiz/interfaces/enums'
import { QuizAnswer } from '~/core/config/comparator/quiz/interfaces/types'
import { CommonFiltersState, HomeFiltersState, MobileFiltersState } from '~/core/config/filters/types'
import { ValueOf } from '~/core/offer.types'

const unpackFilters = <T extends Record<string, any>>(filters: T, keys: string[], { modifier, modifiableKeys }: { modifier: string, modifiableKeys: string[] } = { modifier: '', modifiableKeys: [] }): Partial<T> => {
  const newFilters: T = { ...filters }

  keys.forEach((key) => {
    const item = newFilters[key].reduce((acc: Record<string, boolean>, item: string) => {
      const newKey = modifiableKeys.includes(key) ? modifier + '_' + item : item
      acc[newKey] = true
      return acc
    }, {})

    Object.assign(newFilters, item)
    delete newFilters[key]
  })

  return newFilters
}

export const quizAnswerToFilter = (actions: QuizAnswer['action'][], amp?: boolean) => {
  type FilterAnswer<Type> = ValueOf<{
    [Property in keyof Type]: { name: Property, payload: Type[Property]};
  }>

  const homeFilterAnswers: FilterAnswer<HomeFiltersState>[] = []
  const mobileFilterAnswers: FilterAnswer<MobileFiltersState>[] = []

  actions.forEach((action: any) => {
    if (action.type === QuizAction.DISPATCH) {
      switch (action.payload) {
        case 'internetAndTV':
          if (amp) {
            homeFilterAnswers.push({ name: 'tv_options', payload: ['has_tv_channels', 'has_tv_channels_hd'] })
          } else {
            homeFilterAnswers.push({ name: 'tv_options', payload: ['frequent_tv', 'extra_tv', 'netflix'] })
          }
          break
        case 'internetTVMobile':
          if (amp) {
            homeFilterAnswers.push({ name: 'tv_options', payload: ['has_tv_channels', 'has_tv_channels_hd'] })
          } else {
            homeFilterAnswers.push({ name: 'tv_options', payload: ['frequent_tv', 'extra_tv', 'netflix'] })
          }
          break
        case 'callsCHToEULandline':
          if (amp) {
            homeFilterAnswers.push({ name: 'tv_options', payload: ['has_tv_channels', 'has_tv_channels_hd'] })
          } else {
            homeFilterAnswers.push({ name: 'tv_options', payload: ['frequent_tv', 'extra_tv', 'netflix'] })
          }
          homeFilterAnswers.push({ name: 'home_call_options', payload: ['unlimited_calls_in_ch', 'unlimited_calls_from_ch_to_eu'] })
          break
        case 'unlimited_calls_in_ch':
          mobileFilterAnswers.push({ name: 'data_options', payload: ['unlimited_data_in_ch'] })
          break
        case 'calls_in_eu':
          mobileFilterAnswers.push({ name: 'data_options', payload: ['unlimited_data_in_ch', 'some_data_in_eu'] })
          break
        case 'unlimited_calls_from_ch_to_eu':
          mobileFilterAnswers.push({ name: 'call_options', payload: ['unlimited_calls_in_ch', 'unlimited_calls_from_ch_to_eu'] })
          break
      }
    }
  })

  return {
    home: homeFilterAnswers,
    mobile: mobileFilterAnswers,
  }
}

export const homeFiltersToRequest = (filters: HomeFiltersState, amp?: boolean) => {
  if (amp) {
    const keys = ['data_speed', 'home_call_options', 'tv_options']
    const modifiableKeys = ['home_call_options']
    const unpackedFilters = unpackFilters(filters, keys, { modifier: 'home', modifiableKeys })
    delete unpackedFilters.providers

    return {
      home_provider_slugs: filters.providers,
      ...unpackedFilters,
    }
  } else {
    return {
      home_providers: filters.providers,
      home_special_offers: filters.special_offers,
      ...filters,
    }
  }
}

export const mobileFiltersToRequest = (filters: MobileFiltersState, amp?: boolean) => {
  if (amp) {
    const keys = ['data_options', 'call_options']
    const modifiableKeys = ['call_options']
    const unpackedFilters = unpackFilters(filters, keys, { modifier: 'mobile', modifiableKeys })
    delete unpackedFilters.providers

    return {
      mobile_provider_slugs: filters.providers,
      ...unpackedFilters,
    }
  } else {
    return {
      mobile_providers: filters.providers,
      mobile_special_offers: filters.special_offers,
      ...filters,
    }
  }
}

export const commonFiltersToRequest = (filters: CommonFiltersState, amp?: boolean) => {
  if (amp) {
    const keys = ['special_offers']
    const unpackedFilters = unpackFilters(filters, keys)
    return {
      ...unpackedFilters,
      offer_types: filters.offer_types,
    }
  } else {
    return filters
  }
}
