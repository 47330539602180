import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { OfferCategoryType } from '@alao-frontend/core'
import { RootState } from '~/store'
import { $api } from '~/plugins/axios'
import type { Campaign } from '~/types/promo/campaign'
import type { OfferBanner } from '~/types/promo/offer-banner'
import { OfferBannerTemplate } from '~/types/promo/offer-banner-template'
import { PageBanner } from '~/types/promo/page-banner'
import { PageBannerTemplate } from '~/types/promo/page-banner-template'

export const namespace = 'modules/PromoCampaign'

export interface PromoCampaignState {
  // Active campaigns only
  campaigns: Campaign[],
  offerBanners: OfferBanner[],
  offerBannerTemplates: OfferBannerTemplate[],
  pageBanners: PageBanner[],
  pageBannerTemplates: PageBannerTemplate[],
  loading: boolean,
}

export enum ActionType {
  FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS',
  FETCH_OFFER_BANNERS = 'FETCH_OFFER_BANNERS',
  FETCH_OFFER_BANNER_TEMPLATES = 'FETCH_OFFER_BANNER_TEMPLATES',
  FETCH_PAGE_BANNERS = 'FETCH_PAGE_BANNERS',
  FETCH_PAGE_BANNER_TEMPLATES = 'FETCH_PAGE_BANNER_TEMPLATES',
}

export interface PublicAction {
  [ActionType.FETCH_CAMPAIGNS] (): Promise<void>,
}

export enum MutationType {
  SET_CAMPAIGNS = 'SET_CAMPAIGNS',
  SET_OFFER_BANNERS = 'SET_OFFER_BANNERS',
  SET_OFFER_BANNER_TEMPLATES = 'SET_OFFER_BANNER_TEMPLATES',
  SET_PAGE_BANNERS = 'SET_PAGE_BANNERS',
  SET_PAGE_BANNER_TEMPLATES = 'SET_PAGE_BANNER_TEMPLATES',
}

export enum GetterType {
  GET_CAMPAIGN_BY_ID = 'GET_CAMPAIGN_BY_ID',
  GET_OFFER_BANNERS = 'GET_OFFER_BANNERS',
  GET_OFFER_BANNER_TEMPLATE = 'GET_OFFER_BANNER_TEMPLATE',
  GET_PAGE_BANNERS = 'GET_PAGE_BANNERS',
  GET_PAGE_BANNER_TEMPLATE = 'GET_PAGE_BANNER_TEMPLATE',
}

export interface PublicGetters {
  [GetterType.GET_CAMPAIGN_BY_ID] (campaignId: string): Campaign | undefined,
  [GetterType.GET_OFFER_BANNERS] (offerType: OfferCategoryType, offerId: string): OfferBanner[] | undefined,
  [GetterType.GET_OFFER_BANNER_TEMPLATE] (templateId: string): OfferBannerTemplate | undefined,
  [GetterType.GET_PAGE_BANNERS] (): PageBanner[] | undefined,
  [GetterType.GET_PAGE_BANNER_TEMPLATE] (templateId: string): PageBannerTemplate | undefined,
}

export const state = (): PromoCampaignState => ({
  campaigns: [],
  offerBanners: [],
  offerBannerTemplates: [],
  pageBanners: [],
  pageBannerTemplates: [],
  loading: false,
})

export const getters: GetterTree<PromoCampaignState, RootState> = {
  [GetterType.GET_CAMPAIGN_BY_ID] (state): PublicGetters[GetterType.GET_CAMPAIGN_BY_ID] {
    return (campaignId: string) => {
      return state.campaigns.find(c => c.id === campaignId)
    }
  },
  [GetterType.GET_OFFER_BANNERS] (state): PublicGetters[GetterType.GET_OFFER_BANNERS] {
    return (offerType: OfferCategoryType, offerId: string) => {
      return state.offerBanners.filter(banner => banner.offers.some(offer => offer.type === offerType && offer.externalId === offerId))
    }
  },
  [GetterType.GET_OFFER_BANNER_TEMPLATE] (state): PublicGetters[GetterType.GET_OFFER_BANNER_TEMPLATE] {
    return (templateId) => {
      return state.offerBannerTemplates.find(template => template.id === templateId)
    }
  },
  [GetterType.GET_PAGE_BANNERS] (state): PublicGetters[GetterType.GET_PAGE_BANNERS] {
    // Later it's going to accept params to filter by page path
    return () => {
      return state.pageBanners
    }
  },
  [GetterType.GET_PAGE_BANNER_TEMPLATE] (state): PublicGetters[GetterType.GET_PAGE_BANNER_TEMPLATE] {
    return (templateId) => {
      return state.pageBannerTemplates.find(template => template.id === templateId)
    }
  },
}

export const actions: ActionTree<PromoCampaignState, RootState> = {
  async [ActionType.FETCH_CAMPAIGNS] ({ commit, dispatch }): ReturnType<PublicAction[ActionType.FETCH_CAMPAIGNS]> {
    const campaigns = await $api.PromoService.activeCampaignsList()

    commit(MutationType.SET_CAMPAIGNS, campaigns)

    if (campaigns.length) {
      await Promise.all([
        dispatch(ActionType.FETCH_OFFER_BANNERS),
        dispatch(ActionType.FETCH_PAGE_BANNERS),
      ])
    }
  },

  async [ActionType.FETCH_OFFER_BANNERS] ({ commit, dispatch, state }): Promise<void> {
    // Fetch offer banners for each campaign
    const campaignIds = state.campaigns.map(c => c.id)
    const promises = campaignIds.map((id) => {
      return $api.PromoService.offerBannersList(id)
    })

    const offerBanners = (await Promise.all(promises)).reduce((acc, curr) => acc.concat(curr), [])

    commit(MutationType.SET_OFFER_BANNERS, offerBanners)

    if (offerBanners.length) {
      await dispatch(ActionType.FETCH_OFFER_BANNER_TEMPLATES)
    }
  },

  async [ActionType.FETCH_OFFER_BANNER_TEMPLATES] ({ commit, state }): Promise<void> {
    // Fetch offer banners for each campaign
    const templateIds = state.offerBanners.map(b => b.templateId)
    const promises = templateIds.map((id) => {
      return $api.PromoService.offerBannerTemplate(id)
    })

    const templates = await Promise.all(promises)

    commit(MutationType.SET_OFFER_BANNER_TEMPLATES, templates)
  },

  async [ActionType.FETCH_PAGE_BANNERS] ({ commit, dispatch, state }): Promise<void> {
    // Fetch offer banners for each campaign
    const campaignIds = state.campaigns.map(c => c.id)
    const promises = campaignIds.map((id) => {
      return $api.PromoService.pageBannersList(id)
    })

    const banners = (await Promise.all(promises)).reduce((acc, curr) => acc.concat(curr), [])

    commit(MutationType.SET_PAGE_BANNERS, banners)

    if (banners.length) {
      await dispatch(ActionType.FETCH_PAGE_BANNER_TEMPLATES)
    }
  },

  async [ActionType.FETCH_PAGE_BANNER_TEMPLATES] ({ commit, state }): Promise<void> {
    // Fetch offer banners for each campaign
    const templateIds = state.pageBanners.map(b => b.templateId)
    const promises = templateIds.map((id) => {
      return $api.PromoService.pageBannerTemplate(id)
    })

    const templates = await Promise.all(promises)

    commit(MutationType.SET_PAGE_BANNER_TEMPLATES, templates)
  },
}

export const mutations: MutationTree<PromoCampaignState> = {
  [MutationType.SET_CAMPAIGNS] (
    state: PromoCampaignState,
    campaigns: Campaign[],
  ) {
    state.campaigns = campaigns
  },

  [MutationType.SET_OFFER_BANNERS] (
    state: PromoCampaignState,
    banners: OfferBanner[],
  ) {
    state.offerBanners = banners
  },

  [MutationType.SET_OFFER_BANNER_TEMPLATES] (
    state: PromoCampaignState,
    templates: OfferBannerTemplate[],
  ) {
    state.offerBannerTemplates = templates
  },

  [MutationType.SET_PAGE_BANNERS] (
    state: PromoCampaignState,
    banners: PageBanner[],
  ) {
    state.pageBanners = banners
  },

  [MutationType.SET_PAGE_BANNER_TEMPLATES] (
    state: PromoCampaignState,
    templates: PageBannerTemplate[],
  ) {
    state.pageBannerTemplates = templates
  },
}
