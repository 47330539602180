import { Context } from '@nuxt/types'
import { EmarsysWebExtend } from '@alao-frontend/emarsys-web-extend.module'
import { createLogger } from '@alao-frontend/utils'
import { BaseAnalytics } from '~/plugins/analytics/base-analytics'
import { EmarsysCategoryByOfferType, EmarsysCommands } from '~/plugins/analytics/emarsys/types'
import { Debounce } from '~/utils/decorators/debounce'
import { OfferTypeKey } from '~/core/offer.types'

export interface EmarsysItem {
  item: string,
  price: number,
  quantity: number,
}

export interface EmarsysPurchaseDescriptor {
  orderId: string,
  items: EmarsysItem[],
}

export class EmarsysAnalytics extends BaseAnalytics {
  initialized = false
  readonly logger = createLogger('Emarsys')

  constructor (protected provider: EmarsysWebExtend, protected nuxtContext: Context) {
    super(provider)
  }

  init () {
    if (this.initialized) {
      return
    }

    if (this.provider.options.testMode) {
      this.push(EmarsysCommands.TEST_MODE)
      this.logger.info('Emarsys on test mode')
    }

    this.initialized = true
  }

  private push (command: EmarsysCommands, ...rest: any[]) {
    this.provider.ScarabQueue.push([command, ...rest])
  }

  setEmail (email: string) {
    if (!email) {
      throw new Error('email is required')
    }

    this.push(EmarsysCommands.SET_EMAIL, email)
    this.goWithCart()
  }

  cart (items: EmarsysItem[] = []) {
    this.push(EmarsysCommands.CART, items)
  }

  go () {
    this.push(EmarsysCommands.GO)
  }

  @Debounce(500)
  goWithCart (items?: EmarsysItem[]) {
    this.cart(items)
    this.go()
  }

  view (itemId: EmarsysItem['item']) {
    this.push(EmarsysCommands.VIEW, itemId.toString())
    this.goWithCart()
  }

  category (offerType: OfferTypeKey) {
    this.push(EmarsysCommands.CATEGORY, EmarsysCategoryByOfferType[offerType])
    this.goWithCart()
  }

  purchase (descriptor: EmarsysPurchaseDescriptor) {
    this.push(EmarsysCommands.PURCHASE, descriptor)
    this.goWithCart()
  }
}
