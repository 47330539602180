import {
  AnalyticsVPVOfferTypes,
  OSPPageViewEventMeta,
  PageViewEventPayload,
  TrackEventOptions,
  WithEventProperty,
} from '~/plugins/analytics/types'
import { GATrackEvent } from '~/plugins/analytics/events/ga.event'

export class OSPPageViewEvent extends GATrackEvent<PageViewEventPayload> {
  constructor (private meta: OSPPageViewEventMeta, protected options: TrackEventOptions) {
    super(options.provider, options.debug)
  }

  get previousUrl () {
    if (this.meta.fromPath) {
      return location.origin + this.meta.fromPath
    }

    return ''
  }

  get pageUrl () {
    const {
      pathname: pathNameRaw,
      search,
    } = window.location
    const vpvOfferType = AnalyticsVPVOfferTypes[this.meta.offerType]
    const pathName = pathNameRaw.replace(/\/$/g, '') // just in case the pathname ended with '/'

    return `${pathName}/${vpvOfferType}/${this.meta.stepPath}/${search}`
  }

  get pageTitle () {
    return `${this.meta.offerType} | ${this.meta.offerName} | ${this.meta.stepName}`
  }

  get payload (): WithEventProperty<PageViewEventPayload> {
    const { routeName } = this.meta

    return {
      event: 'sendVirtualPageview',
      routeName,
      pageType: 'PageView',
      pageUrl: this.pageUrl,
      fullUrl: location.href,
      pageTitle: this.pageTitle,
      vpv: location.href.replace(location.origin, ''),
      previousUrl: this.previousUrl,
    }
  }
}
