import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'

export abstract class ApplicationService {
  constructor (
    private httpClient: NuxtAxiosInstance | AxiosInstance,
    protected runtimeConfig: NuxtRuntimeConfig,
  ) {
  }

  abstract get baseUrl (): string

  buildUrlPath (path: string) {
    return this.baseUrl
      .replace('/$', '')
      .concat(path)
  }

  get<T = any>(path: string, params = {}, requestConfig: Partial<AxiosRequestConfig> = {}) {
    return this.request<T>({
      method: 'GET',
      url: this.buildUrlPath(path),
      params,
      ...requestConfig,
    })
  }

  post<T = any>(path: string, data = {}, requestConfig: Partial<AxiosRequestConfig> = {}) {
    return this.request<T>({
      method: 'POST',
      url: this.buildUrlPath(path),
      data,
      ...requestConfig,
    })
  }

  patch<T = any>(path: string, data = {}, requestConfig: Partial<AxiosRequestConfig> = {}) {
    return this.request<T>({
      method: 'PATCH',
      url: this.buildUrlPath(path),
      data,
      ...requestConfig,
    })
  }

  put<T = any>(path: string, data = {}, requestConfig: Partial<AxiosRequestConfig> = {}) {
    return this.request<T>({
      method: 'PUT',
      url: this.buildUrlPath(path),
      data,
      ...requestConfig,
    })
  }

  delete<T = any>(path: string, requestConfig: Partial<AxiosRequestConfig> = {}) {
    return this.request<T>({
      method: 'DELETE',
      url: this.buildUrlPath(path),
      ...requestConfig,
    })
  }

  async request<T = any>(options: AxiosRequestConfig) {
    const url = options.url?.replace(/([^:]\/)\/+/g, '$1')

    const config = {
      ...options,
      url,
      responseType: options.responseType || 'json',
    }
    const response = await this.httpClient.request<T>(config)
    return response?.data
  }
}
