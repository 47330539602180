import { ApplicationService } from '~/services/core/application.service'

export interface PersonalInfoPayload {
  name: string,
  // eslint-disable-next-line camelcase
  last_name: string,
  // eslint-disable-next-line camelcase
  image_id?: number,
}

export interface ContactInfoPayload {
  email: string,
  phone: string,
  location: string,
}

export interface SecurityInfoPayload {
  // eslint-disable-next-line camelcase
  old_password: string,
  // eslint-disable-next-line camelcase
  new_password: string,
}
export class UserService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  signUpByEmail (payload: Record<string, string>) {
    return this.post('/api/sign_up/by_email', payload)
  }

  signUpByIdentityProvider (payload: { provider: 'google' | 'facebook', token: string }) {
    return this.post('/api/sign_up/by_identity_provider', payload)
  }

  signInByIdentityProvider (payload: { provider: 'google' | 'facebook', token: string }) {
    return this.post('/api/sign_in/by_identity_provider', payload)
  }

  lookUp (email: string): Promise<any> {
    return this.get('/api/accounts/lookup', {
      email,
    })
  }

  sendRestorePasswordToEmail (email: string): Promise<any> {
    return this.post('/api/sign_in/forgot_reset', {
      login: email,
    })
  }

  sendConfirmationCodeToEmail (): Promise<any> {
    return this.post('/api/accounts/email/send_confirm_code')
  }

  sentConfirmCodeToExistingEmail (email: string): Promise<any> {
    return this.post('/api/accounts/change_email/send_confirm_code', {
      email,
    })
  }

  confirmChangeEmailByCode (code: number): Promise<void> {
    return this.post('/api/accounts/change_email', {
      verification_code: code,
    })
  }

  confirmEmailByCode (code: string | number): Promise<any> {
    return this.post('/api/accounts/confirm_email', {
      verification_code: code,
    })
  }

  getProfileInfo (): Promise<any> {
    return this.get('/api/accounts/profile_info')
  }

  // eslint-disable-next-line camelcase
  getSessionId (): Promise<{ session_id: string }> {
    return this.get('/api/session')
  }

  clearCurrentSession (): Promise<void> {
    return this.get('/api/account/v1/clear_session')
  }

  uploadProfileImage (file: File) {
    const formData = new FormData()
    formData.append('file', file)
    return this.post('/api/accounts/image', formData)
  }

  setPersonalInfo (payload: PersonalInfoPayload) {
    return this.put('/api/accounts/personal_info', payload)
  }

  setContactInfo (payload: ContactInfoPayload) {
    return this.put('/api/accounts/contact_info', payload)
  }

  setSecurityInfo (payload: SecurityInfoPayload) {
    return this.post('/api/accounts/change_password', payload)
  }
}
