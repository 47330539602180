import { Component, Vue, Prop } from 'nuxt-property-decorator'
import VueI18n from 'vue-i18n'

export enum PhoneNumberType {
  MAIN,
  SUPPORT,
}

export const PHONE_NUMBERS: Record<PhoneNumberType, Record<VueI18n.Locale, string>> = {
  [PhoneNumberType.MAIN]: {
    en: '043 588 25 25',
    de: '043 588 23 23',
    fr: '043 588 24 24',
  },
  [PhoneNumberType.SUPPORT]: {
    en: '043 508 37 30',
    de: '043 508 80 42',
    fr: '043 508 29 59',
  },
}

@Component
export default class ContactMixin extends Vue {
  @Prop({ type: Number, default: PhoneNumberType.MAIN })
  private readonly type!: PhoneNumberType

  get number () {
    return PHONE_NUMBERS[this.type][this.$i18n.locale]
  }

  get href () {
    const replacedNumber = this.number.replace(/\s+/g, '')
    return `tel:${replacedNumber}`
  }
}
