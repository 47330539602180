
import { Component } from 'nuxt-property-decorator'
import IconPhoneOutline from '~/assets/icons/icon-phone-outline.svg?inline'
import ContactMixin from '~/mixins/contact.mixin'

@Component({
  components: {
    IconPhoneOutline,
  },
})
export default class AppHeaderContact extends ContactMixin {}
