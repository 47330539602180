import { Component, Vue } from 'nuxt-property-decorator'

@Component
export class SiteNavigationMixin extends Vue {
  get blogURL () {
    const { locale } = this.$i18n

    return `/${locale}/blogs/`
  }

  get alaoDELink () {
    if (this.$i18n.locale === 'de') {
      return 'https://www.alao.de/de/'
    }

    return 'https://www.alao.de/en/'
  }
}
