import { ApplicationService } from '~/services/core/application.service'
import { SSTEventData } from '~/plugins/analytics/facebook/types'

export class TrackerService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_API_URL
  }

  private readonly facebookEventActionSource = 'website'

  pushFacebookEvent (payload: SSTEventData): Promise<any> {
    return this.post('/api/trackers/facebook/', payload)
  }
}
